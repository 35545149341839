import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SearchContext, defaultSearchValues } from '../BuyRent';
import { getGridColumns, getPropertyOptions } from '../../../common/Utils';

const RadioButtons = ({ options, field, columns = 2 }) => {
  const gridColumns = getGridColumns(); // Get gridColumns value using the utility function
  const { searchValues, setSearchValues, setAdditionalStep } =
    useContext(SearchContext);
  // get functions to build form with useForm() hook
  const { register } = useForm({ mode: 'all' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (field === 'type' && value === 'HDB') ||
      (field !== 'type' && searchValues?.type === 'HDB')
    ) {
      setAdditionalStep(true);
    } else {
      setAdditionalStep(false);
    }

    setSearchValues((prevSearchValues) => {
      if (field === 'type') {
        // If type is being changed, reset other values
        return {
          ...defaultSearchValues,
          [field]: value,
        };
      } else
        return {
          ...prevSearchValues,
          [field]: value,
        };
    }); // Use functional update to avoid unnecessary dependencies
  };

  const optionsToShow = getPropertyOptions(field, options, searchValues);
  if (!optionsToShow) {
    return null;
  }

  return (
    <div key={field} className={`px-5 grid gap-1 ${gridColumns}`}>
      {optionsToShow.map((option, i) => {
        return (
          <div key={i} className="flex items-center">
            <input
              checked={searchValues[field] === option ? true : false}
              id={option}
              type="radio"
              value={option}
              name={field}
              className="w-4 h-4 mb-1 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-0 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              {...register(field, { required: 'Please select one.' })}
              onChange={handleChange}
            />
            <label
              htmlFor={option}
              className="px-2 mb-3 w-full text-sm font-medium cursor-pointer text-gray-500 dark:text-gray-300"
            >
              {option}
            </label>
          </div>
        );
      })}
    </div>
  );
};

RadioButtons.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
};

export default RadioButtons;
