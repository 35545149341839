import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AccountActivationModal from '../common/modals/AccountActivationModal';
import useIsMobile from '../../hooks/useIsMobile';
import ModalContent from '../ModalContent';
import { ownerAccountValidation } from '../../common/Validations';
import { createUserAndSendVerificationEMail } from '../../common/ApiService';
import FormField, { WhatsAppInput } from '../common/FormField';
import * as constants from '../../common/Constants';
import ResendEmailVerificationButton from '../common/ResendEmailVerificationButton';

// This component is used for owner and guest account creation
const CreateOwner = (props) => {
  const [ccode, setCountryCode] = useState('+65');
  const [whatsapp, setWhatsapp] = useState();
  const [pwdType, setPwdType] = useState('password');
  const [cpwdType, setCPwdType] = useState('password');
  // Form options and validation using react-hook-form and yup
  const formOptions = {
    resolver: yupResolver(ownerAccountValidation()),
  };
  const { setFocus, register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState({
    type: '',
    data: { code: null, result: null },
    message: null,
    email: '',
  });
  const [isOkay, setOkay] = useState(false);
  const isMobile = useIsMobile();

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  function handleCCodeChange(event) {
    if (
      event.target.value.length === 1 &&
      event.target.value.charAt(0) === '+'
    ) {
      return setCountryCode('+');
    }

    var _ccode = event.target.value.replace(/\D/g, '');
    if (_ccode.length > 0 && _ccode.charAt(0) !== '+') {
      _ccode = '+' + _ccode;
      event.target.value = _ccode;
    }
    setCountryCode(_ccode);
  }

  function handleWhatsAppNoChange(event) {
    var _whatsapp = event.target.value.replace(/\D/g, '');
    setWhatsapp(_whatsapp);
  }

  // Handle form submission
  const onSubmit = async (data) => {
    // Remove confirm password from data
    delete data['cpassword'];
    // data['ccode'] = Number(data['ccode']);
    data['ccode'] = Number('65');
    data['whatsapp'] = Number(data['whatsapp']);
    data['type'] = props.isGuest ? 'Guest' : 'Owner';
    data['email'] = String(data['email']).toLowerCase();
    setLoading(true);

    // Send the form data to signup API and get a response.
    const response = await createUserAndSendVerificationEMail(data);
    if (response?.status === 200 && response?.data?.code) {
      // code: 1 // message: 'Verification is pending. Kindly check you email!',
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        email: data.email,
        data: { code: response?.data?.code },
        message: response.data.message,
      });
      if (isMobile)
        props.openModal(constants.INFO_MODAL, response.data.message);
    } else if (response.status === 200 && response?.data?.result) {
      setApiResponseMsg({
        type: constants.TEXT_SUCCESS,
        message: response.data?.message,
        email: data.email,
      });
    } else {
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        email: data.email,
        data: { code: response?.data?.code },
        message: response.data.message,
      });
      if (isMobile)
        props.openModal(constants.INFO_MODAL, response.data.message);
    }
    setLoading(false);
    setOkay(true);
    return false;
  };

  const renderServerError = () => {
    if (apiResponseMsg.data?.code) {
      // For Server error response
      return (
        <div className="flex justify-center mt-4 text-xs text-red-700 ">
          {apiResponseMsg.message}
        </div>
      );
    } else {
      // For server error
      return (
        <div className="flex justify-center text-xs text-red-700">
          <h6 className="text-base font-medium py-4">{constants.ERROR_OOPS}</h6>
        </div>
      );
    }
  };

  const renderApiResponse = () => {
    if (!loading) {
      if (apiResponseMsg.type === constants.TEXT_SUCCESS) {
        return <AccountActivationModal />;
      } else if (apiResponseMsg.type === constants.TEXT_FAILURE) {
        if (apiResponseMsg.data.code) {
          return (
            <>
              {renderServerError()}
              {apiResponseMsg.data.code === 2 && (
                <ResendEmailVerificationButton
                  email={apiResponseMsg.email}
                  setloading={(val) => setLoading(val)}
                  setApiResponseMsg={(obj) => setApiResponseMsg(obj)}
                  onClose={(toastType, msg) =>
                    props.onClose(toastType, msg, props.id)
                  }
                />
              )}
            </>
          );
        } else {
          return renderServerError();
        }
      } else {
        return null;
      }
    }
    return null;
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalContent size={props.size}>
        <section className="container">
          <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
            {/* Need to refactor in simpler way */}
            <div
              className={`${
                apiResponseMsg.type === constants.TEXT_SUCCESS && !loading
                  ? 'hidden'
                  : ''
              }`}
            >
              <FormField
                label="Name as in NRIC/Passport "
                name="userName"
                register={register}
                errors={errors}
              />
              <FormField
                label="Email"
                name="email"
                register={register}
                errors={errors}
              />
              <WhatsAppInput
                register={register}
                errors={errors}
                ccode={ccode}
                whatsapp={whatsapp}
                handleCCodeChange={handleCCodeChange}
                handleWhatsAppNoChange={handleWhatsAppNoChange}
              />
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-3">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        {constants.LABEL_PASSWORD}
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <div
                        className={`flex items-center ${
                          !errors.password?.message
                            ? 'focus-within:border-blue-500 border-gray-300'
                            : 'focus-within:border-red-700  border-red-700'
                        } bg-transparent overflow-hidden border rounded shadow-input`}
                      >
                        <input
                          className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-r ${
                            !errors.password?.message
                              ? 'focus-within:border-blue-500  border-gray-300'
                              : 'focus-within:border-red-700  border-red-700'
                          } bg-transparent`}
                          maxLength={12}
                          type={pwdType}
                          placeholder=""
                          id="password"
                          {...register('password')}
                        />
                        <p
                          className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                          onClick={() =>
                            setPwdType(pwdType === 'text' ? 'password' : 'text')
                          }
                        >
                          <FontAwesomeIcon
                            icon={pwdType === 'text' ? faEye : faEyeSlash}
                          />
                        </p>
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.password?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 pl-3 py-3">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        {constants.LABEL_RE_ENTER_PASSWORD}
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <div
                        className={`flex items-center ${
                          !errors.cpassword?.message
                            ? 'focus-within:border-blue-500 border-gray-300'
                            : 'focus-within:border-red-700  border-red-700'
                        } bg-transparent overflow-hidden border rounded shadow-input`}
                      >
                        <input
                          className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-r ${
                            !errors.cpassword?.message
                              ? 'focus-within:border-blue-500  border-gray-300'
                              : 'focus-within:border-red-700  border-red-700'
                          } bg-transparent`}
                          maxLength={12}
                          type={cpwdType}
                          placeholder=""
                          id="cpassword"
                          {...register('cpassword')}
                        />
                        <p
                          className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                          onClick={() =>
                            setCPwdType(
                              cpwdType === 'text' ? 'password' : 'text'
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={cpwdType === 'text' ? faEye : faEyeSlash}
                          />
                        </p>
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.cpassword?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderApiResponse()}
          </div>
        </section>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {!isOkay && (
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none"
            type="submit"
            aria-label="Submit"
            disabled={loading}
          >
            {/* Display different text based on conditions */}

            {constants.BUTTON_SUBMIT}
          </button>
        )}
        {!loading && isOkay && (
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none"
            onClick={() => props.onClose(null, null, props.id)}
          >
            {'Okay'}
          </button>
        )}
      </div>
    </form>
  );
};

export default CreateOwner;
