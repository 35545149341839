import React from 'react';
import * as constants from '../../../common/Constants';

// This component is used in user account creation for EmailVerification Message
function AccountActivationModal() {
  return (
    <div className="py-2">
      <div className="w-full">
        <div className="flex-wrap -m-3">
          <p className="mb-4 text-gray-500">
            To complete the registration process, kindly confirm your email
            address.
          </p>
          <p className="mb-4 text-gray-500">
            We have sent an email with a confirmation link to your email
            address. In order to complete the sign-up process, please click the
            confirmation link. The link will expire after{' '}
            <span className="text-blue-500 font-medium">
              {constants.EMAIL_VALIDATION_EXPIRY} hour(s)
            </span>
            , following which you will have to create your account again.
          </p>
          <p className="mb-4 text-gray-500">
            If you do not receive a confirmation email, please check your spam
            folder.
          </p>
          <p className="mb-4 text-gray-500">
            Thank you for joining our ProproproP family!
          </p>
        </div>
      </div>
    </div>
  );
}

export default AccountActivationModal;
