import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import useIsMobile from '../../../../hooks/useIsMobile';
import { PostStepperContext } from '../../AddEditPost';

const TextFields = ({ fields }) => {
  const isMobile = useIsMobile();

  const { postData, setPostData, errors, setErrors } =
    useContext(PostStepperContext);

  useEffect(() => {
    // console.log('TextFields.useEffect : postData -> ', postData);
  }, [postData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const field = fields.find((f) => f.name === name);

    if (field.maxLength && value.length > field.maxLength) {
      return;
    }

    const updatedErrors = errors.filter((error) => error.name !== name);

    // Check if the field is required and the trimmed value is empty
    if (field && field.isMandatoryField && value === '') {
      updatedErrors.push({ name, message: `${field.label} is required` });
    }

    // Check if the field is a number and the value is negative
    if (field && field.isNumber && Number(value) && Number(value) < 0) {
      updatedErrors.push({
        name,
        message: `${field.label} allows only positive number`,
      });
    }

    if (field.name === 'postalCode' && String(value).length < 6) {
      updatedErrors.push({
        name,
        message: `${field.label} should be ${field.maxLength} characters`,
      });
    }
    setErrors(updatedErrors);

    // Validate if the field is a number
    if (field && field.isNumber && Number(value) && Number(value) >= 0) {
      setPostData({ ...postData, [name]: Number(value) });
    } else {
      // If the value is not a number or the field is not configured for numbers, save it as a string
      setPostData({ ...postData, [name]: value });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const field = fields.find((f) => f.name === name);

    // Mark the field as an error if it is required and the value is empty on blur
    if (field && field.isMandatoryField && value === '') {
      const updatedErrors = [...errors];
      const errorIndex = updatedErrors.findIndex(
        (error) => error.name === name
      );
      if (errorIndex !== -1) {
        updatedErrors[errorIndex] = {
          name,
          message: `${field.label} is required`,
        };
      } else {
        updatedErrors.push({ name, message: `${field.label} is required` });
      }
      setErrors(updatedErrors);
    }
  };

  const handleKeyDown = (e) => {
    // Check if the field is a number and the value is negative
    const field = fields.find((f) => f.name === e.target.name);
    if (field && field.isNumber) {
      // Prevent specific keys for number inputs
      if (
        ['e', 'E', '+', '-'].includes(e.key) ||
        (field.name === 'postalCode' && ['.'].includes(e.key))
      ) {
        e.preventDefault();
        return;
      }
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  if (!fields) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {fields.map((field, i) => {
        // Set default value of isNumber to false if not specified
        const isNumber = field.isNumber !== undefined ? field.isNumber : false;
        const fieldError = errors.find((error) => error.name === field.name);

        return (
          <div key={i} className="w-full">
            <div className={`flex flex-col`}>
              <div
                className={`flex ${
                  isMobile ? 'flex-row justify-between' : 'flex-col'
                }`}
              >
                <label
                  htmlFor={field?.label}
                  className="flex flex-shrink-0 font-bold h-6 text-gray-800 dark:text-white text-xs leading-8"
                >
                  {field?.label}
                  <span className="font-bold text-red-500">
                    {field.isMandatoryField && ' *'}
                  </span>
                </label>
                <div className={`my-2 ${isMobile ? 'pl-4' : ''} w-3/4`}>
                  <input
                    id={field?.label}
                    onWheel={numberInputOnWheelPreventChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    value={postData[field?.name] || ''}
                    name={field?.name}
                    type={isNumber ? 'number' : 'text'}
                    minLength={field?.minLength}
                    maxLength={field?.maxLength}
                    className={`w-full px-4 py-1 text-sm dark:text-white font-normal bg-transparent shadow-input rounded outline-none border ${
                      !fieldError
                        ? 'focus:border-blue-500 border-gray-300'
                        : 'border-red-700 focus:ring-red-700 focus:border-red-700'
                    }`}
                    // Remove the required attribute if isMandatoryField is false
                    {...(!field.isMandatoryField ? { required: false } : {})}
                  />
                </div>
              </div>
              {fieldError && (
                <span className="text-red-700 text-xs">
                  {fieldError.message}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

TextFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isNumber: PropTypes.bool, // New prop for number validation
      isMandatoryField: PropTypes.bool,
      minLength: PropTypes.number, // Add minLength property for text character minimum limit
      maxLength: PropTypes.number, // Add maxLength property for text character limit
    })
  ).isRequired,
};

export default TextFields;
