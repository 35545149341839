import React from 'react';
import useMobileOrientation from '../hooks/useMobileOrientation';
import { modalSizes } from './common/modals';

const ModalContent = ({ size, children }) => {
  const styles = modalSizes.find((item) => item.size === size).styles;
  const { isMobile, isLandscape } = useMobileOrientation();

  return (
    <div
      className={`p-6 space-y-6 ${
        isMobile ? (isLandscape ? 'h-[45vh]' : 'h-[65vh]') : styles.height
      } overflow-auto`}
    >
      {children}
    </div>
  );
};

export default ModalContent;
