import axios from 'axios';
import React, { useEffect } from 'react'; // Import useEffect from 'react'
import './App.css';
import Home from './features/Home';
import { Helmet } from 'react-helmet';
import { SeoKeys } from './common/AppConstants';

axios.defaults.baseURL = process.env.REACT_APP_PROP_SERVER_URL;

// React.memo (HOC) can ensure a component is only re-rendered when its props change
const App = React.memo(function App() {
  useEffect(() => {
    // Redirection to domain URL in case of appspot URL
    if (window.location.href.includes('appspot')) {
      window.location.href = 'https://proproprop.com.sg';
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="https://proproprop.com.sg/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>
        ProProProP Singapore | Free Property Listings for all
        </title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Singapore property for sale and rent. Free real estate listing service. Explore our expertise and tailored solution for your property needs." />
        <meta name="keywords" content={SeoKeys.join(', ')} />
        <meta property="og:title" content="ProProProP Singapore | Free Property Listings for all" />
        <meta property="og:description" content="Singapore property for sale and rent. Free real estate listing service. Explore our expertise and tailored solution for your property needs." />
        <meta property="og:url" content="https://proproprop.com.sg" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://proproprop.com.sg/logo.png" />
        <meta name="logo" content="https://proproprop.com.sg/logo.png" />
        <link rel="apple-touch-icon" href="https://proproprop.com.sg/favicon.ico" />
      </Helmet>
      <div className="font-calibri">
        <Home />
      </div>
    </>
  );
});

export default App;
