import {
  faCheckCircle,
  faCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import styles from './Toast.module.css';

// Toast component is used in Home.js and VARIANTS are used in Feedback, Login, ChangePwd, PwdReset.
export const VARIANTS = {
  Default: {
    base: 'bg-gray-600',
    iconstyle: '',
    icon: faCircle,
    name: 'Default',
  },
  Info: {
    base: 'bg-blue-500',
    iconstyle: 'text-blue-500',
    icon: faInfoCircle,
    name: 'Info',
  },
  Failure: {
    base: 'bg-red-500',
    iconstyle: 'text-red-500',
    icon: faTimesCircle,
    name: 'Failure',
  },
  Error: {
    base: 'bg-red-500',
    iconstyle: 'text-red-500',
    icon: faTimesCircle,
    name: 'Error',
  },
  Warning: {
    base: 'bg-yellow-500',
    iconstyle: 'text-yellow-500',
    icon: faExclamationCircle,
    name: 'Warning',
  },
  Success: {
    base: 'bg-green-500',
    iconstyle: 'text-green-500',
    icon: faCheckCircle,
    name: 'Success',
  },
};

const Toast = ({ toastlist, setList }) => {
  const deleteToast = useCallback(
    (id) => {
      setList((prevList) => prevList.filter((e) => e.id !== id));
    },
    [setList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastlist.length) {
        deleteToast(toastlist[0].id);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [toastlist, deleteToast]);

  return (
    <div className={`text-sm fixed z-50 ${styles['buttom-right']}`}>
      {toastlist.map((toast) => {
        const variant = VARIANTS[toast?.type] || VARIANTS.Default;
        return (
          <div
            key={toast.id}
            className={`right-4 bottom-4 mb-4 sm:bottom-8 sm:right-8 p-4 w-80 max-w-md rounded-lg shadow-md transition duration-150 ease ${variant.base} text-white`}
          >
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                <FontAwesomeIcon className="h-6 w-6" icon={variant.icon} />
                <div className="ml-2">
                  <p className="text-lg font-semibold">{variant.name}</p>
                  <p className="text-sm w-60 overflow-ellipsis overflow-hidden whitespace-nowrap max-w-xs truncate">
                    {toast.description}
                  </p>
                </div>
              </div>
              <button className="ml-2">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-white font-bold float-right bg-none border-none cursor-pointer"
                  onClick={() => deleteToast(toast.id)}
                />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Toast;
