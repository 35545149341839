import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalContent from '../ModalContent';
import { changePwdValidation } from '../../common/Validations';
import { changePassword } from '../../common/ApiService';
import { getLoggedInUser } from '../../common/Utils';
import * as constants from '../../common/Constants';
import { AppContext } from '../Home';
import { VARIANTS } from '../common/toast';

function ChangePassword({ id, size, onClose }) {
  const { addToast } = useContext(AppContext);
  const [currentPwdType, setCurrentPwdType] = useState('password');
  const [newPwdType, setNewPwdType] = useState('password');
  const [confirmPwdType, setConfirmPwdType] = useState('password');

  // Form options and validation using react-hook-form and yup
  const formOptions = { resolver: yupResolver(changePwdValidation()) };

  const { setFocus, register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState({
    type: '',
    data: { code: null },
    message: null,
  });

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const onSubmit = async (data) => {
    // Remove confirm password from data
    delete data['cpassword'];
    let user = getLoggedInUser();
    data['id'] = user.id;
    console.log('data ', data);

    setLoading(true);

    // Send the form data to change password API and get a response.
    const response = await changePassword(data);

    if (response?.status === 200) {
      setLoading(false);
      setApiResponseMsg({
        type: constants.TEXT_SUCCESS,
        message: 'Password changed successfully!',
      });
      addToast(VARIANTS.Success.name, 'Password changed successfully!');
      onClose(id);
    } else {
      setLoading(false);
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        message: response?.data?.result,
        data: { code: response?.status },
      });
      addToast(VARIANTS.Error.name, response?.data?.result);
    }
    return false;
  };

  const renderServerError = () => {
    if (apiResponseMsg.data?.code) {
      // For Server error response
      return (
        <div className="flex justify-center mt-4 text-base text-red-700 ">
          {apiResponseMsg.message}
        </div>
      );
    } else {
      // For server error
      return (
        <div className="flex justify-center text-xs text-red-700">
          <div className="border-t border-gray-200 text-center">
            <h6 className="text-base font-medium py-2">
              {constants.ERROR_OOPS}
            </h6>
          </div>
        </div>
      );
    }
  };

  const renderApiResponse = () => {
    if (!loading && apiResponseMsg.type === constants.TEXT_FAILURE) {
      return renderServerError();
    }
    return null;
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalContent size={size}>
        <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
          <div
            className={`${
              apiResponseMsg.type === constants.TEXT_SUCCESS
                ? 'hidden'
                : 'block'
            }`}
          >
            <div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm pt-2 text-gray-800 dark:text-white font-medium">
                        Current Password
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <div
                        className={`flex items-center ${
                          !errors.password?.message
                            ? 'focus-within:border-blue-500 border-gray-300'
                            : 'focus-within:border-red-700  border-red-700'
                        } bg-transparent overflow-hidden border rounded shadow-input`}
                      >
                        <input
                          className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-r ${
                            !errors.password?.message
                              ? 'focus-within:border-blue-500  border-gray-300'
                              : 'focus-within:border-red-700  border-red-700'
                          } bg-transparent`}
                          type={currentPwdType}
                          placeholder="Enter Current Password"
                          {...register('password')}
                        />
                        <p
                          className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                          onClick={() =>
                            setCurrentPwdType(
                              currentPwdType === 'text' ? 'password' : 'text'
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              currentPwdType === 'text' ? faEye : faEyeSlash
                            }
                          />
                        </p>
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.password?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm pt-2 text-gray-800 dark:text-white font-medium">
                        New Password
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <div
                        className={`flex items-center ${
                          !errors.newPassword?.message
                            ? 'focus-within:border-blue-500 border-gray-300'
                            : 'focus-within:border-red-700  border-red-700'
                        } bg-transparent overflow-hidden border rounded shadow-input`}
                      >
                        <input
                          className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-r ${
                            !errors.newPassword?.message
                              ? 'focus-within:border-blue-500  border-gray-300'
                              : 'focus-within:border-red-700  border-red-700'
                          } bg-transparent`}
                          type={newPwdType}
                          placeholder="Enter New Password"
                          {...register('newPassword')}
                        />
                        <p
                          className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                          onClick={() =>
                            setNewPwdType(
                              newPwdType === 'text' ? 'password' : 'text'
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={newPwdType === 'text' ? faEye : faEyeSlash}
                          />
                        </p>
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.newPassword?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm pt-2 text-gray-800 dark:text-white font-medium">
                        Re-enter New Password
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <div
                        className={`flex items-center ${
                          !errors.cpassword?.message
                            ? 'focus-within:border-blue-500 border-gray-300'
                            : 'focus-within:border-red-700  border-red-700'
                        } bg-transparent overflow-hidden border rounded shadow-input`}
                      >
                        <input
                          className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-r ${
                            !errors.cpassword?.message
                              ? 'focus-within:border-blue-500  border-gray-300'
                              : 'focus-within:border-red-700  border-red-700'
                          } bg-transparent`}
                          type={confirmPwdType}
                          placeholder="Re-enter New Password"
                          {...register('cpassword')}
                        />
                        <p
                          className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                          onClick={() =>
                            setConfirmPwdType(
                              confirmPwdType === 'text' ? 'password' : 'text'
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              confirmPwdType === 'text' ? faEye : faEyeSlash
                            }
                          />
                        </p>
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.cpassword?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex justify-center ${
              apiResponseMsg.type === constants.TEXT_SUCCESS
                ? 'block'
                : 'hidden'
            }`}
          >
            Password changed successfully!
          </div>
          {/* Handle API Response  */}
          {renderApiResponse()}
        </div>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
            type="submit"
            aria-label="Change Password"
            disabled={loading}
          >
            {constants.BUTTON_SUBMIT}
          </button>
        }
      </div>
    </form>
  );
}

export default ChangePassword;
