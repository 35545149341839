import React, { useContext } from 'react';
import ModalContent from '../ModalContent';
import { BUTTON_CLOSE } from '../../common/Constants';
import { AppContext } from '../Home';
import ListView from '../common/ListView';

const Posts = ({ id, size, isEnquiry, isFavorites, onClose, addToast }) => {
  const { loading, posts, enquires, favorites } = useContext(AppContext);

  const getPosts = (params) => {
    if (isEnquiry) {
      return enquires;
    } else if (isFavorites) {
      return favorites;
    } else {
      return posts;
    }
  };

  const handleClick = () => {
    onClose(null, null, id);
  };

  return (
    <>
      <ModalContent size={size}>
        <ListView
          isSearchList={false}
          loading={loading}
          posts={getPosts()}
          addToast={addToast}
          isEnquiries={isEnquiry}
          isFavorites={isFavorites}
        />
      </ModalContent>
      <div className="flex items-center justify-end p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button
          className={`btn btn-secondary`}
          aria-label="Close"
          onClick={handleClick}
        >
          {BUTTON_CLOSE}
        </button>
      </div>
    </>
  );
};

export default Posts;
