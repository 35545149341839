import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Compress from 'compress.js';
import React, { useContext, useState } from 'react';
import { MAX_COUNT } from '../../../common/AppConstants';
import {
  TEXT_NO_FILE_SELECTED,
  TEXT_SUPPORTED_IMAGE_COUNT,
  TEXT_SUPPORTED_IMAGE_FORMATS,
  TEXT_UPLOAD_MULTIPLE_FILES,
} from '../../../common/Constants';
import { PostStepperContext } from '../AddEditPost';
import Carousel, { CarouselItem } from '../../common/Carousel';

const Pictures = () => {
  const { postData, setPostData } = useContext(PostStepperContext);
  const [uploadedFiles, setUploadedFiles] = useState(postData.pictures || []);
  const [fileLimit, setFileLimit] = useState(
    postData.pictures ? postData.pictures.length >= MAX_COUNT : false
  );
  const [carouselKey, setCarouselKey] = useState(0);

  // Event handler for file input change
  const handleFileEvent = (e) => {
    if (uploadedFiles.length >= MAX_COUNT) {
      // Just guard condition. Wont be called as the choose file btn is disabled
      return;
    }
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    // Filter out non-image files
    const imageFiles = chosenFiles.filter((file) => {
      const acceptedFormats = ['image/jpeg', 'image/png'];
      return acceptedFormats.includes(file.type);
    });
    handleUploadFiles(imageFiles);
  };

  // Handle file upload
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let imgCount = uploaded.length;
    if (files.length > MAX_COUNT - imgCount) {
      files = files.slice(0, MAX_COUNT - imgCount);
    }
    let totalImgCount = imgCount + files.length;
    var promise = new Promise((resolve) => {
      files?.forEach(async (file, index) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          let _base64Img = await getBase64(file);
          uploaded.push({ data: _base64Img });
          if (uploaded.length >= MAX_COUNT) {
            setFileLimit(true);
          }
          if (uploaded.length >= totalImgCount) {
            resolve();
          }
        }
      });
    });

    promise.then(() => {
      setUploadedFiles(uploaded);
      console.log('handleUploadFiles : upload ', uploaded);
      setPostData({ ...postData, pictures: uploaded });
    });
  };

  // Convert file to base64 string
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        console.log('Compressing...');
        const compress = new Compress();
        compress
          .compress([file], {
            size: 4, // the max size in MB, defaults to 2MB
            quality: 0.75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
          })
          .then((data) => {
            // returns an array of compressed images
            console.log('Compressed... ', data);
            resolve(data[0].prefix + data[0].data);
          });
      };
      reader.onerror = (error) => reject(null);
    });
  };

  // Render carousel items
  const renderCarouselItems = () => {
    return uploadedFiles.map((picture, i) => {
      return (
        picture && (
          <CarouselItem key={i}>
            <img
              alt={`preview${i}`}
              className="object-fit object-center h-full w-full"
              src={picture.url || picture.data}
            />
            <button
              className="absolute top-1 right-1 bg-white h-6 w-6 rounded-full shadow-lg"
              onClick={() => removeImage(i)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </CarouselItem>
        )
      );
    });
  };

  // Render empty gallery
  const renderEmptyGallery = () => {
    return (
      <div id="gallery" className="flex flex-1 flex-wrap -m-1">
        <div
          id="empty"
          className="h-full w-full text-center flex flex-col items-center justify-center"
        >
          <img
            className="mx-auto w-32"
            src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
            alt="no data"
          />
          <span className="text-small text-gray-500">
            {TEXT_NO_FILE_SELECTED}
          </span>
        </div>
      </div>
    );
  };

  // Remove selected image
  const removeImage = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
    setPostData({ ...postData, pictures: updatedFiles });
    // Increment the carousel key to force re-rendering of the <Carousel> component
    setCarouselKey((prevKey) => prevKey + 1);
    setFileLimit(false);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="px-4 py-1 mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="w-[280px] sm:mb-0 mb-6 mx-auto h-[168px">
            <div className="rounded-lg h-40 overflow-hidden">
              <Carousel key={carouselKey}>{renderCarouselItems()}</Carousel>
              {/* If pictures length is empty  */}
              {uploadedFiles.length === 0 && renderEmptyGallery()}
            </div>
            {uploadedFiles.length > 0 && (
              <label className="font-bold my-2 text-xs text-gray-800 dark:text-white justify-center text-center">
                {uploadedFiles.length} Pictures
              </label>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full pt-4">
          <label
            className="font-bold my-2 text-xs text-gray-800 dark:text-white"
            htmlFor="multiple_files"
          >
            {TEXT_UPLOAD_MULTIPLE_FILES}
          </label>
          <input
            onChange={handleFileEvent}
            disabled={fileLimit}
            className="font-bold my-2 text-xs text-gray-600 block w-full bg-gray-50 border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="multiple_files"
            type="file"
            accept=".jpg, .jpeg, .png"
            multiple
          ></input>
          <p
            className="font-bold my-2 text-xs text-gray-500 dark:text-white"
            id="file_input_help"
          >
            {TEXT_SUPPORTED_IMAGE_FORMATS}
          </p>
          <p
            className="font-bold my-2 text-xs text-gray-500 dark:text-white"
            id="file_input_help2"
          >
            {TEXT_SUPPORTED_IMAGE_COUNT}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Pictures;
