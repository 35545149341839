import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import ModalContent from './ModalContent';
import { loginValidation } from '../common/Validations';
import { login, verifyToken } from '../common/ApiService';
import * as constants from '../common/Constants';
import { VARIANTS } from './common/toast';
import ResendEmailVerificationButton from './common/ResendEmailVerificationButton';
import { isEmail, isWhatsapp } from '../common/Utils';

function Login(props) {
  const captchaRef = useRef(null);
  const [captchaError, setCaptchaError] = useState('');
  const [pwdtype, setPwtype] = useState('password');
  const formOptions = { resolver: yupResolver(loginValidation()) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState({
    type: '',
    data: { code: null, result: null },
    message: null,
  });

  const handleRecoverPassword = () => {
    props.openRecoverPwd();
    props.onClose(null, null, props.id);
  };

  const onSubmit = async (data) => {
    setCaptchaError('');
    let token = captchaRef.current.getValue();
   
    console.log(token)
    if (process.env.NODE_ENV === 'production') {
         if (token) {
        let valid_token = await verifyToken(token);
        if (valid_token.success) {
        } else {
          setCaptchaError('Sorry!! Token invalid');
          return;
        }
      } else {
        setCaptchaError('You must confirm you are not a robot');
        return;
      }
    } else {
      // Skip reCAPTCHA check in local development
      token = 'dummy_token';
    }
    const value = data['email_or_whatsapp'];
    delete data['email_or_whatsapp']; // Removes email_or_whatsapp

    if (isEmail(value)) {
      data['email'] = String(value).toLowerCase();
    } else {
      data['whatsapp'] = parseInt(value);
    }

    setLoading(true);

    // Send the form data to signup API and get a response.
    const response = await login(data);
    if (response?.status === 200 && response?.data?.code) {
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        email: data.email,
        data: { code: response?.data?.code },
        message: response.data.message,
      });
    } else if (response?.status === 200 && response?.data) {
      // store the user in localStorage
      localStorage.setItem(
        constants.LOGGED_IN_USER,
        JSON.stringify(response.data)
      );
      setApiResponseMsg({
        type: constants.TEXT_SUCCESS,
        message: response.data?.message,
      });
      props.onLoggedIn(response.data, 'Success', 'Logged in successfully');
      props.onClose(null, null, props.id);
    } else {
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        message: response?.data.message,
        data: { code: response?.status },
      });
      props.addTost(VARIANTS.Error.name, response?.data.message);
    }
    setLoading(false);
    return false;
  };

  const renderServerError = () => {
    if (apiResponseMsg.data?.code) {
      // For Server error response
      return (
        <div className="flex justify-center mt-4 text-xs text-red-700 ">
          {apiResponseMsg.message}
        </div>
      );
    } else {
      // For server error
      return (
        <div className="flex justify-center text-xs text-red-700">
          <div className="border-t border-gray-200 text-center">
            <h6 className="text-base font-medium py-2">
              {constants.ERROR_OOPS}
            </h6>
          </div>
        </div>
      );
    }
  };

  const renderApiResponse = () => {
    if (!loading && apiResponseMsg.type === constants.TEXT_FAILURE) {
      if (apiResponseMsg.data.code) {
        return (
          <>
            {renderServerError()}
            {apiResponseMsg.data.code === 2 && (
              <ResendEmailVerificationButton
                email={apiResponseMsg.email}
                setloading={(val) => setLoading(val)}
                setApiResponseMsg={(obj) => setApiResponseMsg(obj)}
                onClose={(toastType, msg) => {
                  props.onClose(toastType, msg, props.id);
                }}
              />
            )}
          </>
        );
      } else {
        return renderServerError();
      }
    }
    return null;
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalContent size={props.size}>
        <section className="container">
          <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
            <div
              className={`${
                apiResponseMsg.type === constants.TEXT_SUCCESS ? 'hidden' : ''
              }`}
            >
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        Email
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <input
                        className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none bg-transparent border rounded-lg shadow-input ${
                          !errors.email_or_whatsapp?.message
                            ? 'focus:border-blue-500 border-gray-300'
                            : 'border-red-700 focus:ring-red-700 focus:border-red-700'
                        }`}
                        autoComplete="off"
                        type="email"
                        placeholder="Enter Email"
                        id="email_or_whatsapp"
                        {...register('email_or_whatsapp')}
                      />
                      <span className="text-xs text-red-700">
                        {errors.email_or_whatsapp?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-3">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        Password
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <div
                        className={`flex items-center ${
                          !errors.password?.message
                            ? 'focus-within:border-blue-500 border-gray-300'
                            : 'focus-within:border-red-700  border-red-700'
                        } bg-transparent overflow-hidden border rounded-lg shadow-input`}
                      >
                        <input
                          className={`w-full px-4 py-1 text-sm font-normal outline-none border-r dark:text-white ${
                            !errors.password?.message
                              ? 'focus-within:border-blue-500  border-gray-300'
                              : 'focus-within:border-red-700  border-red-700'
                          } bg-transparent`}
                          type={pwdtype}
                          placeholder=""
                          id="password"
                          {...register('password')}
                        />
                        <p
                          className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                          onClick={() =>
                            setPwtype(pwdtype === 'text' ? 'password' : 'text')
                          }
                        >
                          <FontAwesomeIcon
                            icon={pwdtype === 'text' ? faEye : faEyeSlash}
                          />
                        </p>
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.password?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-3"></div>
                    <div className="w-full md:flex-1 mt-2 p-3">
                      <ReCAPTCHA
                        className="bg-bleck"
                        ref={captchaRef}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      />
                      <span className="text-xs text-red-700">
                        {captchaError}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                {!loading && (
                  <div className="flex flex-col">
                    <>
                      <div
                        className="mt-1 mb-1 flex text-sm justify-center text-blue-500 cursor-pointer hover:underline px-6 py-1 outline-none focus:outline-none"
                        aria-label="Forgot-Password"
                        onClick={handleRecoverPassword}
                      >
                        {`${constants.FORGOT_PWD}?`}
                      </div>
                    </>
                    {props.showGuestSignup && (
                      <p className="dark:text-white text-sm">
                        If you don't have an account, kindly signup
                      </p>
                    )}
                    {props.showGuestSignup && (
                      <button
                        className="text-blue-500 text-sm px-6 py-1 hover:underline outline-none focus:outline-none mb-1 mt-1 mr-4"
                        type="button"
                        aria-label="Login"
                        onClick={props.handleGuestSignup}
                      >
                        {constants.BUTTON_GUEST_SIGNUP}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* Handle API Response  */}
            {renderApiResponse()}
          </div>
        </section>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none"
            type="submit"
            aria-label="Login"
            disabled={loading}
          >
            {constants.BUTTON_LOGIN}
          </button>
        }
      </div>
    </form>
  );
}

export default Login;
