import React from 'react';
import * as appConstants from '../../../common/AppConstants';

import CommonCreateForm from '../../user-accounts/CommonCreateForm';
import Description from '../steps/Description';
import Pictures from '../steps/Pictures';
import PreviewConfirmation from '../steps/PreviewConfirmation';
import PropertyDetails from '../steps/PropertyDetails';
import RadioButtons from '../steps/common/RadioButtons';

const StepperContent = ({ additionalStep, stepsLength, currentStep, createAccount }) => {
  stepsLength = additionalStep ? stepsLength : stepsLength - 1;

  const districtStep = (params) => {
    return (
      <>
        <label className="font-bold text-gray-800 dark:text-white text-xs">
          {appConstants.getPropFieldsLabel().district}
        </label>
        <RadioButtons field={'district'} options={appConstants.districts} />
      </>
    );
  };

  const descriptionStep = (params) => {
    return <Description />;
  };

  if (currentStep === 1) {
    // Action
    return <RadioButtons field={'action'} options={appConstants.actions} />;
  } else if (currentStep === 2) {
    // Property type
    return (
      <RadioButtons
        field={'type'}
        options={appConstants.propertyFields.map((item) => item['type'])}
      />
    );
  } else if (currentStep === 3) {
    // Room type
    return (
      <RadioButtons field={'subType'} options={appConstants.propertyFields} />
    );
  } else if (currentStep === 4 && additionalStep) {
    // HDB Estate type
    return (
      <RadioButtons field={'hdbEstate'} options={appConstants.hdbEstates} />
    );
  } else if (currentStep === 4 && !additionalStep) {
    // Property Details
    return <PropertyDetails />;
  } else if (currentStep === 5 && additionalStep) {
    // Property Details
    return <PropertyDetails />;
  } else if (currentStep === 5 && !additionalStep) {
    // District
    return districtStep();
  } else if (currentStep === 6) {
    // Desc
    return descriptionStep();
  } else if (currentStep === 7) {
    // Pictures
    return <Pictures />;
  } else if (currentStep === 8 && createAccount) {
    return <CommonCreateForm />;
  } else if (currentStep === 8 && !createAccount) {
    return <PreviewConfirmation />;
  } else if (currentStep === 9) {
    return <PreviewConfirmation />;
  } else {
    return null;
  }
};

export default StepperContent;
