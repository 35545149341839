import { faBuilding, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import ModalContent from '../ModalContent';
import { getPost, getUser } from '../../common/ApiService';
import {
  getPropFieldsLabel,
  propertyFieldOrder,
  propertyFields,
} from '../../common/AppConstants';
import {
  BUTTON_CLOSE,
  BUTTON_DELETE,
  BUTTON_EDIT,
} from '../../common/Constants';
import {
  getContactAgentOrOwner,
  getContactedAgentOrOwner,
  getPropertyType,
  isEnquiryMadeForPost,
  numberWithCommas,
} from '../../common/Utils';
import { AppContext } from '../Home';
import postCache from '../../common/PostCache';
import FieldValueDisplay from '../common/FieldValueDisplay';
import Carousel, { CarouselItem } from '../common/Carousel';

const PostDetail = ({
  id,
  size,
  isGuest = false,
  isSearch,
  isEnquiry,
  isFavorite,
  onClose,
}) => {
  const {
    selectedPost,
    openEditPost,
    setSelectedPost,
    openDeletePostConfirmation,
    handleContactAgentOrOwner,
  } = useContext(AppContext);
  const [editPost, setEditPost] = useState(selectedPost);

  const fetchData = async () => {
    try {
      const show = postCache.postWithProperties[selectedPost.id] ? false : true;
      const response = await getPost(selectedPost.id, show);
      if (response?.status === 200 && response?.data) {
        let result = response?.data?.result;
        if (result) {
          const userResponse = await getUser(result.userId);
          if (userResponse?.status === 200 && userResponse?.data) {
            result.userName = userResponse?.data.userName;
            result.userNo =
              '+' +
              userResponse?.data.ccode +
              ' ' +
              userResponse?.data.whatsapp;
            result.userEmail = userResponse?.data.email;
          }
        }
        postCache.postWithProperties[selectedPost.id] = result;
        setSelectedPost(result);
        setEditPost(result);
      } else {
        postCache.posts = [];
        setSelectedPost(null);
        setEditPost(null);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      postCache.posts = [];
      setSelectedPost(null);
      setEditPost(null);
    }
  };

  useEffect(() => {
    if (selectedPost && postCache.postWithProperties[selectedPost?.id]) {
      setSelectedPost(postCache.postWithProperties[selectedPost?.id]);
      setEditPost(postCache.postWithProperties[selectedPost?.id]);
    }
    fetchData();
  }, [selectedPost?.id]);

  if (!selectedPost) {
    return null; // Return early if `selectedPost` is not available
  }

  const renderImages = (pictures) => {
    return (
      <div className="w-[280px]">
        <Carousel>
          {pictures?.map((picture, i) => {
            return (
              picture && (
                <CarouselItem key={i}>
                  <img
                    alt="content"
                    className="object-fit object-center h-full w-full rounded-lg"
                    src={picture.url || picture.data}
                  />
                </CarouselItem>
              )
            );
          })}
        </Carousel>{' '}
      </div>
    );
  };

  const handleEditClick = () => {
    handleClose();
    openEditPost(editPost);
  };

  const handleClose = () => {
    onClose(null, null, id);
  };

  // PropertyDetails component
  function PropertyDetails({ postData }) {
    const selectedPropertyFields = propertyFields.find(
      (property) => property?.type === postData['type']
    )?.fields;

    let count = 0;
    return (
      <div className="my-3">
        <div className="flex flex-wrap">
          {propertyFieldOrder.map((fieldName) => {
            let display = true;
            const field = selectedPropertyFields?.find(
              (f) => f.name === fieldName
            );
            if (field) {
              if (field.actionType) {
                if (field.actionType !== postData.action) {
                  display = false; // Skip this field if actionType doesn't match
                }
              }
              if (!postData.hasOwnProperty(field.name)) {
                display = false; // postData doesn't have the field
              } else if (
                postData[field.name] === null ||
                postData[field.name] === undefined
              ) {
                display = false; // postData field value is null/undefined
              } else if (!String(postData[field.name]).trim().length) {
                display = false; // postData have the field - but the value is empty
              }
            } else {
              display = false;
            }
            if (field && display) {
              count = count + 1;
              return (
                <div
                  key={count}
                  className={`border-b w-full ${
                    count % 2 === 0 ? 'lg:w-2/5' : 'lg:w-3/5'
                  } py-2 border-gray-200`}
                >
                  <FieldValueDisplay
                    key={field.name}
                    field={field}
                    value={postData[field.name]}
                    postData={postData}
                  />
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <ModalContent size={size}>
        <section className="container py-1 mx-auto text-gray-600 dark:text-white body-font">
          {/** Images */}
          <div className="flex flex-wrap -mx-4 justify-center">
            {selectedPost?.pictures.length === 0 && (
              <div className="p-1 md:w-1/3 sm:mb-0 mb-6">
                <div className="rounded-lg h-40 overflow-hidden">
                  <img
                    alt="content"
                    className="object-fit object-center h-full w-full"
                    src={'no_image_available.jpeg'}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center">
            {selectedPost.pictures ? renderImages(selectedPost.pictures) : null}
          </div>

          <div className="flex flex-col w-full">
            <div>
              <h3 className="pt-4">
                <div className="title-font font-semibold text-xs pt-2 pb-4">
                  <span className="bg-blue-50 border border-blue-500 text-blue-500 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-md dark:bg-blue-200 dark:text-blue-800">
                    {isSearch || isEnquiry || isFavorite
                      ? selectedPost.action === 'Sell'
                        ? 'Buy'
                        : 'Rent'
                      : selectedPost.action}
                  </span>
                  <span className="vertical ml-2 mr-2"></span>
                  {getPropertyType(selectedPost)}
                  <span className="vertical ml-2 mr-2"></span>
                  {selectedPost?.subType}
                  {selectedPost?.tenure && (
                    <span className="vertical ml-2 mr-2"></span>
                  )}
                  {selectedPost?.tenure}
                </div>
              </h3>
            </div>

            <hr />

            <div className="pt-4 pb-4">
              <div>
                <div className="grid grid-cols-7 gap-4">
                  <div className="col-span-3 ">
                    <div className="title-font font-semibold text-xs">
                      $ {numberWithCommas(selectedPost?.price)}{' '}
                      {selectedPost.action === 'Rent' && ' / mo'}
                    </div>
                    <span className="title-font font-semibold text-xs bg-grey-50 border border-grey-500 text-grey-500 mr-2 mt-2 px-2.5 py-1 rounded-md dark:bg-grey-200 dark:text-grey-800">
                      Negotiable
                    </span>
                  </div>
                  <div className="col-span-4 vertical pl-4">
                    <div className="grid grid-cols-2 gap-1">
                      <div>
                        <div>
                          <FontAwesomeIcon icon={faBuilding} />
                        </div>
                        <div className="title-font font-semibold text-xs">
                          {selectedPost?.floorSize} sq ft
                        </div>
                      </div>
                      <div>
                        <div>
                          <FontAwesomeIcon icon={faMoneyBill} />
                        </div>
                        <div className="title-font font-semibold text-xs">
                          ${' '}
                          {numberWithCommas(
                            (
                              selectedPost?.price / selectedPost?.floorSize
                            ).toFixed(0)
                          )}{' '}
                          psf
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div className="flex flex-col w-full pt-1">
              {/** Property Details */}
              <PropertyDetails postData={selectedPost} />
            </div>

            {/** Description */}
            <div className="relative h-auto">
              <div className="w-full overflow-y-hidden h-full resize-none">
                <div className="font-bold h-6 mt-3 text-gray-800 dark:text-white text-xs leading-8">
                  {getPropFieldsLabel(selectedPost.action).desc}
                </div>
                <div className="h-32 my-2 p-1 border border-gray-200 rounded textarea-container">
                  <textarea
                    readOnly
                    value={selectedPost['desc'] || ''}
                    className="h-full bg-transparent text-sm dark:text-white/80 font-medium p-1 px-1 w-full border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </ModalContent>

      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {isSearch || isFavorite ? (
          isEnquiryMadeForPost(selectedPost.id) ? (
            <button
              className="btn btn-primary"
              disabled
              onClick={handleContactAgentOrOwner}
            >
              {getContactedAgentOrOwner(selectedPost.userId)}
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={handleContactAgentOrOwner}
            >
              {getContactAgentOrOwner(selectedPost.userId)}
            </button>
          )
        ) : (
          !isSearch &&
          !isEnquiry &&
          !isFavorite && (
            <>
              <button className="btn btn-primary" onClick={handleEditClick}>
                {BUTTON_EDIT}
              </button>
              <button
                className="btn btn-danger"
                onClick={openDeletePostConfirmation}
              >
                {BUTTON_DELETE}
              </button>
            </>
          )
        )}
        <button
          className={`btn btn-secondary`}
          aria-label="Close"
          onClick={handleClose}
        >
          {BUTTON_CLOSE}
        </button>
      </div>
    </>
  );
};

export default PostDetail;
