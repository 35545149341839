import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  addEnquiry,
  deletePost,
  getEnquiries,
  getFavorites,
  getPosts,
} from '../common/ApiService';
import { getModalObject, getToastObject } from '../common/Utils';
import * as AppConstants from '../common/AppConstants';
import * as constants from '../common/Constants';
import { isAgentUser } from '../common/Utils';
import PostContactRequest from './post-listing/PostContactRequest';
import Header from './Header';
import MainSection from './MainSection';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import postCache from '../common/PostCache';
import AgentForm from './user-accounts/CreateAgent';
import ChangePassword from './settings/ChangePassword';
import Feedback from './Feedback';
import Login from './Login';
import CreateOwner from './user-accounts/CreateOwner';
import ResetPassword from './settings/ResetPassword';
import Profile from './settings/Profile';
import Posts from './post-listing/Posts';
import BuyRent from './post-listing/BuyRent';
import PostDetail from './post-listing/PostDetail';
import AddEditPost from './add-edit-post/AddEditPost';
import Modal from './common/modals'; // Import the Modal component
import ConfirmModal from './common/modals/ConfirmModal';
import InfoModal from './common/modals/InfoModal';
import SuccessModal from './common/modals/SuccessModal';
import Toast, { VARIANTS } from './common/toast';

const bgStyle = {
  backgroundImage: `url('bg_image.jpg')`,
};

export const AppContext = createContext();

const Home = React.memo(function Home() {
  // State variables
  const loggedInUser = localStorage.getItem(constants.LOGGED_IN_USER);
  const [user, setUser] = useState(loggedInUser ? loggedInUser : null); // Represents the user state
  const [menus, setMenu] = useState(
    // Represents the menu state
    user ? AppConstants.loggedInUserMenu : AppConstants.defaultUserMenu
  );
  const [showMobileNav, setShowMobileNav] = useState(false); // Controls the visibility of the mobile navigation
  const [modalList, setModalList] = useState([]); // Represents the list of modals
  const [toastList, setToastList] = useState([]); // Represents the list of toasts

  const [loading, setLoading] = useState(false); // Represents the loading state
  const [posts, setPosts] = useState([]); // Represents the list of posts
  const [enquires, setEnquires] = useState([]); // Represents the list of enquires
  const [favorites, setFavorites] = useState([]); // Represents the list of favorites
  const [sortValue, setSortValue] = useState('price_asc'); // Represents the current sort value
  const [selectedPost, setSelectedPost] = useState(null); // Represents the selected post
  const prevSelectedPostRef = useRef(null);
  const prevEditPostRef = useRef(null);

  // To add toast in existing toastlist
  const addToast = useCallback(
    (type, message) => {
      setToastList((toastList) => {
        let tmpList = [...toastList];
        if (tmpList.length > 2) {
          tmpList.splice(0, 1);
        }
        tmpList.push(getToastObject(type, message));
        return tmpList;
      });
    },
    [setToastList]
  );

  // Function to open modal
  const openModal = useCallback(
    (title, message, isSearch, isEnquiry, isFavorite, isGuest) => {
      setShowMobileNav(false);
      setModalList((prevModals) => [
        ...prevModals,
        getModalObject(
          title,
          menuMapping[title]?.size || 'md',
          message,
          isSearch,
          isEnquiry,
          isFavorite,
          isGuest
        ),
      ]);
    },
    []
  );

  // Function to close modal
  const closeModal = useCallback(
    (toastType, msg, id) => {
      if (id) {
        setModalList((prevList) => prevList.filter((e) => e.id !== id));
      }
      if (toastType && msg) {
        addToast(toastType, msg);
      }
    },
    [addToast, selectedPost]
  );

  const logout = useCallback(
    (id) => {
      const _user = null;
      localStorage.removeItem(constants.LOGGED_IN_USER);
      setUser(_user);
      postCache.clear();
      closeModal(VARIANTS.Success.name, constants.RESPONSE_LOGOUT_SUCCESS, id);
    },
    [closeModal]
  );

  const deleteSelectedPost = useCallback(
    async (id) => {
      const deleteRequest = {
        id: selectedPost?.id,
        propertyId: selectedPost?.propertyId,
        userId: selectedPost?.userId,
      };
      // Send the id to Deletepost API and get a response.
      const response = await deletePost(deleteRequest);
      console.log('Delete post response', response);
      if (response?.status === 200) {
        if (response?.data) {
          setSelectedPost(null);
          console.log('Post deleted successfully');
          closeModal(null, null, id); // closes delete post confirmation modal
          const viewEditPostModal = modalList.find(
            (modal) => modal.title === constants.VIEW_POST
          );
          if (viewEditPostModal) {
            closeModal(null, null, viewEditPostModal.id); // closes view/edit post modal
            addToast(
              VARIANTS.Success.name,
              constants.RESPONSE_DELETE_POST_SUCCESS
            );
          }
          fetchData();
        }
      }
    },
    [selectedPost]
  );

  const openPrivacyPolicy = () => {
    openModal(constants.PRIVACY_POLICY);
  };

  const openTermsOfService = () => {
    openModal(constants.TERMS_OF_SERVICE);
  };

  const openLogoutDialog = () => {
    openModal(constants.LOGOUT);
  };

  const openViewPost = (post, isSearch, isEnquiry, isFavorite) => {
    if (!post?.id) {
      return;
    }
    // isSearch field to show contact owner button instead of edit/delete
    openModal(constants.VIEW_POST, null, isSearch, isEnquiry, isFavorite);
    setSelectedPost(post);
    prevSelectedPostRef.current = post;
  };

  const openEditPost = () => {
    openModal(constants.EDIT_POST);
    prevEditPostRef.current = selectedPost;
  };

  const openDeletePostConfirmation = () => {
    openModal(constants.DELETE_POST);
  };

  const openRecoverPwdDialog = () => {
    openModal(constants.PASSWORD_RESET);
  };

  const openGuestLogin = () => {
    openModal(constants.LOGIN, null, null, null, null, true);
  };

  const handleGuestSignup = () => {
    openModal(constants.CREATE_GUEST_ACCOUNT, null, null, null, null, true);
  };

  const handleContactAgentOrOwner = () => {
    if (!user) {
      //1. launch  guest login dialog
      openGuestLogin();
      // 2. Launch guest account creation dialog
      // 3. Launch guest account creation confirmation dialog
      // 4. launch  guest login dialog again
      // 5. upon successful login, initiate post addition to enquires and sending mail to respected parties
    } else {
      // Confirm contact ow/ag dialog & Initiate post addition to enquires and sending mail to respected parties
      if (isAgentUser(selectedPost.userId)) {
        openModal(constants.CONTACT_AGENT);
      } else {
        openModal(constants.CONTACT_OWNER);
      }
    }

    // 1. login modification
    // 2. Owner account modification
    // 3. Resuse confirmation dialog
    // 4. Retain login
    // 5. Retain post detail - Confirm contact ow/ag dialog
    // 6. BE operation
    // 7. Refresh Menu items
  };

  const contactAgentOrOwner = useCallback(
    async (id) => {
      // Send the enquiry and get a response.
      let enquiry = {
        postId: selectedPost.id,
        postDetail: selectedPost.name || selectedPost.address,
        guestId: user.id,
        guestName: user.userName,
        guestEmail: user.email,
        guestNo: '+' + user.ccode + '-' + user.whatsapp,
        agentOrOwnerId: selectedPost.userId,
      };
      const response = await addEnquiry(enquiry);
      console.log('Add enquiry response', response);
      if (response.status === 200) {
        // close confirmation modal and show toastr
        closeModal(
          constants.TEXT_SUCCESS,
          'Enquiry message sent successfully',
          id
        );
        fetchEnquiryPosts();
      } else {
        // close confirmation modal and show toastr
        closeModal(constants.TEXT_FAILURE, response.data.result, id);
      }
    },
    [user, selectedPost]
  );

  useEffect(() => {
    // To check loggedin user
    const loggedInUser = localStorage.getItem(constants.LOGGED_IN_USER);
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
    }

    // scrollToTop
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // For menu
    const handleResize = () => {
      if (window.innerWidth < 1060) {
        setShowMobileNav(false);
      } else if (window.innerWidth > 1060) {
        setShowMobileNav(true);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setMenu(
      user
        ? user.type === constants.TEXT_GUEST
          ? AppConstants.loggedInGuestUserMenu
          : AppConstants.loggedInUserMenu
        : AppConstants.defaultUserMenu
    );
  }, [user]);

  const fetchData = async () => {
    if (postCache.posts.length > 0) {
      setPosts(postCache.posts);
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      var userId;
      const loggedInUser = localStorage.getItem(constants.LOGGED_IN_USER);
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        userId = foundUser?.id;
      }
      // setPosts(constants.sampleResponse);
      const response = await getPosts({
        userId: userId,
        sortBy: 'date_desc',
      });
      if (response?.status === 200 && response?.data) {
        postCache.posts = response?.data;
        setPosts(response?.data);
      } else {
        postCache.posts = [];
        setPosts([]);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      postCache.posts = [];
      setPosts([]);
    }
    setLoading(false);
  };

  const fetchEnquiryPosts = async (show) => {
    // If user not logged in return
    const loggedInUser = localStorage.getItem(constants.LOGGED_IN_USER);
    if (!loggedInUser) {
      return;
    }
    if (postCache.enquiries.length > 0) {
      show = false;
      setEnquires(postCache.enquiries);
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      var userId;
      if (loggedInUser) {
        const foundUser = JSON.parse(loggedInUser);
        userId = foundUser?.id;
      }
      // setPosts(constants.sampleResponse);
      const response = await getEnquiries(
        {
          userId: userId,
        },
        show
      );
      if (response?.status === 200 && response?.data) {
        postCache.setEnquiries(response?.data);
        setEnquires(response?.data);
      } else {
        setEnquires([]);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      setEnquires([]);
    }
    setLoading(false);
  };

  const fetchFavoritesPosts = async () => {
    // If user not logged in return
    const loggedInUser = localStorage.getItem(constants.LOGGED_IN_USER);
    if (!loggedInUser) {
      return;
    }

    if (postCache.favorites.length > 0) {
      setFavorites(postCache.favorites);
      setLoading(false);
    } else {
      setLoading(true);
    }
    try {
      var userId;
      const foundUser = JSON.parse(loggedInUser);
      userId = foundUser?.id;
      const response = await getFavorites({
        userId: userId,
      });
      if (response?.status === 200 && response?.data) {
        postCache.favorites = response?.data;
        setFavorites(response?.data);
      } else {
        setFavorites([]);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
      setFavorites([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchEnquiryPosts();
      fetchData();
    }
  }, [sortValue, user]);

  // Function to handle menu click
  const handleMenuClick = useCallback(
    (menu) => {
      if (menu.title === constants.VIEW_ENQUIRES) {
        fetchEnquiryPosts(true);
        openModal(menu.title);
      } else if (menu.title === constants.VIEW_FAVORITES) {
        // fetchFavoritesPosts();
        openModal(menu.title);
      } else {
        openModal(menu.title);
      }
    },
    [openModal]
  );

  const handleProfileEdit = () => {
    openModal(constants.PROFILE);
  };

  const menuMapping = {
    [constants.LOGOUT]: {
      size: 'xs',
      component: (id) => (
        <ConfirmModal
          id={id}
          size={'xs'}
          text={constants.TEXT_LOGOUT_CONFIRMATION}
          onClose={(id) => closeModal(null, null, id)}
          ConfirmBtnText={constants.BUTTON_LOGOUT}
          onConfirm={(id) => logout(id)}
        />
      ),
    },
    [constants.DELETE_POST]: {
      size: 'xs',
      component: (id) => (
        <ConfirmModal
          id={id}
          size={'xs'}
          text={constants.TEXT_DELETE_POST_CONFIRMATION}
          onClose={(id) => closeModal(null, null, id)}
          ConfirmBtnText={constants.BUTTON_DELETE}
          onConfirm={() => deleteSelectedPost(id)}
        />
      ),
    },
    [constants.INFO_MODAL]: {
      size: 'xs',
      component: (id, message) => (
        <InfoModal
          id={id}
          size={'xs'}
          text={message}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.CREATE_OWNER_ACCOUNT]: {
      size: 'xl',
      component: (id) => (
        <CreateOwner
          id={id}
          size={'xl'}
          onClose={closeModal}
          openModal={openModal}
        />
      ),
    },
    [constants.CREATE_AGENT_ACCOUNT]: {
      size: 'xl',
      component: (id) => (
        <AgentForm
          id={id}
          size={'xl'}
          onClose={closeModal}
          openModal={openModal}
        />
      ),
    },
    [constants.CREATE_GUEST_ACCOUNT]: {
      size: 'xl',
      component: (id, msg, isSearch, isEnquiry, isFavorite, isGuest) => (
        <CreateOwner
          id={id}
          size={'xl'}
          isGuest={isGuest}
          onClose={closeModal}
          openModal={openModal}
        />
      ),
    },
    [constants.LOGIN]: {
      size: 'xl',
      component: (id, msg, isSearch, isEnquiry, isFavorite, isGuest) => (
        <Login
          id={id}
          size={'xl'}
          showGuestSignup={isGuest}
          handleGuestSignup={handleGuestSignup}
          openRecoverPwd={openRecoverPwdDialog}
          onClose={closeModal}
          onLoggedIn={(user, toastType, msg) => {
            setUser(user);
            addToast(toastType, msg);
          }}
          addTost={addToast}
        />
      ),
    },
    [constants.PROFILE]: {
      size: 'xl',
      component: (id) => (
        <Profile
          id={id}
          size={'xl'}
          addTost={addToast}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.PASSWORD_RESET]: {
      size: 'xl',
      component: (id) => (
        <ResetPassword
          id={id}
          size={'xl'}
          addTost={addToast}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.FEEDBACK_CONTACT]: {
      size: 'xl',
      component: (id) => (
        <Feedback
          id={id}
          size={'xl'}
          addTost={addToast}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.ADD_POST]: {
      size: 'xl',
      component: (id) => (
        <AddEditPost
          id={id}
          size={'xl'}
          onClose={closeModal}
          onSuccess={(_id) => {
            fetchData();
            closeModal(
              VARIANTS.Success.name,
              constants.RESPONSE_ADD_POST_SUCCESS,
              _id
            );
          }}
        />
      ),
    },
    [constants.EDIT_POST]: {
      size: 'xl',
      component: (id) => (
        <AddEditPost
          id={id}
          size={'xl'}
          data={selectedPost}
          onClose={closeModal}
          onSuccess={(_id) => {
            fetchData();
            closeModal(
              constants.TEXT_SUCCESS,
              constants.RESPONSE_UPDATE_POST_SUCCESS,
              _id
            );
          }}
        />
      ),
    },
    [constants.VIEW_MY_POSTS]: {
      size: 'xl',
      component: (id) => (
        <Posts id={id} size={'xl'} onClose={closeModal} addToast={addToast} />
      ),
    },
    [constants.VIEW_ENQUIRES]: {
      size: 'xl',
      component: (id) => (
        <Posts
          id={id}
          size={'xl'}
          isEnquiry={true}
          onClose={closeModal}
          addToast={addToast}
        />
      ),
    },
    [constants.VIEW_FAVORITES]: {
      size: 'xl',
      component: (id) => (
        <Posts
          id={id}
          size={'xl'}
          isFavorites={true}
          onClose={closeModal}
          addToast={addToast}
        />
      ),
    },
    [constants.VIEW_POST]: {
      size: 'xl',
      component: (id, msg, isSearch, isEnquiry, isFavorite) => (
        <PostDetail
          id={id}
          size={'xl'}
          isGuest={user?.type === constants.TEXT_GUEST ? true : false}
          isSearch={isSearch}
          isEnquiry={isEnquiry}
          isFavorite={isFavorite}
          onClose={closeModal}
        />
      ),
    },
    [constants.CONTACT_AGENT]: {
      size: 'xl',
      component: (id) => (
        <PostContactRequest
          id={id}
          size={'xl'}
          onClose={(id) => closeModal(null, null, id)}
          ConfirmBtnText={constants.BUTTON_YES}
          onConfirm={() => contactAgentOrOwner(id)}
          cancelBtnText={constants.BUTTON_NO}
        />
      ),
    },
    [constants.CONTACT_OWNER]: {
      size: 'xl',
      component: (id) => (
        <PostContactRequest
          id={id}
          size={'xl'}
          isDangerous
          onClose={(id) => closeModal(null, null, id)}
          ConfirmBtnText={constants.BUTTON_YES}
          onConfirm={() => contactAgentOrOwner(id)}
          cancelBtnText={constants.BUTTON_NO}
        />
      ),
    },
    [constants.CHANGE_PWD]: {
      size: 'xl',
      component: (id) => (
        <ChangePassword
          id={id}
          size={'xl'}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.BUTTON_BUY]: {
      size: 'xl',
      component: (id) => (
        <BuyRent
          id={id}
          size={'xl'}
          action={constants.TEXT_SELL}
          addToast={addToast}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.BUTTON_RENT]: {
      size: 'xl',
      component: (id) => (
        <BuyRent
          id={id}
          size={'xl'}
          action={constants.TEXT_RENT}
          addToast={addToast}
          onClose={(id) => closeModal(null, null, id)}
        />
      ),
    },
    [constants.PRIVACY_POLICY]: {
      size: 'xl',
      component: (id) => (
        <PrivacyPolicy id={id} size={'xl'} onClose={closeModal} />
      ),
    },
    [constants.TERMS_OF_SERVICE]: {
      size: 'xl',
      component: (id) => (
        <TermsOfService id={id} size={'xl'} onClose={closeModal} />
      ),
    },
    [constants.BUTTON_LIST]: {
      size: 'xl',
      component: (id) => (
        <AddEditPost
          id={id}
          size={'xl'}
          onClose={closeModal}
          createAccount={true}
          openModal={openModal}
          onSuccess={(_id) => {
            fetchData();
            closeModal(
              VARIANTS.Success.name,
              constants.RESPONSE_ADD_POST_SUCCESS,
              _id
            );
          }}
        />
      ),
    },
  };

  return (
    <div
      style={bgStyle}
      className="relative bg-no-repeat bg-center bg-cover flex flex-col items-center h-screen"
    >
      <AppContext.Provider
        value={{
          addToast,
          loading,
          setLoading,
          posts,
          setPosts,
          enquires,
          favorites,
          sortValue,
          setSortValue,
          prevSelectedPostRef,
          selectedPost,
          setSelectedPost,
          openViewPost,
          openEditPost,
          openDeletePostConfirmation,
          handleContactAgentOrOwner,
        }}
      >
        {/* header */}
        <Header
          showMobileNav={showMobileNav}
          setShowMobileNav={setShowMobileNav}
          handleMenuClick={handleMenuClick}
          handleProfileEdit={handleProfileEdit}
          handleLogoutClick={openLogoutDialog}
          user={user}
          menus={menus}
        />
        {/* body */}
        <MainSection
          openModal={openModal}
          handlePrivacyPolicy={openPrivacyPolicy}
          handleTermsOfService={openTermsOfService}
        />
        {/* To show toast msg on bottom right  */}
        <Toast toastlist={toastList} setList={setToastList} />
        {/* Render the modal */}
        <>
          {modalList.map((modal) => {
            return (
              <Modal
                key={modal.id}
                id={modal.id}
                title={modal.title}
                size={modal.size}
                onClose={closeModal}
              >
                {menuMapping[modal.title]?.component(
                  modal.id,
                  modal.message,
                  modal.isSearch,
                  modal.isEnquiry,
                  modal.isFavorite,
                  modal.isGuest
                )}
                {/* Pass modal.id as an argument */}
              </Modal>
            );
          })}
          {/* Modal Overlay */}
          {modalList.length !== 0 && (
            <div className="opacity-40 fixed inset-0 z-30 bg-black" />
          )}
        </>
      </AppContext.Provider>
    </div>
  );
});

export default Home;
