import {
  faAngleLeft,
  faAngleRight,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import {
  TEXT_NO_ENQ_FOUND,
  TEXT_NO_FAV_FOUND,
  TEXT_NO_POST_ADDED,
  TEXT_NO_SEARCH_FOUND
} from '../../common/Constants';
import { AppContext } from '../Home';
import Loading from '../Loading';
import ListItem from './ListItem';

const sortOptions = [
  { value: 'price_asc', label: 'Price: Low to High' },
  { value: 'price_desc', label: 'Price: High to Low' },
  { value: 'date_asc', label: 'Date: Old to New' },
  { value: 'date_desc', label: 'Date: New to Old' },
];
const PostList = ({ posts, isSearch, isEnquiries, isFavorites, addToast }) => {
  const scrollContainerRef = useRef(null);
  const [showScrollNavigators, setShowScrollNavigators] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const contentWidth = scrollContainerRef.current.scrollWidth;
      setShowScrollNavigators(contentWidth > containerWidth);
    };

    handleResize(); // Check initially
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -scrollContainerRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: scrollContainerRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="relative">
      <div
        ref={scrollContainerRef}
        className={`flex ${
          posts?.length < 3 && !isMobile ? 'justify-center' : ''
        } overflow-x-auto px-4 w-full`}
      >
        {posts?.map((post, i) => {
          return (
            <ListItem
              key={i}
              post={post}
              postLength={posts?.length}
              count={i + 1}
              isSearch={isSearch}
              isEnquiries={isEnquiries}
              isFavorites={isFavorites}
              addToast={addToast}
            />
          );
        })}
      </div>
      {showScrollNavigators && (
        // Left scroll navigator
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
          <button
            className="bg-white border dark:text-black border-gray-300 rounded-full w-8 h-8 flex items-center justify-center shadow"
            onClick={scrollLeft}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
        </div>
      )}
      {showScrollNavigators && (
        // Right scroll navigator
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
          <button
            className="bg-white dark:text-black border border-gray-300 rounded-full w-8 h-8 flex items-center justify-center shadow"
            onClick={scrollRight}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      )}
    </div>
  );
};

const ListView = ({
  isSearchList,
  loading,
  posts,
  addToast,
  isEnquiries,
  isFavorites,
}) => {
  const { sortValue, setSortValue } = useContext(AppContext);
  // Function to handle sort selection
  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <section className="text-gray-600 dark:text-white body-font">
          {posts.length === 0 ? (
            <>
              {!isSearchList && !isEnquiries && !isFavorites && (
                <p className="content flex justify-center">
                  {TEXT_NO_POST_ADDED}
                </p>
              )}
              {isSearchList && (
                <p className="content flex justify-center">
                  {TEXT_NO_SEARCH_FOUND}
                </p>
              )}
              {isEnquiries && (
                <p className="content flex justify-center">
                  {TEXT_NO_ENQ_FOUND}
                </p>
              )}
              {isFavorites && (
                <p className="content flex justify-center">
                  {TEXT_NO_FAV_FOUND}
                </p>
              )}
            </>
          ) : (
            <>
              <div className={`flex flex-row justify-around`}>
                <h2 className="text-sm font-semibold rounded border appearance-none border-gray-400 py-1 pl-1 pr-5 bg-white leading-tight focus:outline-none focus:border-blue-500 text-gray-600 ">
                  {posts.length} Properties
                </h2>
                {isSearchList && (
                  <div className="relative inline-flex">
                    <select
                      className="text-sm font-semibold rounded border appearance-none border-gray-400 py-1 pl-1 pr-5 bg-white leading-tight focus:outline-none focus:border-blue-500 text-gray-600 "
                      value={sortValue}
                      onChange={handleSortChange}
                    >
                      <option disabled value="">
                        Sort by
                      </option>
                      {sortOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <FontAwesomeIcon icon={faSort} />
                    </div>
                  </div>
                )}
              </div>
              <PostList
                posts={posts}
                isSearch={isSearchList}
                isEnquiries={isEnquiries}
                isFavorites={isFavorites}
                addToast={addToast}
              />
            </>
          )}
        </section>
      )}
    </>
  );
};

export default ListView;
