export const CREATE_OWNER_ACCOUNT = 'Create Free Owner Account';
export const CREATE_AGENT_ACCOUNT = 'Create Free Agent Account';
export const LOGIN = 'Login';
export const LOGGED_IN_USER = 'proproprop_user';

export const PROFILE = 'Profile';
export const PRIVACY_POLICY = 'Privacy Policy';
export const TERMS_OF_SERVICE = 'Terms of service';
export const PASSWORD_RESET = 'Recover Password';
export const FORGOT_PWD = 'Forgot Password';
export const CHANGE_PWD = 'Change Password';
export const FEEDBACK_CONTACT = 'Feedback / Contact';

// export const RECOVER_OR_CHANGE_PWD = 'Recover / Change Password';
export const EMAIL_VALIDATION_EXPIRY = 1;
export const POST_LIST_LIMIT = 10;

export const SALE = 'Sale';

export const ADD_POST = 'Add Property Listing';
export const VIEW_POST = 'View / Edit Property Listing';
export const EDIT_POST = 'Edit Property Post';
export const DELETE_POST = 'Delete Property Post';
export const VIEW_MY_POSTS = 'View / Edit My Property Listing';
export const VIEW_ENQUIRES = 'View Enquires';
export const VIEW_FAVORITES = 'View Favorites';
export const PRIVACY_POLICY_TITLE = 'ProproproP Privacy Policy';
export const TERMS_OF_SERVICE_TITLE = 'ProproproP Terms of Service';
export const LOGOUT = 'Logout';
export const INFO_MODAL = 'Info';
export const VIEW_ALL = 'View All';
export const CONTACT_AGENT = 'Contact Agent';
export const CONTACT_OWNER = 'Contact Owner';
export const CREATE_GUEST_ACCOUNT = 'Create Guest Account';
export const BUTTON_GUEST_SIGNUP = 'Guest Sign Up';

// Labels
export const LABEL_EMAIL_OR_WHATSAPP = 'Email / Whatsapp';
export const LABEL_PASSWORD = 'Password';
export const LABEL_RE_ENTER_PASSWORD = 'Re-Enter Password';

// Texts
export const TEXT_SELL = 'Sell';
export const TEXT_RENT = 'Rent';
export const TEXT_LIST = 'List Property'
export const TEXT_AGENT = 'Agent';
export const TEXT_GUEST = 'Guest';
export const TEXT_SUCCESS = 'Success';
export const TEXT_FAILURE = 'Failure';
export const TEXT_LOGOUT_CONFIRMATION = 'Are you sure you want to logout?';
export const TEXT_DELETE_POST_CONFIRMATION = 'Are you sure you want to delete?';
export const TEXT_NO_FILE_SELECTED = 'No file selected';
export const TEXT_UPLOAD_MULTIPLE_FILES = 'Upload multiple files';
export const TEXT_SUPPORTED_IMAGE_FORMATS = 'PNG, JPG';
export const TEXT_SUPPORTED_IMAGE_COUNT =
  'Maximum 10 pictures can be uploaded.';
export const TEXT_NO_SEARCH_FOUND =
  'No property listing found for the selected criteria';
export const TEXT_NO_POST_ADDED = 'You are yet to add a property listing';
export const TEXT_NO_ENQ_FOUND = "You haven't made a enquiry";
export const TEXT_NO_FAV_FOUND = "You haven't marked any post as favorite";
export const TEXT_CONTACT_AGENT_CONFIRMATION =
  'Are you sure you want to contact the agent?';
export const TEXT_CONTACT_OWNER_CONFIRMATION =
  'Are you sure you want to contact the owner?';

// Buttons
export const BUTTON_EDIT_PROFILE = 'Edit Profile';
export const BUTTON_PLEASEWAIT = 'Please Wait...';
export const BUTTON_OKAY = 'Okay';
export const BUTTON_RESEND_VERIFICATION_EMAIL = 'Resend Verification e-Mail';
export const BUTTON_BUY = 'Looking to Buy';
export const BUTTON_RENT = 'Looking to Rent';
export const BUTTON_LIST = "I want to list my property for FREE!"
export const BUTTON_PREVIOUS = 'Previous';
export const BUTTON_CLOSE = 'Close';
export const BUTTON_BACK = 'Back';
export const BUTTON_NEXT = 'Next';
export const BUTTON_SUBMIT = 'Submit';
export const BUTTON_EDIT = 'Edit';
export const BUTTON_UPDATE = 'Update';
export const BUTTON_DELETE = 'Delete';
export const BUTTON_CONFIRM = 'Confirm';
export const BUTTON_FILTER = 'List';
export const BUTTON_LOGIN = 'Login';
export const BUTTON_LOGOUT = 'Logout';
export const BUTTON_CANCEL = 'Cancel';
export const BUTTON_YES = 'Yes';
export const BUTTON_NO = 'No';

// Response Messages
export const RESPONSE_VERIFICATION_EMAIL_SUCCESS =
  'Verification mail is sent successfully';
export const RESPONSE_VERIFICATION_EMAIL_FAILURE = '';
export const RESPONSE_RESEND_VERIFICATION_SUCCESS =
  'Re-send verification mail is sent successfully';
export const RESPONSE_RESEND_VERIFICATION_FAILURE = '';
export const RESPONSE_LOGOUT_SUCCESS = 'Logged out successfully';
export const RESPONSE_LOGOUT_FAILURE = '';
export const RESPONSE_ADD_POST_SUCCESS = 'Your post added successfully';
export const RESPONSE_ADD_POST_FAILURE = '';
export const RESPONSE_UPDATE_POST_SUCCESS = 'Your post updated successfully';
export const RESPONSE_UPDATE_POST_FAILURE = '';
export const RESPONSE_DELETE_POST_SUCCESS = 'Your post deleted successfully';
export const RESPONSE_DELETE_POST_FAILURE = '';

// Error
export const ERROR_OOPS = 'Oops! Something went Wrong';
