import { getPropFieldsLabel } from '../../common/AppConstants';
import {
  getDate,
  getPropertyType,
  NumberFormatterForSingDollars,
} from '../../common/Utils';

// This Component is used for post preview-confirmation and in view property details
const FieldValueDisplay = ({ field, value, postData }) => {
  const propertyLabels = getPropFieldsLabel(postData.action);
  switch (field.name) {
    case 'type':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {getPropertyType(postData)}
          </p>
        </>
      );
    case 'subType':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {`${postData?.type} Type`}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {postData.subType}
          </p>
        </>
      );
    case 'bombShelter':
    case 'tenanted':
    case 'broker':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
          </p>
        </>
      );
    case 'floorSize':
    case 'landSize':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {value} sq ft
          </p>
        </>
      );
    case 'price':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            $ {NumberFormatterForSingDollars(value, 0)}
            {postData.action === 'Rent' ? '/ mo' : ''}
          </p>
        </>
      );
    case 'yearBuilt':
    case 'renovationYear':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {value != null ? new Date(value).getFullYear() : 'Not Available'}
          </p>
        </>
      );
    case 'maintenanceAmount':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            $ {NumberFormatterForSingDollars(value, 0)}
          </p>
        </>
      );
    case 'facilities':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {value?.length > 0 ? value.join(', ') : 'Not Available'}
          </p>
        </>
      );
    case 'createdAt':
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {getDate(value)}
          </p>
        </>
      );
    default:
      return (
        <>
          <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
            {propertyLabels[field.name]}
          </h2>
          <p className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
            {value === '' || value === null ? 'Not Available' : value}
          </p>
        </>
      );
  }
};

export default FieldValueDisplay;
