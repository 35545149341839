// TODO - Need to change all the caches to Map instead of array.
const postCache = {
  posts: [],
  filteredPosts: {}, // Buy/Rent
  favorites: [],
  enquiries: [],
  postWithProperties: {},
  setEnquiries: function (list) {
    this.enquiries = list || [];
  },
  setFilteredPosts: function (query, list) {
    const key = JSON.stringify(query);
    if (list) {
      this.filteredPosts[key] = list;
    } else {
      delete this.filteredPosts.key;
    }
  },
  getFilteredPosts: function (query) {
    const key = JSON.stringify(query);
    return this.filteredPosts[key];
  },
  clear: function () {
    this.posts = [];
    // this.filteredPosts = {};
    this.favorites = [];
    this.enquiries = [];
    // this.postWithProperties = {};
  },
};

export default postCache;
