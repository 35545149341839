import React, { useContext, useState } from 'react';
import { BUTTON_CANCEL } from '../../common/Constants';
import ModalContent from '../ModalContent';
import { getLoggedInUser, isAgentUser } from '../../common/Utils';
import { AppContext } from '../Home';

const PostContactRequest = ({
  id,
  size,
  onClose,
  ConfirmBtnText,
  onConfirm,
  cancelBtnText,
}) => {
  const { selectedPost } = useContext(AppContext);
  const loggedInUser = getLoggedInUser();
  const [confirmationSent, setConfirmationSent] = useState(false);

  const handleConfirmClick = () => {
    if (!confirmationSent) {
      setConfirmationSent(true);
      onConfirm(id);
    }
  };

  return (
    <>
      <ModalContent size={size}>
        <div className="flex items-center justify-center">
          <p className="text-base text-gray-700 dark:text-white mt-1">
            <p className="mt-4 title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
              To:{' '}
              <span className=" text-sm font-medium text-gray-500 dark:text-white/80">
                {selectedPost?.userName}
              </span>
            </p>

            <h2 className="mt-4 title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
              Re: Property:{' '}
              <span className="text-sm font-medium text-gray-500 dark:text-white/80">
                {selectedPost?.name || selectedPost?.address}
              </span>
            </h2>
            <p className="mt-4 title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
              Listing No:{' '}
              <span className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
                {selectedPost?.propertyId}
              </span>
            </p>

            <p className="mt-4 title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
              Dear {isAgentUser(selectedPost.userId) ? 'Agent' : 'Owner'}
              {', '}
              <br></br>
              <span className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
                I am interested in your property above. Can you kindly contact
                me at:
              </span>
            </p>

            <p className="mt-1 title-font font-medium text-gray-900 dark:text-white tracking-widest text-xs">
              Email:{' '}
              <span className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
                {loggedInUser?.email}
              </span>
            </p>
            <p className="mt-1 title-font font-medium text-gray-900 dark:text-white tracking-widest text-xs">
              Whatsapp:{' '}
              <span className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">{`${loggedInUser?.ccode} ${loggedInUser?.whatsapp}`}</span>
            </p>

            <p className="mt-4 title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
              From:{' '}
              <span className="mt-1 text-sm font-medium text-gray-500 dark:text-white/80">
                {loggedInUser?.userName}
              </span>
            </p>
          </p>
        </div>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button
          aria-label={ConfirmBtnText}
          onClick={handleConfirmClick}
          type="button"
          className={`btn btn-primary`}
          disabled={confirmationSent}
        >
          {ConfirmBtnText}
        </button>
        <button
          aria-label="Cancel"
          onClick={() => onClose(id)}
          type="button"
          className="btn btn-secondary"
        >
          {cancelBtnText ? cancelBtnText : BUTTON_CANCEL}
        </button>
      </div>
    </>
  );
};

export default PostContactRequest;
