import React, { useContext } from 'react';
import * as appConstants from '../../../common/AppConstants';
import { SearchContext } from '../BuyRent';
import CheckBoxes from '../common/Checkboxes';
import RadioButtons from '../common/RadioButtons';
import ListView from '../../common/ListView';
import { getOptionsWithSelectedFalse } from '../../../common/Utils';

const PropertyTypeStep = ({ onSelect }) => {
  return (
    <RadioButtons
      field={'type'}
      options={appConstants.propertyFields.map((item) => item['type'])}
    />
  );
};

const HDBEstateTypeStep = ({ onSelect }) => {
  return (
    <>
      <TipText />
      <CheckBoxes
        columns={3}
        options={appConstants.hdbEstates}
        field={'hdbEstates'}
        isMultiple={true}
        onSelect={onSelect}
      />
    </>
  );
};

const RoomTypeStep = ({ onSelect }) => {
  return (
    <>
      <TipText />
      <CheckBoxes
        columns={3}
        options={appConstants.propertyFields}
        field={'subType'}
        isMultiple={true}
        onSelect={onSelect}
      />
    </>
  );
};

const PriceStep = ({ onSelect, action }) => {
  const { searchValues } = useContext(SearchContext);
  return (
    <>
      <TipText />
      <CheckBoxes
        columns={3}
        options={
          action === appConstants.actions[0] // For Sell
            ? appConstants.getSellPriceList(searchValues?.type)
            : appConstants.rentPriceList
        }
        field={'price'}
        isMultiple={true}
        onSelect={onSelect}
      />
    </>
  );
};

const TipText = () => {
  return (
    <div className="text-sm font-medium text-red-500">
      * You can select multiple choices or skip this
    </div>
  );
};
const AreaStep = ({ onSelect }) => {
  return (
    <CheckBoxes
      columns={3}
      options={getOptionsWithSelectedFalse([...appConstants.areas]).map(
        (area) => area.name
      )}
      field={'area'}
      isMultiple={true}
      onSelect={onSelect}
    />
  );
};

const ListStep = ({ addToast }) => {
  const { loading, posts } = useContext(SearchContext);
  return (
    <div className="flex flex-col w-full">
      <div className="flex-auto">
        <ListView
          isSearchList={true}
          loading={loading}
          posts={posts}
          addToast={addToast}
        />
      </div>
    </div>
  );
};

const StepperContent = ({
  action,
  currentStep,
  additionalStep,
  stepsLength,
  applyFilter,
  addToast,
  onSelect,
}) => {
  if (currentStep === 1) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <PropertyTypeStep onSelect={onSelect} />
    );
  } else if (currentStep === 2 && additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <HDBEstateTypeStep onSelect={onSelect} />
    );
  } else if (currentStep === 2 && !additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <RoomTypeStep onSelect={onSelect} />
    );
  } else if (currentStep === 3 && additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <RoomTypeStep onSelect={onSelect} />
    );
  } else if (currentStep === 3 && !additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <PriceStep onSelect={onSelect} action={action} />
    );
  } else if (currentStep === 4 && additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <PriceStep onSelect={onSelect} action={action} />
    );
  } else if (currentStep === 4 && !additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <AreaStep onSelect={onSelect} />
    );
  } else if (currentStep === 5 && additionalStep) {
    return applyFilter ? (
      <ListStep addToast={addToast} />
    ) : (
      <AreaStep onSelect={onSelect} />
    );
  } else if (
    (currentStep === 5 && !additionalStep) ||
    currentStep === stepsLength
  ) {
    return <ListStep addToast={addToast} />;
  } else {
    return null;
  }
};

export default StepperContent;
