import React from 'react';
import ModalContent from '../../ModalContent';
import { BUTTON_CANCEL } from '../../../common/Constants';
import useIsMobile from '../../../hooks/useIsMobile';

// This component is used for logout and delete post
const ConfirmModal = ({
  id,
  size,
  text,
  onClose,
  ConfirmBtnText,
  onConfirm,
  cancelBtnText,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      <ModalContent size={size}>
        <div className="flex items-center justify-center">
          <p className="text-base text-gray-700 dark:text-white mt-1">{text}</p>
        </div>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button
          aria-label={ConfirmBtnText}
          onClick={() => onConfirm(id)}
          type="button"
          className={`btn btn-primary`}
        >
          {ConfirmBtnText}
        </button>
        <button
          aria-label="Cancel"
          onClick={() => onClose(id)}
          type="button"
          className="btn btn-secondary"
        >
          {cancelBtnText ? cancelBtnText : BUTTON_CANCEL}
        </button>
      </div>
    </>
  );
};

export default ConfirmModal;
