import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { PostStepperContext, defaultValues } from '../../AddEditPost';
import { getGridColumns, getPropertyOptions } from '../../../../common/Utils';
import { propertyTypes } from '../../../../common/AppConstants';

// This Component is used for action type, Property type, Room type, HDB Estate, and district
const RadioButtons = ({ options, field }) => {
  const gridColumns = getGridColumns(); // Get gridColumns value using the utility function
  const { postData, setPostData, setAdditionalStep, setErrors } =
    useContext(PostStepperContext);
  // get functions to build form with useForm() hook
  const { register } = useForm({ mode: 'all' });
  const [checkedStatus, setCheckedStatus] = useState({});
  const optionsToShow = getPropertyOptions(field, options, postData);

  useEffect(() => {
    console.log('RadioButton.useEffect : postData -> ', postData);
    const newCheckedStatus = {};
    optionsToShow?.forEach((option) => {
      const optionVal = typeof option === 'string' ? option : option.name;
      newCheckedStatus[optionVal] = getCheckedStatus(optionVal);
    });
    setCheckedStatus(newCheckedStatus);
  }, [field, postData]);

  const handleChange = (e) => {
    const { value } = e.target;
    if (
      (field === 'type' && value === propertyTypes.PROPERTY_TYPE_HDB) ||
      (field !== 'type' && postData?.type === propertyTypes.PROPERTY_TYPE_HDB)
    ) {
      setAdditionalStep(true);
    } else {
      setAdditionalStep(false);
    }

    // Update checked status when field or postData changes
    const newCheckedStatus = { ...checkedStatus };
    optionsToShow?.forEach((option) => {
      const optionVal = typeof option === 'string' ? option : option.name;
      newCheckedStatus[optionVal] = getCheckedStatus(optionVal);
    });
    setCheckedStatus(newCheckedStatus);
    // Use functional update to avoid unnecessary dependencies
    if (field === 'action') {
      // If action is being changed, reset all other values
      setPostData((prevPostData) => ({
        ...defaultValues,
        [field]: value,
      }));
      // Reset errors
      setErrors([]);
    } else if (field === 'type') {
      // If type is being changed, reset other values except action
      setPostData((prevPostData) => ({
        ...defaultValues,
        action: prevPostData.action,
        [field]: value,
      }));
      setErrors([]);
    } else {
      setPostData((prevPostData) => ({
        ...prevPostData,
        [field]: value,
      }));
    }
  };

  if (!optionsToShow) {
    return null;
  }

  const getCheckedStatus = (optionVal) => {
    if (
      (postData[field] === true && optionVal === 'Yes') ||
      (postData[field] === false && optionVal === 'No') ||
      postData[field] === optionVal
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      key={field}
      className={`px-5 ${
        field === 'bombShelter' || field === 'tenanted' || field === 'broker'
          ? 'flex'
          : `grid gap-1 ${gridColumns}`
      }`}
    >
      {optionsToShow.map((option, i) => {
        const optionId = typeof option === 'string' ? option : option.id;
        const optionVal = typeof option === 'string' ? option : option.name;
        return (
          <div key={i} className="flex items-center">
            <input
              checked={checkedStatus[optionVal]}
              id={optionId}
              type="radio"
              value={optionVal}
              name={field}
              className="w-4 h-4 mb-1 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-0 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              {...register(field, { required: 'Please select one.' })}
              onChange={handleChange}
            />
            <label
              htmlFor={optionId}
              className="px-2 mb-3 w-full text-sm font-medium cursor-pointer text-gray-500 dark:text-gray-300"
            >
              {optionVal}
            </label>
          </div>
        );
      })}
    </div>
  );
};

RadioButtons.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
};

export default RadioButtons;
