import * as Yup from 'yup';
import { isEmail, isWhatsapp } from './Utils';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const pwdRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/;
const pwdRegExpText =
  ' Password must contain at least - one number, one special character, one lowercase letter, one uppercase letter - and should be 6 to 12 characters';

const nameRegExp = /^[a-zA-Z ]{3,50}$/;

const companyJobRegExp = /^[a-zA-Z0-9 ]{3,50}$/;

export const loginValidation = () => {
  const validationSchema = Yup.object().shape({
    email_or_whatsapp: Yup.string()
      .required('Email is required')
      .test('email_or_whatsapp', 'Email is invalid', (value) => {
        return validateEmail(value);
      }),
    password: Yup.string().required('Password is required'),
  });
  return validationSchema;
};

export const passwordResetValidation = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .test('email', 'Email is invalid', (value) => {
        return validateEmail(value);
      }),
  });
  return validationSchema;
};

export const changePwdValidation = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Current password is required')
      .min(6, 'Password must be at least 6 characters'),
    newPassword: Yup.string()
      .required('New password is required')
      .matches(pwdRegExp, pwdRegExpText),
    cpassword: Yup.string()
      .required('Re-enter Password is required')
      .oneOf(
        [Yup.ref('newPassword'), null],
        'New password and Re-enter password must match'
      ),
  });
  return validationSchema;
};

const validateEmail = (email) => {
  return isEmail(email);
};

export const ownerAccountValidation = () => {
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .trim()
      .required('Name is required')
      .matches(
        nameRegExp,
        'Name should contain only alphabets. Maximum 50 chars including spaces'
      ),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    whatsapp: Yup.string()
      .required('Whatsapp is required')
      .min(8, 'Whatsapp number is not valid - Should be 8 digits')
      .min(8, 'Whatsapp number is not valid - Should be 8 digits')
      .matches(phoneRegExp, 'Whatsapp number is not valid'),
    password: Yup.string()
      .required('Password is required')
      .matches(pwdRegExp, pwdRegExpText),
    cpassword: Yup.string()
      .required('Re-enter Password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Password and Re-enter password must match'
      ),
  });

  return validationSchema;
};

export const agentAccountValidation = () => {
  // Add validation for CEA Registration No
  const ceaRegistrationNo = Yup.string()
    .required('CEA Registration Number is required')
    // Staring with letter P or R followed by 6 digits and last char should be letter (total - 8chars)
    .matches(/^[RP]\d{6}[A-Za-z]$/, 'CEA Registration Number is not valid');

  const agencyLicenseNo = Yup.string()
    .required('Agency license number is required')
    .matches(/^L\d{7}[A-Za-z]$/, 'Agency license Number is not valid');

  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .trim()
      .required('Name is required')
      .matches(
        nameRegExp,
        'Name should contain only alphabets. Maximum 50 chars including spaces'
      ),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    whatsapp: Yup.string()
      .required('Whatsapp is required')
      .min(8, 'Whatsapp number is not valid - Should be 8 digits')
      .min(8, 'Whatsapp number is not valid - Should be 8 digits')
      .matches(phoneRegExp, 'Whatsapp number is not valid'),
    company: Yup.string()
      .trim()
      .required('Company name is required')
      .matches(
        companyJobRegExp,
        'Company name should contain only alphabets and numbers. Maximum 50 chars including spaces'
      ),
    jobTitle: Yup.string()
      .trim()
      .required('Job title is required')
      .matches(
        companyJobRegExp,
        'Job title should contain only alphabets and numbers. Maximum 50 chars including spaces'
      ),
    registrationNo: Yup.string()
      .required('CEA registration number is required')
      .transform((value) => value.toUpperCase()) // Convert to uppercase
      .concat(ceaRegistrationNo),
    licenseNo: Yup.string()
      .required('Agency license number is required')
      .transform((value) => value.toUpperCase()) // Convert to uppercase
      .concat(agencyLicenseNo),
    password: Yup.string()
      .required('Password is required')
      .matches(pwdRegExp, pwdRegExpText),
    cpassword: Yup.string()
      .required('Re-enter Password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Password and Re-enter password must match'
      ),
  });

  return validationSchema;
};

export const editAccountValidation = (userType) => {
  const ceaRegistrationNo = Yup.string()
    .required('CEA Registration Number is required')
    // Staring with letter P or R followed by 6 digits and last char should be letter (total - 8chars)
    .matches(/^[RP]\d{6}[A-Za-z]$/, 'CEA Registration Number is not valid');
  // Staring with letter L followed by 7 digits and last char should be any letter (total - 9chars)
  const agencyLicenseNo = Yup.string()
    .required('Agency license number is required')
    .matches(/^L\d{7}[A-Za-z]$/, 'Agency license Number is not valid');

  let commonSchema = {
    userName: Yup.string()
      .trim()
      .required('Name is required')
      .matches(
        nameRegExp,
        'Name should contain only alphabets. Maximum 50 chars including spaces'
      ),
    whatsapp: Yup.string()
      .required('Whatsapp is required')
      .min(8, 'Whatsapp number is not valid - Should be 8 digits')
      .min(8, 'Whatsapp number is not valid - Should be 8 digits')
      .matches(phoneRegExp, 'Whatsapp number is not valid'),
  };

  if (userType === 'Agent') {
    return Yup.object().shape({
      ...commonSchema,
      company: Yup.string()
        .trim()
        .required('Company name is required')
        .matches(
          companyJobRegExp,
          'Company name should contain only alphabets and numbers. Maximum 50 chars including spaces'
        ),
      jobTitle: Yup.string()
        .trim()
        .required('Job title is required')
        .matches(
          companyJobRegExp,
          'Job title should contain only alphabets and numbers. Maximum 50 chars including spaces'
        ),
      registrationNo: Yup.string()
        .required('CEA registration number is required')
        .transform((value) => value.toUpperCase()) // Convert to uppercase
        .concat(ceaRegistrationNo),
      licenseNo: Yup.string()
        .required('Agency license number is required')
        .transform((value) => value.toUpperCase()) // Convert to uppercase
        .concat(agencyLicenseNo),
    });
  } else {
    // Handled default schema for owner and guest
    return Yup.object().shape({
      ...commonSchema,
    });
  }
};

export const feedbackValidation = () => {
  const validationSchema = Yup.object().shape({
    regarding: Yup.string()
      .nullable()
      .required('Please select a Regarding option'),
    desc: Yup.string().trim().required('Description of feedback is required'),
    email: Yup.string()
      .required('Email is required')
      .test('email', 'Email is invalid', (value) => {
        return validateEmail(value);
      }),
  });
  return validationSchema;
};
