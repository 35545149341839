import { useEffect, useState } from 'react';

function useMobileOrientation() {
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust the breakpoint as needed
    };

    const handleOrientationChange = (event) => {
      setIsLandscape(event.matches);
    };

    // Initial checks
    checkIsMobile();

    // Listen for window resize events
    window.addEventListener('resize', checkIsMobile);
    // Create a media query for landscape orientation
    const mediaQuery =
      window.matchMedia && window.matchMedia('(orientation: landscape)');

    // if (mediaQuery) {
    //   // Check orientation initially
    //   handleOrientationChange(mediaQuery);
    // }
    // Add an event listener for changes to the orientation
    mediaQuery.addEventListener('change', handleOrientationChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkIsMobile);
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return { isMobile, isLandscape };
}

export default useMobileOrientation;
