import React, { useContext, useEffect, useRef, useState } from 'react';
import { PostStepperContext } from '../AddEditPost';
import useIsMobile from '../../../hooks/useIsMobile';

const StepperNavigation = ({ steps, currentStep, handleStepperClick }) => {
  const [newStep, setNewStep] = useState([]);
  const stepsRef = useRef();
  const { additionalStep, postData } = useContext(PostStepperContext);
  const isMobile = useIsMobile();

  const updateStep = (stepNumber, steps) => {
    // Filter the array to remove objects without a "description" property
    const newSteps = [...steps].filter((item) => item?.description);
    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      }

      //step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      //step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) => {
      if (index === 2) {
        step = `${postData?.type} Type`;
      }

      if (!additionalStep && index === 3) {
        return null; // To skip HDB estate for other property types
      }
      if (additionalStep && index === 5) {
        return null; // To skip district for HDB
      } else {
        return Object.assign(
          {},
          {
            description: step,
            completed: false,
            highlighted: index === 0 ? true : false,
            selected: index === 0 ? true : false,
          }
        );
      }
    });

    stepsRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
  }, [steps, currentStep, additionalStep]);

  const stepsDisplay = newStep.map((step, index) => {
    return (
      <div key={index} className={'flex-shrink-0'}>
        <div className="relative flex flex-col items-center">
          <div
            className={`cursor-pointer mt-2 rounded-lg transition duration-500 ease-in-out h-2 ${
              isMobile ? 'w-6' : 'w-14'
            } ${
              step.selected
                ? 'bg-blue-500 dark:bg-blue-500'
                : 'bg-gray-300 dark:bg-gray-300'
            } ${isMobile ? 'mr-2' : ''}`}
            onClick={() => {
              handleStepperClick(index + 1);
            }}
          ></div>
        </div>
      </div>
    );
  });

  return (
    <>
      <h3 className="text-base font-semibold dark:text-white">
        {currentStep}. {newStep[currentStep - 1]?.['description']}
      </h3>
      <div
        className={`flex ${
          isMobile ? 'justify-start ' : 'justify-between'
        } items-center overflow-x-auto flex-wrap`}
      >
        {stepsDisplay}
      </div>
    </>
  );
};
export default StepperNavigation;
