import React from 'react';

// This component is used for profile, and account creation (Owner and agent)
const FormField = ({
  label,
  name,
  register,
  errors,
  type = 'text',
  children,
}) => {
  return (
    <div className="py-2">
      <div className="w-full">
        <div className="flex flex-wrap -m-3">
          <div className="w-full md:w-1/3 p-3">
            <p className="text-sm text-gray-800 dark:text-white font-medium">
              {label}
            </p>
          </div>
          <div className="w-full md:flex-1 p-3">
            <input
              className={`w-full px-4 py-1 text-sm dark:text-white font-normal bg-transparent shadow-input rounded outline-none border ${
                !errors[name]?.message
                  ? 'focus:border-blue-500 border-gray-300'
                  : 'border-red-700 focus:ring-red-700 focus:border-red-700'
              }`}
              type={type}
              placeholder=""
              id={name}
              {...register(name)}
            />
            {children}
            <span className="text-xs text-red-700">
              {errors[name]?.message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormField;

// Component for the WhatsApp input field
export const WhatsAppInput = ({
  errors,
  register,
  ccode,
  whatsapp,
  handleCCodeChange,
  handleWhatsAppNoChange,
}) => {
  return (
    <div className="py-2">
      <div className="w-full">
        <div className="flex flex-wrap -m-3">
          <div className="w-full md:w-1/3 p-3">
            <p className="text-sm text-gray-800 dark:text-white font-medium">
              Whatsapp
            </p>
          </div>
          <div className="w-full md:flex-1 p-3">
            <div
              className={`flex items-center overflow-hidden border ${
                !(errors.ccode?.message || errors.whatsapp?.message)
                  ? 'focus-within:border-blue-500  border-gray-300'
                  : 'focus-within:border-red-700  border-red-700'
              } bg-transparent rounded shadow-input`}
            >
              <input
                className={`w-[70px] px-4 py-1 text-sm dark:text-white font-normal outline-none border-l ${
                  !errors.ccode?.message
                    ? 'focus-within:border-blue-500  border-gray-300'
                    : 'focus-within:border-red-700  border-red-700'
                } bg-transparent`}
                type="text"
                id="ccode"
                name="ccode"
                value={ccode}
                readOnly={true}
              />
              <input
                className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-l ${
                  !errors.whatsapp?.message
                    ? 'focus-within:border-blue-500  border-gray-300'
                    : 'focus-within:border-red-700  border-red-700'
                } bg-transparent`}
                type="text"
                maxLength={8}
                placeholder=""
                id="whatsapp"
                name="whatsapp"
                value={whatsapp}
                onChange={handleWhatsAppNoChange}
                {...register('whatsapp', {
                  onChange: (e) => handleWhatsAppNoChange(e),
                })}
              />
            </div>
            <span className="text-xs text-red-700">
              {errors.ccode?.message}
              {errors.ccode && <br></br>}
              {errors.whatsapp?.message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
