import {
  faBars,
  faTimes,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import {
  BUTTON_EDIT_PROFILE,
  BUTTON_LOGOUT,
  TEXT_AGENT
} from '../common/Constants';
import { getInitials, getLoggedInUser } from '../common/Utils';

const Header = ({
  showMobileNav,
  setShowMobileNav,
  handleMenuClick,
  handleProfileEdit,
  handleLogoutClick,
  menus,
}) => {
  const [showDropdown, setShowDropdown] = useState(false); // New state for the dropdown
  const dropdownRef = useRef(null); // Reference to the dropdown element
  const user = getLoggedInUser();

  // Function to handle clicks outside the dropdown
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    // Add event listener when the dropdown is shown
    if (showDropdown) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      // Remove event listener when the dropdown is hidden
      document.removeEventListener('click', handleOutsideClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showDropdown]);

  const handleUserClick = () => {
    // User profile click enabled only for loggedin user
    if (user) {
      setShowDropdown(!showDropdown);
    }
  };

  const handleEdit = () => {
    handleProfileEdit();
    setShowDropdown(false);
  };

  const handleLogout = () => {
    handleLogoutClick();
    setShowDropdown(false);
  };

  return (
    <header className="absolute flex w-full p-2 items-end justify-end text-sm text-gray-800">
      {/* right section */}
      <nav className="hidden md-lg:flex md-lg:flex-col font-Ubuntu items-end">
        <div
          className="px-8 flex items-end justify-end"
          onClick={handleUserClick}
        >
          {user?.image ? (
            <img
              loading="lazy"
              className={`h-8 rounded-full cursor-pointer`}
              src={user.image}
              alt="profile pic"
            />
          ) : user?.userName ? (
            <div className="cursor-pointer h-8 w-8 rounded-full mx-auto relative inline-flex items-center justify-center overflow-hidden bg-blue-600">
              <span className="font-semibold text-white text-lg">
                {getInitials(user?.userName)}
              </span>
            </div>
          ) : (
            <FontAwesomeIcon
              className={`rounded-full cursor-pointer`}
              icon={faUserCircle}
              size="2x"
              color="white"
            />
          )}
        </div>
        <div className="mt-0 flex flex-col">
          {menus.map((menu) => (
            <label
              key={menu.id}
              className="px-6 mt-0 text-white cursor-pointer text-xl font-bold"
              style={{ fontFamily: 'Calibri, Arial, sans-serif', fontSize: '14px' }}
              aria-label={menu.title}
              onClick={() => handleMenuClick(menu)}
            >
              {menu.title}
            </label>
          ))}
        </div>
      </nav>
      {/* hamburger */}
      {showMobileNav ? (
        // close button
        <FontAwesomeIcon
          icon={faTimes}
          className="fixed right-[25px] text-3xl z-50 md-lg:hidden top-5 cursor-pointer dark:text-white"
          aria-hidden="true"
          onClick={() => setShowMobileNav(!showMobileNav)}
        ></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon
          icon={faBars}
          className="text-3xl md-lg:hidden text-white cursor-pointer"
          aria-hidden="true"
          onClick={() => setShowMobileNav(!showMobileNav)}
        ></FontAwesomeIcon>
      )}
      {/* mobile nav */}
      <nav
        className={`h-full fixed top-[0px] flex flex-col items-center w-60 pt-8 md-lg:hidden bg-white dark:bg-gray-800/90 dark:text-white z-40 duration-1000 ${
          showMobileNav ? 'right-[0px]' : 'right-[-100vw]'
        } `}
      >
        <div className="px-8 pt-6">
          {/* Use onClick to handle user icon click */}
          <FontAwesomeIcon
            className={`rounded-full cursor-pointer`}
            icon={user?.image ? faUserCircle : faUserCircle}
            size="2x"
            onClick={handleUserClick}
          />
        </div>
        <div className="mt-3 ">
          {menus.map((menu) => (
            <label
              key={menu.id}
              className="px-6 cursor-pointer text-base font-bold text-medium"
              aria-label={menu.title}
              onClick={() => handleMenuClick(menu)}
            >
              {menu.title}
            </label>
          ))}
        </div>
      </nav>
      {/* Dropdown */}
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute top-16 mt-3 p-2 sm:max-w-full max-w-md overflow-hidden shadow-lg bg-white border border-gray-300 rounded-md z-50"
        >
          {/* Dropdown content */}
          <div className="border-b">
            <div className="p-2 flex">
              {user?.image ? (
                <img
                  className="h-12 w-12 rounded-full"
                  src="https://randomuser.me/api/portraits/men/24.jpg"
                  alt="Randy Robertson"
                />
              ) : (
                <div className="h-12 w-12 rounded-full relative inline-flex items-center justify-center overflow-hidden bg-blue-600">
                  <span className="font-semibold text-white text-xl">
                    {getInitials(user?.userName)}
                  </span>
                </div>
              )}
              <div className="pl-3">
                {user?.userName && (
                  <p className="text-sm font-semibold">{user.userName}</p>
                )}
                {user?.email && (
                  <p className="text-xs text-gray-600">{user.email}</p>
                )}
                {user?.whatsapp && (
                  <p className="text-xs text-gray-600">
                    {`+${user?.ccode}`} {user.whatsapp}
                  </p>
                )}
              </div>
            </div>

            {user?.type === TEXT_AGENT && (
              <>
                <hr></hr>
                <div className="p-2 flex">
                  <div className="pl-3 text-xs">
                    {user?.company && (
                      <>
                        <p className="font-semibold">Company Name :</p>
                        <p className="text-gray-600">{user.company}</p>
                      </>
                    )}
                    {user?.jobTitle && (
                      <>
                        <p className="font-semibold">Job Title :</p>
                        <p className="text-gray-600">{user.jobTitle}</p>
                      </>
                    )}
                    {user?.registrationNo && (
                      <>
                        <p className="font-semibold">Registration No :</p>
                        <p className="text-gray-600">{user.registrationNo}</p>
                      </>
                    )}
                    {user?.licenseNo && (
                      <>
                        <p className="font-semibold">License No :</p>
                        <p className="text-gray-600">{user.licenseNo}</p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <hr></hr>

            <div className="p-2 flex">
              <div className="pl-3 text-xs">
                <>
                  <p className="font-semibold">User Id :</p>
                  <p className="text-gray-600">{user.id}</p>
                </>
                {user?.loginTime && (
                  <>
                    <p className="font-semibold">Last Login :</p>
                    <p className="text-gray-600">
                      {new Date(user?.loginTime).toLocaleString()}
                    </p>
                  </>
                )}
              </div>
            </div>

            <hr></hr>

            <div
              className="p-2 hover:bg-gray-200 flex cursor-pointer"
              onClick={handleEdit}
            >
              <button className="py-1 px-4 font-semibold text-xs text-gray-600">
                {BUTTON_EDIT_PROFILE}
              </button>
            </div>

            <hr></hr>

            <div
              className="p-2 hover:bg-gray-200 flex cursor-pointer"
              onClick={handleLogout}
            >
              <button className="py-1 px-4 font-semibold text-xs text-gray-600">
                {BUTTON_LOGOUT}
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
