import React, { useCallback } from 'react';
import { resendVerificationEMail } from '../../common/ApiService';
import * as constants from '../../common/Constants';

// This component is used in user accounts creation and in login
function ResendEmailVerificationButton(props) {
  const handleResendVerificationEmail = useCallback(async () => {
    props.setloading(true);

    // Triggering Resend email verification API and get a response.
    try {
      const response = await resendVerificationEMail({ email: props.email });
      if (response.status === 200) {
        props.onClose(
          constants.TEXT_SUCCESS,
          constants.RESPONSE_VERIFICATION_EMAIL_SUCCESS
        );
      } else {
        props.setApiResponseMsg({
          type: constants.TEXT_FAILURE,
          message: response?.data.message,
          data: { code: response.status },
        });
      }
    } catch (error) {
      props.setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        message: error.message,
      });
    } finally {
      props.setloading(false);
    }
  }, [props]);

  return (
    <div>
      <button
        className="flex mx-auto text-sm  text-blue-600 dark:text-blue-500 hover:underline mt-4"
        aria-label="Resend Verification Email"
        onClick={handleResendVerificationEmail}
      >
        {constants.BUTTON_RESEND_VERIFICATION_EMAIL}
      </button>
    </div>
  );
}

export default ResendEmailVerificationButton;
