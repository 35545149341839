import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { getGridColumns, getPropertyOptions } from '../../../common/Utils';
import { SearchContext } from '../BuyRent';

export const CheckBox = ({ label, isChecked, onChange }) => {
  return (
    <label className="p-1 flex items-center font-medium cursor-pointer">
      <input
        type="checkbox"
        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500"
        checked={isChecked}
        onChange={onChange}
      />
      <span className="ml-2 text-sm text-gray-700 dark:text-white">
        {label}
      </span>
    </label>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CheckBoxes = ({ options, field, columns = 2, isMultiple }) => {
  const gridColumns = getGridColumns(); // Get gridColumns value using the utility function
  const { searchValues, setSearchValues } = useContext(SearchContext);
  const [list, setList] = useState([]);

  const filteredOptions = getPropertyOptions(field, options, searchValues);

  const handleChange = (item) => {
    if (isMultiple) {
      // For multiple
      const updatedList = list.map((listItem) => {
        if (listItem.id === item.id) {
          return { ...listItem, isSelected: !listItem.isSelected };
        }
        return listItem;
      });

      const selectedOptions = updatedList.filter(
        (listItem) => listItem.isSelected
      );
      const result = selectedOptions.map(
        (listItem) => listItem.value || listItem.name
      );

      setList(updatedList);
      setSearchValues({ ...searchValues, [field]: result });
    }
  };

  useEffect(() => {
    const initializeList = () => {
      let array;
      if (
        Array.isArray(filteredOptions) &&
        filteredOptions.every((item) => typeof item === 'string')
      ) {
        array = filteredOptions.map((item, index) => ({
          id: index,
          name: item,
          value: item,
          isSelected: searchValues[field]?.includes(item) || false, // Check if item is included in searchValues[field]
        }));
      } else {
        array = filteredOptions.map((item) => ({
          ...item,
          isSelected: searchValues[field]
            ? searchValues[field]?.includes(item.value || item.name)
            : false,
        }));
      }
      setList(array);
    };
    initializeList();
  }, [filteredOptions, searchValues, field]);

  if (!filteredOptions) {
    return null;
  }

  return (
    <div className={`grid gap-1 ${gridColumns}`}>
      {list.map((item, i) => (
        <CheckBox
          key={item.id || item}
          label={item.name || item}
          isChecked={item?.isSelected}
          onChange={() => handleChange(item)}
        />
      ))}
    </div>
  );
};

CheckBoxes.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
};

export default CheckBoxes;
