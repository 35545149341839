import React from 'react';
import ModalContent from '../../ModalContent';
import { BUTTON_CLOSE } from '../../../common/Constants';

// This component is used only for mobile view on user accounts creation api response
const InfoModal = ({ id, size, text, onClose, btnText }) => {
  return (
    <>
      <ModalContent size={size}>
        <div className="flex items-center justify-center">
          <p className="text-base text-gray-700 dark:text-white mt-1">{text}</p>
        </div>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button
          aria-label="Cancel"
          onClick={() => onClose(id)}
          type="button"
          className="btn btn-secondary"
        >
          {btnText ? btnText : BUTTON_CLOSE}
        </button>
      </div>
    </>
  );
};

export default InfoModal;
