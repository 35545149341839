import React, { useContext, useState } from 'react';
import postCache from '../../common/PostCache';
import { addFavorite, removeFavorite } from '../../common/ApiService';
import { getLoggedInUser } from '../../common/Utils';
import { TEXT_FAILURE, TEXT_SUCCESS } from '../../common/Constants';
import { getPropertyType, numberWithCommas } from '../../common/Utils';
import { AppContext } from '../Home';

const ListItem = ({
  post,
  count,
  isSearch,
  isEnquiries,
  isFavorites,
  addToast
}) => {
  const { openViewPost, prevSelectedPostRef } = useContext(AppContext);

  const [selectedItem, setSelectedItem] = useState(prevSelectedPostRef.current);
  const [isFavorite, setIsFavorite] = useState(false);

  const handleItemClick = (post) => {
    setSelectedItem(post);
  };

  const handleClick = () => {
    setSelectedItem(post);
    openViewPost(post, isSearch, isEnquiries, isFavorites);
  };

  const markAsFavorite = async () => {
    const loggedInUser = getLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    let query = {
      userId: loggedInUser?.id,
      postId: post.id,
    };
    if (isFavorite) {
      const response = await removeFavorite(query);
      console.log('Remove favorite response', response);
      if (response.status === 200) {
        const updatedFavorites = postCache.favorites.filter(
          (item) => item.id !== post.id
        );
        postCache.favorites = updatedFavorites;
        setIsFavorite(!isFavorite);
        addToast(TEXT_SUCCESS, 'Post removed as favorite');
      } else {
        addToast(TEXT_FAILURE, response.data.result);
      }
    } else {
      const response = await addFavorite(query);
      console.log('Add favorite response', response);
      if (response.status === 200) {
        postCache.favorites.push(post);
        setIsFavorite(true);
        addToast(TEXT_SUCCESS, 'Post marked as favorite');
      } else {
        addToast(TEXT_FAILURE, response.data.result);
      }
    }
  };

  return (
    <div className="w-[220px] py-2 flex-shrink-0">
      <div
        className={`px-2 border-2 rounded-md ${
          selectedItem?.id === post.id
            ? 'border-blue-500'
            : 'border-transparent'
        }`}
        onClick={() => handleItemClick(post)}
        onMouseEnter={() => handleItemClick(post)}
        onMouseLeave={() => handleItemClick(null)}
      >
        <div className="w-full sm:mb-0 mb-6">
          <div className="flex items-center justify-center">
            <div className="text-black dark:text-white rounded-full h-6 flex items-center justify-center truncate">
              <span className="text-xs font-semibold mr-4 truncate">
                {`${count}. ${post.name || post.address}`}
              </span>
            </div>
          </div>
          <div className="relative rounded-lg h-36 overflow-hidden">
          {post.pictures && post.pictures[0] && 
            <img
              onClick={handleClick}
              alt="content"
              className="cursor-pointer object-fill h-full w-full"
              src={
                post.pictures[0]['url'] ||
                post.pictures[0]['data'] ||
                'no_image_available.jpeg'
              }
            />
            }
          </div>
        </div>
        <div className="p-2 cursor-pointer" onClick={handleClick}>
          <div className="font-semibold text-lg text-gray-900 dark:text-white truncate">
            <span className="font-medium text-base">$</span>{' '}
            {numberWithCommas(post?.price)} {post.action === 'Rent' && ' / mo'}
          </div>
          <h1 className="text-gray-800 dark:text-white text-sm font-medium truncate">
            {post.floorSize} {' sq ft'}
          </h1>
          <h1 className="text-gray-800 dark:text-white text-sm font-medium truncate">
            {getPropertyType(post)}
          </h1>
          <h1 className="text-gray-800 dark:text-white text-sm font-medium truncate">
            {post?.subType}
          </h1>
          <h1 className="text-gray-800 dark:text-white text-sm font-medium truncate">
            {post?.district || post?.hdbEstate}
          </h1>
          <div className="flex flex-row flex-wrap pt-1">
            <span className="bg-blue-50 border border-blue-500 text-blue-500 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-md dark:bg-blue-200 dark:text-blue-800">
              {isSearch || isEnquiries || isFavorites
                ? post.action === 'Sell'
                  ? 'Buy'
                  : 'Rent'
                : post.action}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
