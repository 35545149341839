import { BUTTON_NEXT, BUTTON_PREVIOUS } from '../../../common/Constants';
import useIsMobile from '../../../hooks/useIsMobile';

export default function StepperControl({
  additionalStep,
  currentStep,
  isNextDisabled,
  applyFilter,
  handleClick,
  stepsLength,
}) {
  stepsLength = additionalStep ? stepsLength : stepsLength - 1;
  const isMobile = useIsMobile();

  return (
    <div className="flex-grow">
      <button
        className={`btn btn-primary ${isMobile ? 'px-4' : ''}`}
        disabled={currentStep === 1 && !applyFilter ? true : false}
        aria-label={
          currentStep === 1 && !applyFilter ? 'Previous (Disabled)' : 'Previous'
        }
        onClick={() => handleClick()}
      >
        {isMobile ? '<' : BUTTON_PREVIOUS}
      </button>

      {
        <button
          className={`ml-2 btn btn-primary ${isMobile ? 'px-4' : ''}`}
          disabled={
            isNextDisabled || currentStep == stepsLength - 1 || applyFilter
          }
          aria-label={BUTTON_NEXT}
          onClick={() => handleClick('next')}
        >
          {isMobile ? '>' : BUTTON_NEXT}
        </button>
      }
    </div>
  );
}
