import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ModalContent from '../../ModalContent';

// This component is used on delete post success response
function SuccessModal(props) {
  return (
    <>
      <ModalContent size={props.size}>
        <div className="flex flex-col text-center items-center">
          <div className="w-14 h-14 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
            {/* Now it is used for success will changes as dynamic later */}
            <FontAwesomeIcon className="text-3xl" icon={faCheck} />
          </div>
          <div className="flex-grow">
            <p className="text-base text-gray-700 dark:text-white mt-1">
              {props.message}
            </p>
          </div>
        </div>
      </ModalContent>
    </>
  );
}

export default SuccessModal;
