import React, { useState, useMemo } from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BUTTON_NEXT, BUTTON_PREVIOUS } from '../../common/Constants';

// This component is used for adding property pictures and in preview confirm of add/edit post, and in Property detailed view
export const CarouselItem = ({ children }) => {
  return (
    <div className="relative duration-700 ease-in-out inline-flex items-center justify-center h-40 w-full">
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalItems = useMemo(() => React.Children.count(children), [children]);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= totalItems) {
      newIndex = totalItems - 1;
    }
    setActiveIndex(newIndex);
  };

  const renderCarouselItems = () => {
    return React.Children.map(children, (child, index) => {
      return React.cloneElement(child);
    });
  };

  const renderSliderIndicators = () => {
    return React.Children.map(children, (child, i) => {
      const indicatorClasses =
        i === activeIndex ? 'bg-white' : 'border-2 border-white';
      return (
        <button
          key={i}
          aria-label={`Go to slide ${i}`}
          className={`${indicatorClasses} w-3 h-3 rounded-full`}
          onClick={() => updateIndex(i)}
        />
      );
    });
  };

  return (
    <div className="overflow-hidden relative">
      <div
        className="whitespace-nowrap transform duration-300"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {renderCarouselItems()}
      </div>
      {/* Slider indicators  */}
      <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-3 left-1/2">
        {renderSliderIndicators()}
      </div>
      {/* Slider controls  */}
      {totalItems > 1 && (
        <>
          <button
            type="button"
            className="absolute top-8 left-0 z-30 flex items-center justify-center h-24 px-1 cursor-pointer group focus:outline-none"
            data-carousel-prev
            aria-label="Previous"
            onClick={() => updateIndex(activeIndex - 1)}
          >
            <span className="inline-flex items-center justify-center w-6 h-6 rounded-full sm:w-8 sm:h-8 bg-white/50 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-2 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <FontAwesomeIcon className="text-white" icon={faAngleLeft} />
              <span className="sr-only">{BUTTON_PREVIOUS}</span>
            </span>
          </button>
          <button
            type="button"
            className="absolute top-8 right-0 z-30 flex items-center justify-center h-24 px-1 cursor-pointer group focus:outline-none"
            data-carousel-next
            aria-label="Next"
            onClick={() => updateIndex(activeIndex + 1)}
          >
            <span className="inline-flex items-center justify-center w-6 h-6 rounded-full sm:w-8 sm:h-8 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-2 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <FontAwesomeIcon className="text-white" icon={faAngleRight} />
              <span className="sr-only">{BUTTON_NEXT}</span>
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default Carousel;
