import React, { useContext, useEffect, useState } from 'react';
import postCache from '../../common/PostCache';
import useIsMobile from '../../hooks/useIsMobile';
import ModalContent from '../ModalContent';
import { addPost, updatePost, addFreePost } from '../../common/ApiService';
import { getLoggedInUser } from '../../common/Utils';
import { VARIANTS } from '../common/toast';
import { propertyFields, propertyTypes } from '../../common/AppConstants';
import * as constants from '../../common/Constants';
import {
  isApplicableFieldForActionType,
  isMandatoryField,
} from '../../common/Utils';
import { AppContext } from '../Home';
import StepperNavigation from './stepper/StepperNavigation';
import StepperContent from './stepper/StepperContent';
import StepperControl from './stepper/StepperControl';

export const PostStepperContext = React.createContext();

const defaultSteps = [
  'Sell or Rent',
  'Property Type',
  'Room Type',
  'HDB Estate',
  'Property Details',
  'District',
  'Description',
  'Pictures',
  'Account Creation',
  'Preview & Confirm',
];

const propertyValidationFields = [
  'floorLevel',
  'noOfStoreys',
  'furnishing',
  'name',
  'landSize',
  'floorSize',
  'developer',
  'yearBuilt',
  'renovationYear',
  'bombShelter',
  'maintenance',
  'facilities',
  'price',
  'tenure',
  'tenanted',
  'broker',
  'address',
  'postalCode',
  'mrtInfo',
  'schoolInfo',
];

export const defaultValues = {
  type: '',
  subType: '',
  address: '',
  postalCode: null, // number
  district: '',
  hdbEstate: '',
  floorLevel: '',
  furnishing: '',
  bombShelter: null, // bool
  tenanted: null, // bool
  broker: null, // bool
  floorSize: null, // number
  mrtInfo: '',
  schoolInfo: '',
  desc: '',
  pictures: [],
  action: '',
  price: null, // number
  tenure: '',
  userId: '',
  name: '',
  developer: '',
  yearBuilt: null, // year
  maintenanceAmount: null, // number
  facilities: [],
  noOfStoreys: null, // number
  landSize: null, // number
  renovationYear: null, // year
  account: {valid:false}
};

const AddEditPost = ({ id, size, data, onSuccess, onClose, createAccount, openModal }) => {
  const isMobile = useIsMobile();
  const { addToast, setSelectedPost } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [postData, setPostData] = useState(data || defaultValues);
  const [errors, setErrors] = useState([]);
  const steps = [...defaultSteps];
  if(!createAccount){
    steps.splice(8, 1);
  }
  // const steps = defaultSteps;
  const [additionalStep, setAdditionalStep] = useState(
    postData?.type === propertyTypes.PROPERTY_TYPE_HDB ? true : false
  );
  const [selectedProperty, setSelectedProperty] = useState(
    propertyFields.find((property) => property?.type === postData['type'])
  );
  const [fields, setFields] = useState(selectedProperty?.fields);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedProperty(
      propertyFields.find((property) => property?.type === postData['type'])
    );
    setFields(
      propertyFields.find((property) => property?.type === postData['type'])
        ?.fields
    );
  }, [postData]);

  useEffect(() => {
    const foundUser = getLoggedInUser();
    if (!foundUser) {
      return;
    }
    postData.userName = foundUser.userName;
    postData.userNo = '+' + foundUser.ccode + ' ' + foundUser.whatsapp;
    postData.userEmail = foundUser.email;
  }, []);

  const propertyFieldsValidation = (postData) => {
    let isValid = true;
    fields.forEach((field) => {
      if (
        propertyValidationFields.includes(field.name) &&
        isApplicableFieldForActionType(fields, field.name, postData)
      ) {
        const fieldValue = postData[field.name];
        const isFieldMandatory = isMandatoryField(fields, field.name);

        if (isFieldMandatory && field.type !== 'Boolean' && !fieldValue) {
          isValid = false;
          return isValid; // Skip further validation for this field
        }

        if (field.type === 'String' && isFieldMandatory) {
          if (typeof fieldValue !== 'string' || fieldValue.trim() === '') {
            isValid = false;
          } else if (
            ['mrtInfo', 'schoolInfo', 'address'].includes(field.name) &&
            fieldValue.length > 100
          ) {
            isValid = false;
          }
        } else if (field.type === 'Number' && isFieldMandatory) {
          if (
            typeof fieldValue !== 'number' ||
            fieldValue <= 0 ||
            !fieldValue // Year and renovationYear are number/ISOstring
          ) {
            isValid = false;
          } else if (
            'postalCode' === field.name &&
            String(fieldValue).length !== 6
          ) {
            isValid = false;
          }
        } else if (field.type === 'Boolean' && isFieldMandatory) {
          // bombShelter, broker and tenanted are string/boolean
          if (fieldValue === null || fieldValue === undefined) {
            isValid = false;
          }
        } else if (field.type === 'Array' && isFieldMandatory) {
          if (!Array.isArray(fieldValue)) {
            isValid = false;
          } else if (fieldValue.length === 0) {
            isValid = false;
          }
        }
      }
    });

    return isValid;
  };

  const isNextDisabled = (step) => {
    switch (step) {
      case 1:
        return !postData.action;
      case 2:
        return !postData.type;
      case 3:
        return !postData.subType;
      case 4:
        if (additionalStep) {
          return !postData.hdbEstate;
        }
        return !propertyFieldsValidation(postData);
      case 5:
        if (additionalStep) {
          return !propertyFieldsValidation(postData);
        }
        return !postData.district;
      case 6:
        return !postData.desc || postData.desc.length > 3000;
      case 7:
        return postData.pictures.length === 0;
      case 8:
        return !postData.account.valid;
      default:
        return false;
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === 'next' ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const handleStepperClick = (newStep) => {
    if (
      newStep > 0 &&
      newStep <= steps.length &&
      !isNextDisabled(newStep - 1)
    ) {
      setCurrentStep(newStep);
    }
  };

  const onComplete = async () => {
    const foundUser = getLoggedInUser();
    if (!createAccount) {
      if (!foundUser) {
        return;
      }
    }

    setLoading(true);

    let postObj = {};

    // Iterate through the postData object and trim string values
    for (const key in postData) {
      if (typeof postData[key] === 'string') {
        postObj[key] = postData[key].trim();
      } else {
        postObj[key] = postData[key];
      }
    }

    postObj.bombShelter = postData?.bombShelter === 'Yes' ? true : false;
    postObj.tenanted = postData?.tenanted === 'Yes' ? true : false;
    postObj.broker = postData?.broker === 'Yes' ? true : false;
    postObj.yearBuilt = postData?.yearBuilt
      ? new Date(postData?.yearBuilt).getFullYear()
      : null;
    postObj.renovationYear = postData?.renovationYear
      ? new Date(postData?.renovationYear).getFullYear()
      : null;

    if (!createAccount) {
      postObj.userId = foundUser.id;
      postObj.userType = foundUser.type;
    }

    if (createAccount) {
      postObj = {...postObj,...postObj.account};
      const response = await addFreePost(postObj);
      if (response.status === 200) {
        if (response ?.data) {
          postCache.posts.unshift(response ?.data);
          postCache.postWithProperties[response.data.id] = response ?.data;
          const str = `Exciting news! Your property listing has been successfully added and is now active. 
          To take full control and manage your listing, please verify your email address by clicking on 
          the verification link in the email that has been sent to your registered email address. 
          Once verified, you'll have all the tools at your disposal to showcase your property 
          and connect with potential buyers or renters.`;
          onClose(null, null, id);
          openModal(constants.INFO_MODAL, str);
        }
      } else {
        onClose(null, null, id);
        openModal(constants.INFO_MODAL, response ?.data ?.message);
      }
    } else {
      if (!data) {
        // Send the form data to Addpost API and get a response.
        const response = await addPost(postObj);
        console.log('Add post response', response);
        if (response.status === 200) {
          if (response?.data) {
            console.log('Post added successfully');
            postCache.posts.unshift(response?.data);
            postCache.postWithProperties[response.data.id] = response?.data;
            // Showing success modal on success (Passing id to close current add post modal)
            onSuccess(id);
          }
        } else {
          addToast(VARIANTS.Error.name, response?.data?.result);
        }
      } else {
        // Send the form data to Updatepost API and get a response.
        const response = await updatePost(postObj);
        console.log('Edit post response', response);
        if (response.status === 200) {
          if (response?.data) {
            // Find the index of the updated post in postCache.posts
            const updatedPostIndex = postCache.posts.findIndex(
              (post) => post.post_id === postData.post_id
            );

            // If the updated post is found in postCache.posts, replace it with the updated data
            if (updatedPostIndex !== -1) {
              postCache.posts[updatedPostIndex] = postData;
              postCache.postWithProperties = postData;
            }
            // update the selected post
            setSelectedPost(postData);
            // Showing success modal on success (Passing id to close current add post modal)
            onSuccess(id);
          }
        }
      }
    }

    setLoading(false);

    return false;
  };

  const handleClose = () => {
    onClose(null, null, id);
  };

  return (
    <PostStepperContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        postData,
        setPostData,
        errors,
        setErrors,
        additionalStep,
        setAdditionalStep,
      }}
    >
      <ModalContent size={size}>
        <div className="relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
          <div className="mb-3">
            <StepperNavigation
              steps={steps}
              currentStep={currentStep}
              isNextDisabled={isNextDisabled(currentStep)}
              handleStepperClick={handleStepperClick}
            />
          </div>
          <div className="relative py-4 flex flex-col min-w-0 break-words w-full">
            <StepperContent
              createAccount={createAccount}
              additionalStep={additionalStep}
              stepsLength={steps.length}
              currentStep={currentStep}
            />
          </div>
        </div>
      </ModalContent>
      {/* navigation button */}
      <div
        className={`flex flex-row items-center justify-between p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600`}
      >
        {currentStep <= steps.length - 1 && (
          <StepperControl
            additionalStep={additionalStep}
            isNextDisabled={isNextDisabled(currentStep)}
            handleClick={handleClick}
            currentStep={currentStep}
            stepsLength={steps.length}
          />
        )}
        <div>
          {currentStep === steps.length - 1 && (
            <button
              className={`mr-2 btn btn-primary ${isMobile ? 'px-3' : ''}`}
              aria-label={
                !data ? constants.BUTTON_CONFIRM : constants.BUTTON_UPDATE
              }
              onClick={onComplete}
              disabled={loading}
            >
              {!data ? constants.BUTTON_CONFIRM : constants.BUTTON_UPDATE}
            </button>
          )}
          <button
            className={`btn btn-secondary ${isMobile ? 'px-3' : ''}`}
            aria-label="Close"
            onClick={handleClose}
          >
            {constants.BUTTON_CLOSE}
          </button>
        </div>
      </div>
    </PostStepperContext.Provider>
  );
};

export default AddEditPost;
