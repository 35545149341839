import React from 'react';
import ModalContent from './ModalContent';
import { BUTTON_CLOSE } from '../common/Constants';

const PrivacyParagraph = ({ children, className }) => (
  <p className={className}>{children}</p>
);

const PrivacyPolicy = ({ id, size, onClose }) => {
  const handleClick = () => {
    onClose(null, null, id);
  };
  return (
    <>
      <ModalContent size={size}>
        <div className="container mx-auto font-sans dark:text-white">
          <PrivacyParagraph className="mb-4">
            This Privacy Policy applies to the collection, use, and disclosure
            of personal data by Proproprop Private Limited{' '}
            <strong>(“ProproproP”)</strong>
            in accordance with the Personal Data Protection Act (No. 26 of 2012
            ("the PDPA") and as may be amended or supplemented from time to
            time. ProproproP operates the website ProproproP.com.sg, its
            subdomains and apps across various devices including desktops,
            tablets and other mobile devices.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4">
            By accepting the terms under ProproproP Privacy Policy, you
            acknowledge and agree on ProproproP’s collection, use and disclosure
            of your Personal data as defined below. This Privacy Policy is
            applicable to all users, both registered and unregistered guest
            users on all our platforms.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            1. General
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            1.1 This Policy provides information on our obligations and policies
            in respect of an individual customer’s Personal data. We undertake
            to use reasonable efforts in applying, where practicable, those
            principles and the processes set out herein to our operations.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            1.2 Our officers, management, and members of staff shall use
            reasonable endeavour to respect the confidentiality of and keep safe
            all Personal data collected, stored, disclosed, and used for or on
            behalf of ProproproP. ProproproP will ensure its staff to comply
            with the highest standards of security and confidentiality in
            respect of all personal information and data submitted and
            collected. We shall use reasonable endeavors to ensure all
            collection, storage, disclosure, and usage of personal data by
            ProproproP and by anyone acting on its behalf shall be done in an
            appropriate manner and in accordance with the PDPA and this Policy.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            1.3 By interacting with us, submitting information to us, or
            engaging in services offered by us, you agree and consent to
            ProproproP, as well as its respective representatives collecting,
            using, disclosing and sharing amongst themselves your Personal data,
            and disclosing such Personal data to ProropProp's authorized service
            providers and relevant third parties in the manner set forth in this
            Policy.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            1.4 This Policy supplements but does not supersede nor replace any
            other consents you may have previously provided to us in respect of
            your Personal data, and your consents herein are additional to any
            rights which we may have at law to collect, use or disclose your
            Personal data.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            1.5 For the purposes of this Policy, in line with the provisions
            under the PDPA, "Personal data" shall mean data, whether true or
            not, about an individual who can be identified from that data; or
            from that data and other information to which an organization has or
            is likely to have access. Such Personal data shall also refer to
            that which is already in the possession of ProproproP or that which
            shall be collected by ProproproP in the future.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            2. Contacting the Data Protection Officer
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            2.1 Where you legitimately request access to Personal data relating
            to you which is in the possession and control of ProproproP; have
            any questions or feedback relating to your Personal data or this
            Policy; or wish to correct Personal data relating to you which is in
            the possession and control of ProproproP, you may contact our Data
            Protection Officer (“DPO”) through the Feedback/Contact on our
            website in accordance with our standard procedures as stated
            hereinafter. We shall accede to your request within a reasonable
            time and manner.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            2.2 In accordance with the PDPA, ProproproP has established a
            process for receiving and responding to any query or complaint that
            may arise with respect to the application of this PDPA through the
            Feedback/contact channel.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            3. Collection of Personal data
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            3.1 ProproproP may collect your Personal data in various ways,
            including via our website, apps or any other related platforms; when
            you interact with our customer service officers through email,
            telephone or direct face to face communication; when you create an
            account with us; when you submit forms relating to any of our
            products or services; when you enter into any agreement or provide
            other documentation or information in respect of your interactions
            with us; when you subscribe and use our services; when you request
            that we contact you; when you respond to our promotions; when you
            are contacted by, and respond to our marketing representatives; when
            we receive references from business partners and third parties; when
            you use third party applications to provide us your credentials;
            when you list your properties with us; when you upload pictures to
            our platforms; when you search our database for listings; when you
            browse our websites; when you make payments; when we seek
            information from third parties about you in connection with the
            products or services you have applied for; when you submit your
            Personal data to us for any other reason.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            3.2 If you provide us with any personal or third party data, images
            or information in the course of interacting with us in whatever
            manner or means, you represent to us that you have consented and in
            the case of third party, you have already obtained the consent of
            the associated third party for you to provide us with their Personal
            data, images or information for the said purposes.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            3.3 You should ensure that any personal or third party data, images
            or information you provide or submit to us in the course of creating
            your account with us, listing any properties and engaging with us in
            any form or manner are complete, accurate, true and correct.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            3.4 We will collect only personal data which are necessary and
            adequate to providing the required services on our platforms.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            3.5 When you browse our website, you generally do so anonymously but
            please see section 4 below on cookies.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            4. Use and Purposes of the Collection of Personal Data
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            4.1 ProproproP may use your personal data for various purposes
            including but not limited to providing services on our platforms;
            responding to inquiries; sending marketing materials; providing you
            with alerts, market or education materials; conducting surveys,
            market research and analysis; designing our products and services;
            improving customer service; fulfilling out our obligations and
            contracts; improving customer experiences and services; in legal
            proceedings such as collection of dues and disputes; in seeking
            professional advices and complying with applicable legal and
            regulatory requirements.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            4.2 In the course of using our platform services, your personal data
            is collected or you may be asked to provide personal information or
            data in order to benefit from and enjoy the full range of services
            provided by ProproproP. You are not obliged to provide any
            information or data requested by our platforms. However if you fail
            to do so, we may not be able to provide or render our full fledge of
            services to you under this Policy.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            4.3 Your personal data may be used for direct communications with
            you, in response to your queries or feedback, direct marketing to
            you, provide affiliated services and products to you, monitoring
            your rewards, loyalty and other privilege promotions and invitations
            for you to attend events, seminars, forums, conferences, exhibitions
            and launches.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            4.4 We may conduct direct marketing through emails, direct mails,
            telephone, face to face or other means of communication. You may
            choose not to receive any marketing or promotional materials or
            invitation by informing us.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            4.5 We may also use independent third party vendors to analyse the
            anonymised data collected for research purposes, marketing,
            education and understanding industry trends.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            5. Disclosure of Personal Data
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            5.1 We undertakes all practicable measures to keep your personal
            information and data confidential subject to the following:
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            5.2 We may disclose your personal data to various parties including
            but not limited to our affiliates and subsidiaries and their
            offices, directors, employees, agents, contractors, service
            providers, business partners, advisors, auditors, financial
            institutions and any other related or third parties whom we consider
            necessary or appropriate and are under a duty of confidentiality to
            us.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            5.3 We will only disclose your personal data in compliance with what
            is permitted by PDPA to improve and enhance your browser experience,
            enable the use of our platform services, integrate with third party
            platforms, respond to your communications with us, to monitor
            customer feedback and complains, identify and verify user account
            creations, verify property listings and perform various internal
            security checks.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            5.4 We will also have to disclose your personal data, if we are
            required to comply under any applicable rules, Singapore laws and
            regulations, industry codes of conduct, practice or guidelines, or
            where ProproproP needs to protect and defend its rights and property
            or any request to assist in law enforcement and investigations by
            relevant Singapore authorities, judicial proceedings, court order or
            legal process served on ProproproP, our affiliates, subsidiaries and
            platforms.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            5.5 We may also disclose anonymised aggregated data collected to
            provide media announcements and communications, perform analysis,
            research and track market trends, manage customer quality assurances
            and employee training and education.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            6. Protection of Personal Data
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            6.1 ProproproP takes the protection of your personal data seriously.
            We will take reasonable steps to ensure that your personal data is
            protected against unauthorized access, collection, use, disclosure,
            copying, modification, disposal or similar risks.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            6.2 Registered account users should safeguard their usernames and
            passwords at all times. Under the terms of this policy, you are not
            allowed to let others use your account for any purposes related to
            our platforms. We reserve the right to immediately terminate your
            account membership without refund if we have reasonable evidence
            that your account is being lend or allowed to be used by another
            person other than you.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            6.3 Our platforms use third party payment gateway service providers
            to facilitate electronic payment transactions. All sensitive
            information related to banks or credit cards needed to complete the
            payment transaction use secure socket layer technology (SSL). We do
            not store or retain any personal banking or credit card information
            in our servers.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            6.4 We will take reasonable safety precautions to prevent the loss,
            misuse or alteration of your personal data. We store all personal
            data on secure servers.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            6.5 We will not be responsible for the security of your data during
            transmission to our platforms. Any breaches of security while
            accessing our platforms or during transmission of data is at your
            own risks as the network security is not provided by us and is
            beyond our control.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            7. Cookies
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            7.1 When you interact with us on our websites, we automatically
            receive and record information on our server logs from your browser.
            We may employ cookies in order for our server to recognize a return
            visitor as a unique user including, without limitation, monitoring
            information relating to how a visitor arrives at the website, what
            kind of browser a visitor is on, what operating system a visitor is
            using, a visitor's IP address, and a visitor's clickstream
            information and time stamp (for example, which pages they have
            viewed, the time the pages were accessed and the time spent per web
            page).
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            7.2 Cookies are small text files stored in your computing or other
            electronic devices which allow us to remember you or other data
            about you. The cookies placed by our server are readable only by us
            and cookies cannot access, read or modify any other data on an
            electronic device.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            7.3 By continuing to browse our platforms, you are agreeing to our
            use of cookies. All web-browsers offer the option to refuse any
            cookie. If you refuse our cookie(s), some parts of our website may
            not function properly.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            8. Use of Cookies
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            8.1 The cookies are generally used to help the website remember
            information about your visit, personalize your browsing experience
            which will make it easier for you to visit the website again and
            make the website more useful to you. Cookies help to enrich your
            browser experience with us.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            8.2 The cookies are required for the operation of our platform
            services such as logging into secure areas of our platforms,
            membership tracking, listings, creating user accounts, and payment
            services.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            8.3 We may collect information on our website using cookies to
            analyze anonymized aggregated data such as frequency of use, page
            views, favorites, time spent on the website, etc.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            8.4 We may also use third-party cookies such as Google Analytics
            cookies together with our cookies to analyze, research, and optimize
            customer experiences. You may go to Google Ads settings to disable
            their cookies or download and install their Google Analytics Opt Out
            browser plug-in.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            8.5 Third parties such as network service providers may also use
            cookies to perform analytical and performance services on the net
            and are beyond our control.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            9. Third-Party Sites Linked to our Platforms
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            9.1 Our platforms may contain links to and from third-party
            platforms such as our partner networks, advertisers, and affiliates.
            If you follow a link to any of these third-party platforms or
            services, ProproproP does not accept any responsibility or liability
            for any policies or for any personal data that may be collected by
            these platforms or services. We encourage you to learn about the
            privacy policies of the linked third-party websites. Some of these
            third-party websites may be co-branded with our logo or trademark,
            even though they are not operated or maintained by us. Once you have
            left our platforms, you should check the applicable terms,
            conditions, and policies of the third-party website to determine how
            they will handle any information they collect from you.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            10. Termination, Access and Amendment of your Personal Data
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.1 You have the right to withdraw consent to the use of your
            personal data or obtain access to or make corrections to your
            personal data records. You can directly make amendments to your
            personal data by logging in to your account. If you wish to delete
            all personal data collected by our platforms, send your request to
            our Feedback/Contact link.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.2 Kindly note that if you withdraw your consent to any or all use
            of your personal data, ProproproP will not be in a position to
            continue to provide its products or services to you and this will
            result in the termination of any contractual agreements you have
            with ProproproP. ProproproP reserves all legal rights and remedies
            in such event.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.3 ProproproP will retain your personal data for as long as it is
            necessary to fulfill the purpose for which it was collected, or as
            required or permitted by applicable laws.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.4 We may also refuse to disclose, delete or amend any Personal
            data for the reasons set out in the PDPA.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.5 We may terminate your ProroproP account immediately without
            prior notice under the following circumstances at our discretion:
            requested by law enforcement agencies or other government agencies;
            court order; breaches or violations of our privacy and terms of
            policy; infringement of intellectual property rights of others;
            self-initiated withdrawal of consent to any or all use of your
            personal data; misrepresentations and inaccuracies of data and
            information submitted to us during account creation, registration
            and listings; use of third party information, images and data in
            your submissions and communications with us without obtaining prior
            consent from their legal owners; expiry of membership;
            discontinuance or disruption to our business due to circumstances
            that are no longer viable for us to commercially operate; disruption
            of services from our third party vendors and unexpected technical or
            security issues.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.6 The termination of your account shall be without prejudice to
            any of our rights and remedies we may be entitled to under our
            Privacy Policy and Terms of Service.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            10.7 Your account and rights under our Terms of Service are not
            transferable under any circumstances.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            11. Your Consent and Rights
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            11.1 By registering as an account user or by browsing our platforms
            as an unregistered guest user, you hereby unreservedly consent to
            the collection and use of your personal data and other activities as
            prescribed in this Policy and in accordance with PDPA.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            12. Changes to this Policy
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            12.1 ProproproP reserves the right to update, revise or amend this
            Privacy Policy from time to time as it deems necessary. Any changes
            to this Policy will be posted on our website.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            13. Governing Law
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            13.1 This Policy shall be governed in all respects by the laws of
            Singapore and any claims or disputes of whatever nature shall be
            subject to the exclusive jurisdiction of the courts of the Republic
            of Singapore.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            14. Disclaimer
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            14.1 We shall not be liable for any voluntary disclosure or personal
            data made by you to other users or third parties in connection with
            the use of our suite of platforms under ProproproP.
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 font-bold">
            15. Contact Us
          </PrivacyParagraph>
          <PrivacyParagraph className="mb-4 ml-4">
            15.1 If you have any questions or concerns regarding this Privacy
            Policy or if you require any additional information about the
            collection, use and treatment of your personal data by us, you can
            contact our Data Protection Officer at Feedback/Contact on our
            website.
          </PrivacyParagraph>
        </div>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button
          className={`btn btn-secondary`}
          aria-label="Close"
          onClick={handleClick}
        >
          {BUTTON_CLOSE}
        </button>
      </div>
    </>
  );
};

export default PrivacyPolicy;
