import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { feedbackRequest } from '../common/ApiService';
import * as constants from '../common/Constants';
import ModalContent from './ModalContent';
import { getLoggedInUser } from '../common/Utils';
import { feedbackValidation } from '../common/Validations';
import { AppContext } from './Home';
import { VARIANTS } from './common/toast';

const regardingOptions = [
  'Listing',
  'Membership',
  'Technical issues',
  'Complain',
  'Others',
];

function Feedback({ id, size, onClose }) {
  const user = getLoggedInUser();

  const { addToast } = useContext(AppContext);
  // Add a state variable for the description field
  const [desc, setDesc] = useState('');
  const formOptions = { resolver: yupResolver(feedbackValidation()) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState({
    type: '',
    data: { code: null, result: null },
    message: null,
  });

  useEffect(() => {
    if (user) {
      setValue('email', user?.email);
    }
  }, [user]);

  // Add a function to handle changes in the description field
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    // Limit the description to 500 characters
    if (value.length <= 500) {
      setDesc(value);
    }
  };

  const onSubmit = async (data) => {
    console.log('submit - form values', data);
    data['email'] = String(data['email']).toLowerCase();
    setLoading(true);

    // Send the form data to Feedback request API and get a response.
    const response = await feedbackRequest(data);
    console.log('Feedback request form response', response);
    if (response?.status === 200) {
      setLoading(false);
      onClose(id);
      addToast(VARIANTS.Success.name, 'Feedback Submitted!');
    } else {
      setLoading(false);
      onClose(id);
      addToast(VARIANTS.Error.name, 'Error in submitting feedback.');
    }
  };

  const renderServerError = () => {
    if (apiResponseMsg.data?.code) {
      // For Server error response
      return (
        <div className="flex justify-center mt-4 text-xs text-red-700 ">
          {apiResponseMsg.message}
        </div>
      );
    } else {
      // For server error
      return (
        <div className="flex justify-center text-xs text-red-700">
          <div className="border-t border-gray-200 text-center">
            <h6 className="text-base font-medium py-2">
              {constants.ERROR_OOPS}
            </h6>
          </div>
        </div>
      );
    }
  };

  const renderApiResponse = () => {
    if (!loading && apiResponseMsg.type === constants.TEXT_FAILURE) {
      return renderServerError();
    }
    return null;
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalContent size={size}>
        <section className="container">
          <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
            <div
              className={`${
                apiResponseMsg.type === constants.TEXT_SUCCESS
                  ? 'hidden'
                  : 'block'
              }`}
            >
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        Regarding
                      </p>
                    </div>
                    <div className="w-full md:w-2/3 px-3">
                      <div className="flex flex-wrap">
                        {/* Render radio buttons dynamically */}
                        {regardingOptions.map((option) => (
                          <div className="flex items-center" key={option}>
                            <input
                              type="radio"
                              className="w-4 h-4 mb-1 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-0 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                              id={option.toLowerCase().replace(/\s+/g, '-')}
                              value={option}
                              {...register('regarding')}
                            />
                            <label
                              htmlFor={option
                                .toLowerCase()
                                .replace(/\s+/g, '-')}
                              className="px-2 mb-3 w-full text-sm font-medium cursor-pointer text-gray-500 dark:text-gray-300"
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                      <span className="text-xs text-red-700">
                        {errors.regarding?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        Description of Feedback
                      </p>
                      <span className="text-xs text-gray-800 dark:text-white font-medium">
                        (Max 500 characters)
                      </span>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <textarea
                        id="desc"
                        className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none bg-transparent border rounded-lg shadow-input ${
                          !errors.desc?.message
                            ? 'focus:border-blue-500 border-gray-300'
                            : 'border-red-700 focus:ring-red-700 focus:border-red-700'
                        }`}
                        type="text"
                        value={desc}
                        wrap="soft"
                        placeholder="Enter Feedback"
                        rows="4"
                        {...register('desc', {
                          onChange: handleDescriptionChange,
                        })}
                      />
                      <span className="text-xs text-red-700">
                        {errors.desc?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        Email
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <input
                        className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none bg-transparent border rounded-lg shadow-input ${
                          !errors.email?.message
                            ? 'focus:border-blue-500 border-gray-300'
                            : 'border-red-700 focus:ring-red-700 focus:border-red-700'
                        }`}
                        type="text"
                        placeholder="Enter Email"
                        id="email"
                        disabled={user ? true : false}
                        {...register('email')}
                      />
                      <span className="text-xs text-red-700">
                        {errors.email?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex justify-center text-gray-800 dark:text-white font-medium ${
                apiResponseMsg.type === constants.TEXT_SUCCESS
                  ? 'block'
                  : 'hidden'
              }`}
            >
              Please check you email...
            </div>
            {/* Handle API Response  */}
            {renderApiResponse()}
          </div>
        </section>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
            type="submit"
            aria-label="Login"
            disabled={loading}
          >
            {constants.BUTTON_SUBMIT}
          </button>
        }
      </div>
    </form>
  );
}

export default Feedback;
