import React from 'react';
import ModalContent from './ModalContent';
import { BUTTON_CLOSE } from '../common/Constants';

const TermsParagraph = ({ children, className }) => (
  <p className={className}>{children}</p>
);

const TermsOfService = ({ id, size, onClose }) => {
  const handleClick = () => {
    onClose(null, null, id);
  };
  return (
    <>
      <ModalContent size={size}>
        <div className="container mx-auto font-sans dark:text-white">
          <TermsParagraph className="mb-4">
            <strong>1. Acceptance of Terms</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            1.1 Proproprop Private Limited (“ProproproP”) operates the website
            ProproproP.com.sg, its subdomains and apps across various devices
            including desktops, tablets and other mobile devices.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            1.2 By accessing and using ProproproP.com.sg website including its
            suite of services on mobile sites and apps (which altogether form
            our "platform"), you agree to comply with and be bound by these
            Terms of Service. If you do not agree with these terms, please do
            not use our Platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            1.3 ProproproP Terms of Use and Privacy Policy form the basis which
            you may access all our platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>2. Use of ProproproP platform</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.1 The platform provides information related to real estate
            listings, property details, and related services in Singapore.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.2 You may use the Platform only for lawful purposes and in
            compliance with all applicable Singapore laws and regulations.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.3 You are responsible for maintaining the confidentiality of your
            account and password, and for restricting access to your computer or
            device. You agree to accept responsibility for all activities that
            occur under your account or password.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.4 You are not allowed to transfer, share or lend your account or
            rights to anyone.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.5 You will not use any device, bot, software, process or means to
            scrape, index or hack into our platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.6 You will not use any device, bot, software, process or means to
            disrupt, slowdown or create excessive and repetitive traffic on our
            platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.7 You will not transmit or attempt to transmit or introduce any
            computer viruses, worms, trojan horses or other any other
            destructive software into our platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.8 You will not use or index any content or data on our platform to
            create your own database.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.9 You will not use or index any content or data on our platform to
            transmit spam, chain letters, marketing materials or other mass
            communications.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.10 You are not allowed to reproduce, republish, retransmit,
            modify, adapt, distribute, translate, display, misrepresent, sell,
            trade or in any way exploit any content, data or information on our
            platform in violation of ProproproP Terms of Service or any
            applicable Singapore laws and regulations.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.11 You are not allowed to link to any content on our platform
            unless you expressly obtained our permission. If we do allow you to
            link to our platform, it is only on the strict condition that you
            expressly show and attribute the link to ProproproP. You are not
            allowed to link our content under your logo or mask the link content
            as other than ProproproP.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.12 You are not allowed to attribute a link to our site and then
            subsequently use this to redirect or relink to another site.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.13 You are not allowed to frame, mask, adapt, or derive partially
            or fully our content in such a way as to present it as your own or
            as belonging to anyone other than us or our licensors.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.14 You are not allowed to link or use our content as part of a
            website that aggregates property listings and information or
            competes with us in any manner.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.15 We reserve the right to require you to cut all links to our
            platform and take down all propriety content belonging to us from
            your platform. We reserve complete discretion in relation to all our
            rights and remedies to exercise of this right by giving you notice.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            2.16 We reserve all rights and remedies to whatever means we deem
            reasonable and necessary to prevent unauthorised access to or use of
            our platform, including but not limited to terminating your account,
            set up barriers to your access and reporting your conduct to the
            appropriate authorities.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>3. Copyright</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            3.1 All contents accessible on our platform and publications are
            copyright. Apart from fair dealing permitted by the Singapore
            Copyright Act (Cap. 63), ProproproP Singapore Private Limited grants
            permission to uses of our platform to download copyright material
            only for private and non-commercial purposes. For reproduction or
            use of ProproproP.com.sg copyright material beyond such use, written
            permission must be obtained directly from the ProproproP Singapore
            Private Limited or the relevant copyright owner. If given,
            permission will be subject to the requirement that the copyright
            owner’s name and interest in the material be acknowledged when the
            material is reproduced or quoted, in whole or in part.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>
              4. Third party links, advertising, content, service and platform
              providers
            </strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            4.1 Our platforms may contain advertisements, links to and from
            third party platforms such as our partner networks, advertisers and
            affiliates. Those third party websites do not form part of our
            platform and are not under the control of or the responsibility of
            ProproproP. If you follow a link to any of these third party
            platforms or services, ProproproP does not accept any responsibility
            or liability for any policies, services or information that may be
            rendered by these third party platforms or services. ProproproP
            makes no warranty as to the accuracy and reliability of the
            information contained on any third party websites. ProproproP and
            its related entities, directors and officers disclaim all liability
            and responsibility for any direct or indirect loss or damage which
            may be suffered by you through relying on anything contained on or
            omitted from such third party websites. Some of these third party
            websites may be co-branded with our logo or trademark, even though
            they are not operated or maintained by us. Any display of
            advertising, links or directions on our platform does not imply an
            endorsement or recommendation by ProproproP. Once you leave our
            platforms, you should check the applicable terms, conditions and
            policies of the third party website and any reliance by you on them;
            you do so at your own risk.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            4.2. Our platform may contain content and Contributions by third
            party vendors, service providers and business partners (“Third Party
            Content”). Even though we perform due diligence on Third Party
            Content as far as practically possible, ProproproP is not
            responsible for, nor do we endorse or makes any express or implied
            representations on Third Party Content. All Third Party Content do
            not represent the views, policies, intent or actions of ProproproP.
            If you rely on Third Party Content, you do so at your sole
            discretion and at your own risk.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            4.3. Third Party Platform providers
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            4.3.1. Our apps are provided subject to the platform or software
            provider’s terms.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            4.3.2. (a) Apple
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.1. If you download one of our apps through iTunes, you
            acknowledge that these terms incorporate an end user licence
            agreement between you and ProproproP. The end user licence agreement
            is not an agreement between Apple Inc. (Apple) and you.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.2. We grant you a non-transferable licence to use our app on
            any iPhone or iPad that you own or control. You must comply with the
            Usage Rules in the App Store Terms.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.3. You acknowledge that ProproproP and not Apple Inc. is
            solely responsible for our app and its contents.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.4. Apple Inc. is not required to provide maintenance or
            support services for our app.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.5. If our app fails to comply with a warranty (if any), you
            may notify Apple Inc. and Apple Inc. will refund the app purchase
            price (if any) to you. To the maximum extent permitted by law, Apple
            Inc. has no other warranty obligation with respect to our app. Any
            other claims, losses, liabilities, damages, costs or expenses
            attributable to a warranty failure (if any) is our responsibility.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.6. We are responsible for addressing any claims by you or
            relating to our app in your possession or use of our app, including
            (but not limited to): (i) product liability claims; (ii) failure of
            our app to conform to any applicable legal or regulatory
            requirement; and (iii) claims under consumer protection or similar
            legislation; and
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.7. We are responsible for investigating, defending, settling
            and discharging any third party claim on our app in your possession
            or the use of our app infringes a third party’s intellectual
            property rights.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.8. You represent and warrant that: (i) you are not located in
            a country that is subject to a U.S. Government embargo, or that has
            been designated by the U.S. Government as a “terrorist supporting”
            country; and (ii) you are not listed on any U.S. Government list of
            prohibited or restricted parties.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.2.9. To the extent permitted by local applicable laws, you
            acknowledge and agree that Apple Inc. and its subsidiaries are third
            party beneficiaries of this end user licence agreement and that,
            your acceptance of these terms (and this end user licence
            agreement), gives Apple Inc. (and you accept that Apple has) the
            right to enforce the end user licence agreement against you as a
            third party beneficiary of the agreement.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            4.3.3. (b) Android
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            4.3.3.1. Our app is accessible on Android devices that run the
            software under Apache License, Version 2.00 (the “License”,) the
            preferred license of The Android Open Source Project (AOSP). You are
            allowed to use this software only in compliance with the terms of
            this License. Unless required by applicable law or agreed to in
            writing, the software distributed under the License is distributed
            on an “as is basis”, without any expressed or implied warranties and
            conditions of any kind. Please refer to the License on the specific
            language governing permissions and limitations under the License.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>5. Terms Governing your Contributions</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.1. All data, text, video, images, audio or other content
            (“Contributions”) that ProproproP permits the user to browse, list,
            share, publish, post, store or upload on our platform must fully
            comply with our Terms of Service and Privacy Policy at all times.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.2. ProproproP may at any time, without liability to you, remove,
            alter or disable access to any or all of your Contributions or
            terminate your account on our sole discretion without prior notice
            to you if we consider that:
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            5.2.1. your Contributions to our platform or in the course of
            interacting with us are incomplete, inaccurate, false, misleading,
            deceptive, materially incorrect, likely to cause offence, obscene
            including pornographic, hateful, racially or ethnically offensive
            material, defamatory, unlawful, corrupt with virus, contain
            destructive codes or material or codes inappropriate to the purpose
            of our platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            5.2.2. in submitting, conveying or communicating any third party
            data, images or information on our platform during account creation,
            registration, listing and engagement in whatever form and means, you
            did not obtain prior approval and consent from their legal owners.
            In doing so, your Contributions may have violated the copyrights,
            trade secret, privacy rights or any other intellectual property or
            propriety rights of the legal owners.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            5.2.3. your Contributions are in breach of any applicable law or
            regulation.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            5.2.4. you are required to correct, amend or take-down on any of
            your Contributions directed by the relevant regulatory body or
            authority.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.3. In listing, sharing, publishing, posting, storing or uploading
            your Contributions, you have consented and grant ProproproP a
            worldwide, nonexclusive, royalty-free, perpetual, transferable and
            irrevocable licence to use, reproduce, modify, adapt, translate,
            distribute, publish, create derivative works from and publicly
            display your Contributions in any medium. You also grant permission
            to all users of our platform a nonexclusive licence to use,
            reproduce, adapt, translate, distribute, prepare derivative works of
            and display your Contributions as permitted by ProproproP Terms of
            Service and Privacy Policy.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.4. Use of Name and Representations
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-8">
            5.4.1. You grant ProproproP, in respect of your Contributions to its
            website the right to use your name or the name you submit with the
            Contribution, and, the right to represent and warrant that:
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            5.4.1.1. you own and control all of the rights to the Contributions.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            5.4.1.2. you have the lawful right including all necessary licences,
            rights, consents, and permissions to use and authorise ProproproP to
            use, analyse, publicly display the Contributions.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            5.4.1.3. you declare that you do not require any personal
            identifying mark or declaration be used in connection with your
            Contributions or derivative of your Contributions.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-12">
            5.4.1.4. You represent and warrant that you have the lawful right
            including all necessary licences, rights, consents, and permissions
            to use and authorise ProproproP to display your Contributions.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.5. Intellectual Property Rights and Contributions
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.5. you will not make any Contributions that infringe the
            intellectual property rights of any third party, and you agree to
            pay all royalties, fees or other monies payable by reason of any
            third party Contributions made by you.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            5.6. you will not make any Contributions that are incomplete,
            inaccurate, false, misleading, deceptive, materially incorrect,
            likely to cause offence, obscene, defamatory, unlawful or encourage
            unlawful conduct, corrupt with virus, contain destructive codes or
            material and codes inappropriate to the purpose of our platform.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>6. Further Warranties</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            6.1. You represent and warrant that you will comply at all times
            with our Terms of Service, Privacy Policy and any directions we may
            make to you in relation to your use of our platform from time to
            time.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            6.2. You will ensure that your username and password by which you
            access ProproproP.com.sg and all its platform, is kept confidential
            and secure at all times.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            6.3. You have accept all liabilities for any unauthorised use of any
            username and password issued to you except for the unauthorised use
            resulting from any negligent act or omission legally attributable to
            ProproproP.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>7. Indemnity</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            7.1. Any information, data, images, reports or content provided by
            our platform should not be regarded as professional advice or relied
            upon exclusively for your decision making. All these collectively
            may not take into account the potential impact of contributing
            factors such as policy changes, tax amendments, government
            announcements, future planned infrastructures, regulations,
            environment, business cycle or the property market cycle which may
            affect the value of the property. We recommend that you seek
            professional advice before acting on the content or making any
            property decisions. In providing data, images, content and
            information on our platform, we do not act as an agent or advisory
            for you.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            7.2. You agree to indemnify and hold ProproproP, its affiliates,
            subsidiaries and their officers, agents, partners and employees
            against any and all loss, liability, claim or demand (including any
            legal & court fees) arising out of, or in connection with your use
            of and access to our platform or making Contributions not in
            accordance with our Terms of Service and Privacy Policy.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>8. Limitation of Liability</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            8.1. Certain rights and remedies may be available to you under
            consumer protection legislations of Singapore and may not be
            permitted to be excluded, restricted or modified. Apart from those
            that cannot be excluded, to the extent permitted by law, our
            liability for breach of any implied warranty or condition that
            cannot be excluded is restricted, at our option to: the re-supply of
            services or payment of the cost of re-supply of services; or the
            replacement or repair of goods or payment of the cost of replacement
            or repair.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            8.2. In no event under any circumstances, shall ProproproP be liable
            to you or any third party for any indirect, consequential,
            exemplary, incidental, special or punitive damages, including loss
            of revenue, loss of goodwill, loss of customers, loss of capital,
            downtime costs, reputational damage, loss in relation to any
            contractual rights or obligations you may have with other parties,
            loss of data, loss of use of the data, or loss of any other benefits
            or rewards suffered in connection with the use of our platform or in
            any way connected with our Terms of Service and Privacy Policy.
            Notwithstanding anything to the contrary, the aggregate liability of
            ProproproP Singapore Private Limited and its related affiliates,
            subsidiaries, officers, directors and employees to you for any cause
            of action whatsoever and regardless of the form of action, will at
            all times be limited to $500 ( Five hundred Singapore Dollars).
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>9. Jurisdiction</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            9.1. Your use of our platform under our Terms of Service and Privacy
            Policy and all of our legal terms and notices will be governed by
            and construed in accordance with the laws and regulations of
            Singapore.
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            9.2. By using our platform you irrevocably and unconditionally
            submit to the jurisdiction of the courts of Singapore.
          </TermsParagraph>
          <TermsParagraph className="mb-4">
            <strong>10. Contact</strong>
          </TermsParagraph>
          <TermsParagraph className="mb-4 ml-4">
            10.1. You can direct any queries, questions, clarifications and
            communications in the Feedback/Contact link on all our platform.
          </TermsParagraph>
        </div>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button
          className={`btn btn-secondary`}
          aria-label="Close"
          onClick={handleClick}
        >
          {BUTTON_CLOSE}
        </button>
      </div>
    </>
  );
};

export default TermsOfService;
