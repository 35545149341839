import React, { useContext, useState } from 'react';
import { PostStepperContext } from '../AddEditPost';
import { getPropFieldsLabel } from '../../../common/AppConstants';

const Description = () => {
  const { postData, setPostData, errors, setErrors } =
    useContext(PostStepperContext);
  const [descValue, setDesc] = useState(postData['desc'] || '');
  const descFieldError = errors.find((error) => error.name === 'desc');

  const handleTextAreaErrors = (fieldName, fieldLabel, value) => {
    const updatedErrors = errors.filter((error) => error.name !== fieldName);
    // Check if the field is required and the value is empty
    if (value.trim() === '') {
      updatedErrors.push({
        name: fieldName,
        message: `${fieldLabel} is required`,
      });
    }
    console.log(fieldName, fieldLabel, 'errors ', updatedErrors);
    setErrors(updatedErrors);
  };

  const handleTextAreaBlur = (fieldName, fieldLabel) => (e) => {
    const { value } = e.target;
    handleTextAreaErrors(fieldName, fieldLabel, value, setErrors);
  };

  const handleTextAreaChange = (fieldName, fieldLabel) => (e) => {
    const { value } = e.target;
    handleTextAreaErrors(fieldName, fieldLabel, value, setErrors);

    if (fieldName === 'desc' && value.length <= 3000) {
      setDesc(value);
      setPostData((prevState) => ({ ...prevState, [fieldName]: value }));
    } else return;
  };

  return (
    <>
      <label
        htmlFor={getPropFieldsLabel(postData.action).desc}
        className="font-bold h-6 mt-3 text-gray-800 dark:text-white text-xs leading-8"
      >
        {getPropFieldsLabel(postData.action).desc}
        <span className="font-bold text-red-500">{' *'}</span>
      </label>
      <div className="my-2 w-full">
        <textarea
          id={getPropFieldsLabel(postData.action).desc}
          onBlur={handleTextAreaBlur(
            'desc',
            getPropFieldsLabel(postData.action).desc
          )}
          onChange={handleTextAreaChange(
            'desc',
            getPropFieldsLabel(postData.action).desc
          )}
          wrap="soft"
          maxLength={3000}
          value={descValue}
          className={`bg-transparent dark:text-white/80  text-sm font-medium p-1 px-1 none w-full text-gray-500 resize-none outline-none border rounded
              ${
                !descFieldError
                  ? 'focus:border-blue-500 border-gray-300'
                  : 'border-red-700 focus:ring-red-700 focus:border-red-700'
              }`}
          style={{ height: 'auto', minHeight: '150px' }}
        />
      </div>
      {descFieldError && (
        <span className="text-red-700 text-xs">{descFieldError.message}</span>
      )}
    </>
  );
};

export default Description;
