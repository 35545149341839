import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import * as appConstants from '../../../common/AppConstants';
import { TEXT_RENT, TEXT_SELL } from '../../../common/Constants';

import 'react-datepicker/dist/react-datepicker.css';
import { PostStepperContext } from '../AddEditPost';
import useIsMobile from '../../../hooks/useIsMobile';
import {
  isApplicableFieldForActionType,
  isMandatoryField,
} from '../../../common/Utils';
import CheckBoxes from './common/Checkboxes';
import RadioButtons from './common/RadioButtons';
import TextFields from './common/TextFields';

const PropertyDetails = () => {
  const isMobile = useIsMobile();

  const { postData, setPostData, errors, setErrors } =
    useContext(PostStepperContext);
  const [selectedProperty, setSelectedProperty] = useState(
    appConstants.propertyFields.find(
      (property) => property?.type === postData['type']
    )
  );
  const [fields, setFields] = useState(selectedProperty?.fields);

  const [selectedYear, setSelectedYear] = useState(
    postData['yearBuilt'] || null
  );
  const [selectedRenovationYear, setSelectedRenovationYear] = useState(
    postData['renovationYear'] || null
  );

  // For error fields
  const yearFieldError = errors.find((error) => error.name === 'yearBuilt');
  const renovationYearFieldError = errors.find(
    (error) => error.name === 'renovationYear'
  );

  useEffect(() => {
    setSelectedProperty(
      appConstants.propertyFields.find(
        (property) => property?.type === postData['type']
      )
    );
    setFields(
      appConstants.propertyFields.find(
        (property) => property?.type === postData['type']
      )?.fields
    );
  }, [postData]);

  const handleYearChange = (year) => {
    let fieldName = 'yearBuilt';
    setPostData((prevState) => ({
      ...prevState,
      [fieldName]: year.toISOString(),
    }));
    setSelectedYear(year.toISOString());
    handleCalendarErrors(
      fieldName,
      appConstants.getPropFieldsLabel(postData.action).yearBuilt,
      true
    );
  };

  const handleRenovationYearChange = (year) => {
    let fieldName = 'renovationYear';
    setPostData((prevState) => ({
      ...prevState,
      [fieldName]: year.toISOString(),
    }));
    setSelectedRenovationYear(year.toISOString());
    handleCalendarErrors(
      fieldName,
      appConstants.getPropFieldsLabel(postData.action).renovationYear,
      true
    );
  };

  const handleCalendarErrors = (field, label, valueExists) => {
    const updatedErrors = errors.filter((error) => error.name !== field);
    if (valueExists || postData.yearBuilt || postData.renovationYear) {
      setErrors(updatedErrors);
      return;
    } else {
      updatedErrors.push({ name: field, message: `${label} is required` });
      setErrors(updatedErrors);
      return;
    }
  };

  function checkFieldExists(fieldName) {
    if (!selectedProperty || !fields || !fieldName) {
      return false;
    }
    return isApplicableFieldForActionType(fields, fieldName, postData);
  }

  return (
    <>
      {checkFieldExists('floorLevel') && (
        <>
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).floorLevel}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'floorLevel') && ' *'}
            </span>
          </label>
          <RadioButtons
            field={'floorLevel'}
            options={selectedProperty.floorLevel}
          />
        </>
      )}
      {checkFieldExists('noOfStoreys') && (
        <TextFields
          fields={[
            {
              label: appConstants.getPropFieldsLabel(postData.action)
                .noOfStoreys,
              name: 'noOfStoreys',
              isNumber: true,
              isMandatoryField: isMandatoryField(fields, 'noOfStoreys'),
            },
          ]}
        />
      )}
      {checkFieldExists('furnishing') && (
        <>
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).furnishing}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'furnishing') && ' *'}
            </span>
          </label>
          <RadioButtons field={'furnishing'} options={appConstants.furnishing} />
        </>
      )}
      {checkFieldExists('tenure') && (
        <>
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).tenure}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'tenure') && ' *'}
            </span>
          </label>
          <RadioButtons
            field={'tenure'}
            options={
              postData?.action === TEXT_SELL
                ? appConstants.commonPropertyFields.sellTenure
                : appConstants.commonPropertyFields.rentTenure
            }
          />
        </>
      )}
      {checkFieldExists('bombShelter') && (
        <div className="flex">
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).bombShelter}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'bombShelter') && ' *'}
            </span>
          </label>
          <RadioButtons
            field={'bombShelter'}
            options={[
              { id: 'BS01', name: 'Yes' },
              { id: 'BS02', name: 'No' },
            ]}
          />
        </div>
      )}
      {checkFieldExists('tenanted') && (
        <div className="flex">
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).tenanted}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'tenanted') && ' *'}
            </span>
          </label>
          <RadioButtons
            field={'tenanted'}
            options={[
              { id: 'TE01', name: 'Yes' },
              { id: 'TE02', name: 'No' },
            ]}
          />
        </div>
      )}
      {checkFieldExists('broker') && (
        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).broker}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'broker') && ' *'}
            </span>
          </label>
          <RadioButtons
            field={'broker'}
            options={[
              { id: 'BR01', name: 'Yes' },
              { id: 'BR02', name: 'No' },
            ]}
          />
        </div>
      )}
      {checkFieldExists('landSize') && (
        <TextFields
          fields={[
            {
              label:
                appConstants.getPropFieldsLabel(postData.action).landSize +
                ' (sq ft)',
              name: 'landSize',
              isNumber: true,
              isMandatoryField: isMandatoryField(fields, 'landSize'),
            },
          ]}
        />
      )}
      {checkFieldExists('floorSize') && ( // Redundant Check as floorsize is mandatory for all types
        <TextFields
          fields={[
            {
              label:
                appConstants.getPropFieldsLabel(postData.action).floorSize +
                ' (sq ft)',
              name: 'floorSize',
              isNumber: true,
              isMandatoryField: isMandatoryField(fields, 'floorSize'),
            },
          ]}
        />
      )}
      <TextFields
        fields={[
          {
            label:
              appConstants.getPropFieldsLabel(postData.action).price + ' ($)',
            name: 'price',
            isNumber: true,
            isMandatoryField: isMandatoryField(fields, 'price'),
          },
        ]}
      />
      {checkFieldExists('maintenanceAmount') &&
        postData?.action === TEXT_RENT && (
          <TextFields
            fields={[
              {
                label:
                  appConstants.getPropFieldsLabel(postData.action)
                    .maintenanceAmount + ' ($)',
                name: 'maintenanceAmount',
                isNumber: true,
                isMandatoryField: isMandatoryField(fields, 'maintenanceAmount'),
              },
            ]}
          />
        )}
      {checkFieldExists('name') && (
        <TextFields
          fields={[
            {
              label: appConstants.getPropFieldsLabel(postData.action).name,
              name: 'name',
              isMandatoryField: isMandatoryField(fields, 'name'),
            },
          ]}
        />
      )}
      {checkFieldExists('developer') && (
        <TextFields
          fields={[
            {
              label: appConstants.getPropFieldsLabel(postData.action).developer,
              name: 'developer',
              isMandatoryField: isMandatoryField(fields, 'developer'),
            },
          ]}
        />
      )}
      {checkFieldExists('yearBuilt') && (
        <>
          <div className="customDatePicker">
            <label className="font-bold h-6 mt-3 text-gray-800 dark:text-white text-xs leading-8">
              {appConstants.getPropFieldsLabel(postData.action).yearBuilt}
              <span className="font-bold text-red-500">
                {isMandatoryField(fields, 'yearBuilt') && ' *'}
              </span>
            </label>
            <div className="customDatePickerWidth yearBuilt">
              <DatePicker
                id="yearBuilt"
                className={`cursor-pointer border rounded mt-2 px-3 py-1 w-full ${
                  isMandatoryField(fields, 'yearBuilt') && yearFieldError
                    ? 'border-red-700 focus:ring-red-700 focus:border-red-700'
                    : 'border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500'
                }`}
                selected={selectedYear ? new Date(selectedYear) : null}
                onChange={handleYearChange}
                onBlur={() =>
                  handleCalendarErrors(
                    'yearBuilt',
                    appConstants.getPropFieldsLabel(postData.action).yearBuilt,
                    false
                  )
                }
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                autoComplete={'off'}
                maxDate={new Date()}
                showYearPicker
                dateFormat="yyyy"
              />
            </div>
          </div>
          {isMandatoryField(fields, 'yearBuilt') && yearFieldError && (
            <span className="text-red-700 text-xs">
              {yearFieldError.message}
            </span>
          )}
        </>
      )}
      {checkFieldExists('renovationYear') && (
        <>
          <div className="font-bold h-6 mt-3 text-gray-800 dark:text-white text-xs leading-8">
            {appConstants.getPropFieldsLabel(postData.action).renovationYear}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'renovationYear') && ' *'}
            </span>
          </div>
          <div className="customDatePickerWidth">
            <DatePicker
              id="renovationYear"
              className={`cursor-pointer border rounded mt-2 px-3 py-1 w-full ${
                isMandatoryField(fields, 'renovationYear') &&
                renovationYearFieldError
                  ? 'border-red-700 focus:ring-red-700 focus:border-red-700'
                  : 'border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500'
              }`}
              selected={
                selectedRenovationYear ? new Date(selectedRenovationYear) : null
              }
              onChange={handleRenovationYearChange}
              onBlur={() =>
                handleCalendarErrors(
                  'renovationYear',
                  appConstants.getPropFieldsLabel(postData.action)
                    .renovationYear,
                  false
                )
              }
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              autoComplete={'off'}
              maxDate={new Date()}
              showYearPicker
              dateFormat="yyyy"
            />
          </div>
          {isMandatoryField(fields, 'renovationYear') &&
            renovationYearFieldError && (
              <span className="text-red-700 text-xs">
                {renovationYearFieldError.message}
              </span>
            )}
        </>
      )}
      {checkFieldExists('facilities') && (
        <>
          <label className="font-bold text-gray-800 dark:text-white text-xs">
            {appConstants.getPropFieldsLabel(postData.action).facilities}
            <span className="font-bold text-red-500">
              {isMandatoryField(fields, 'facilities') && ' *'}
            </span>
          </label>
          <CheckBoxes
            columns={3}
            options={selectedProperty?.facilities}
            field={'facilities'}
            isMultiple={true}
          />
        </>
      )}
      <TextFields
        fields={[
          {
            label: appConstants.getPropFieldsLabel(postData.action).address,
            name: 'address',
            maxLength: 100,
            isMandatoryField: isMandatoryField(fields, 'address'),
          },
          {
            label: appConstants.getPropFieldsLabel(postData.action).postalCode,
            name: 'postalCode',
            isNumber: true,
            minLength: 6,
            maxLength: 6,
            isMandatoryField: isMandatoryField(fields, 'postalCode'),
          },
        ]}
      />
      {checkFieldExists('mrtInfo') && ( // Redundant Check as mrtinfo is mandatory for all types
        <TextFields
          fields={[
            {
              label: appConstants.getPropFieldsLabel(postData.action).mrtInfo,
              name: 'mrtInfo',
              maxLength: 100,
              isMandatoryField: isMandatoryField(fields, 'mrtInfo'),
            },
          ]}
        />
      )}
      {checkFieldExists('schoolInfo') && (
        <TextFields
          fields={[
            {
              label: appConstants.getPropFieldsLabel(postData.action)
                .schoolInfo,
              name: 'schoolInfo',
              maxLength: 100,
              isMandatoryField: isMandatoryField(fields, 'schoolInfo'),
            },
          ]}
        />
      )}
    </>
  );
};

export default PropertyDetails;
