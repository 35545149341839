import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalContent from '../ModalContent';
import { editAccountValidation } from '../../common/Validations';
import { updateUser } from '../../common/ApiService';
import { getLoggedInUser } from '../../common/Utils';
import * as constants from '../../common/Constants';
import FormField, { WhatsAppInput } from '../common/FormField';
import { AppContext } from '../Home';

const Profile = ({ id, size, onClose }) => {
  const { addToast } = useContext(AppContext);
  const user = getLoggedInUser();
  const [ccode, setCountryCode] = useState(`+${user?.ccode}`);
  const [whatsapp, setWhatsapp] = useState();
  // Form options and validation using react-hook-form and yup
  const formOptions = {
    resolver: yupResolver(editAccountValidation(user?.type)),
  };
  const { setFocus, register, handleSubmit, formState, reset } =
    useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState({
    data: { code: null, result: null },
    message: null,
    company: '',
    jobTitle: '',
    registrationNo: '',
    licenseNo: '',
  });

  useEffect(() => {
    // Set form field values once the component mounts
    reset({ ...user });
    // Cleanup function to cancel any pending tasks when the component unmounts
    return () => {};
  }, [reset]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  function handleCCodeChange(event) {
    if (
      event.target.value.length === 1 &&
      event.target.value.charAt(0) === '+'
    ) {
      return setCountryCode('+');
    }

    var _ccode = event.target.value.replace(/\D/g, '');
    if (_ccode.length > 0 && _ccode.charAt(0) !== '+') {
      _ccode = '+' + _ccode;
      event.target.value = _ccode;
    }
    setCountryCode(_ccode);
  }

  function handleWhatsAppNoChange(event) {
    var _whatsapp = event.target.value.replace(/\D/g, '');
    setWhatsapp(_whatsapp);
  }

  const getRequestData = (data) => {
    let commonRequest = {
      id: user.id,
      userName: data.userName,
      ccode: Number(data['ccode']),
      whatsapp: Number(data['whatsapp']),
    };

    let agentRequest = {
      ...commonRequest,
      company: data.company,
      jobTitle: data.jobTitle,
      registrationNo: data.registrationNo,
      licenseNo: data.licenseNo,
    };

    return user.type === constants.TEXT_AGENT ? agentRequest : commonRequest;
  };

  // Handle form submission
  const onSubmit = async (data) => {
    const req = getRequestData(data);
    console.log('submit - form values', getRequestData(data));
    setLoading(true);

    // Send the form data to signup API and get a response.
    const response = await updateUser(req);
    console.log('Profile form response', response);
    if (response?.status === 200 && response?.data?.code) {
      setLoading(false);
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        email: data.email,
        data: { code: response?.data?.code },
        message: response.data.message,
      });
    } else if (response.status === 200 && response?.data) {
      setLoading(false);
      let newUserObj = {
        ...user,
        ...req,
      };
      // store the user in localStorage
      localStorage.setItem(
        constants.LOGGED_IN_USER,
        JSON.stringify(newUserObj)
      );
      setApiResponseMsg({
        type: constants.TEXT_SUCCESS,
        message: response.data?.message,
      });
      addToast(constants.TEXT_SUCCESS, 'User updated successfully');
      onClose(id);
    } else {
      setLoading(false);
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        message: response.data.message,
        data: { code: response.status },
      });
    }
    return false;
  };

  const renderServerError = () => {
    if (apiResponseMsg.data?.code) {
      // For Server error response
      return (
        <div className="flex justify-center mt-4 text-xs text-red-700 ">
          {apiResponseMsg.message}
        </div>
      );
    } else {
      // For server error
      return (
        <div className="flex justify-center text-xs text-red-700">
          <h6 className="text-base font-medium py-2">{constants.ERROR_OOPS}</h6>
        </div>
      );
    }
  };

  const renderApiResponse = () => {
    if (!loading && apiResponseMsg.type === constants.TEXT_FAILURE) {
      return renderServerError();
    }
    return null;
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalContent size={size}>
        <section className="container">
          <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
            {/* Need to refactor in simpler way */}
            <div
              className={`${
                apiResponseMsg.type === constants.TEXT_SUCCESS
                  ? 'hidden'
                  : 'block'
              }`}
            >
              <FormField
                label="Name"
                name="userName"
                register={register}
                errors={errors}
              />
              <WhatsAppInput
                register={register}
                errors={errors}
                ccode={ccode}
                whatsapp={whatsapp}
                handleCCodeChange={handleCCodeChange}
                handleWhatsAppNoChange={handleWhatsAppNoChange}
              />
              {user.type === constants.TEXT_AGENT && (
                <>
                  <FormField
                    label="Company"
                    name="company"
                    register={register}
                    errors={errors}
                  />
                  <FormField
                    label="Job title"
                    name="jobTitle"
                    register={register}
                    errors={errors}
                  />
                  <FormField
                    label="CEA Registration No"
                    name="registrationNo"
                    register={register}
                    errors={errors}
                  />
                  <FormField
                    label="Agency License No"
                    name="licenseNo"
                    register={register}
                    errors={errors}
                  />
                </>
              )}
            </div>
            {/* Handle API Response  */}
            {renderApiResponse()}
          </div>
        </section>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
            type="submit"
            aria-label="Submit"
            disabled={loading}
          >
            {constants.BUTTON_SUBMIT}
          </button>
        }
      </div>
    </form>
  );
};

export default Profile;
