import {
  BUTTON_BUY,
  BUTTON_RENT,
  BUTTON_LIST,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
} from '../common/Constants';
import { getLoggedInUser } from '../common/Utils';

const MainSection = ({
  openModal,
  handlePrivacyPolicy,
  handleTermsOfService,
}) => {
  const user = getLoggedInUser();

  return (
    <>
      <div className="flex flex-col items-start">
        <div className="mt-11 mb-4">
          <img src="logo.png" height={80} width={500} loading="eager" />
        </div>
        {/* <LogoText /> */}
      </div>
      <div className="flex flex-col items-center h-full">
        <div className="flex flex-col space-y-8 sm:space-y-0 sm:flex-row sm:space-x-4">
          <button aria-label="Open Buy Modal" className="px-6 py-1 text-white text-4xl font-semibold cursor-pointer bg-gradient-to-r from-blue-500 via-blue-300 to-blue-500 hover:shadow focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 border border-blue-700 rounded-md shadow-sm transition duration-300 ease-in-out"
    style={{ fontFamily: 'Calibri, Arial, sans-serif', fontSize: '24px' }}
            onClick={() => {
              openModal(BUTTON_BUY);
            }}
          >
            {BUTTON_BUY}
          </button>
          <button aria-label="Open Rent Modal" className="px-6 py-1 text-white text-4xl font-semibold cursor-pointer bg-gradient-to-r from-blue-500 via-blue-300 to-blue-500 hover:shadow focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 border border-blue-700 rounded-md shadow-sm transition duration-300 ease-in-out"
    style={{ fontFamily: 'Calibri, Arial, sans-serif', fontSize: '24px' }}
            onClick={() => {
              openModal(BUTTON_RENT);
            }}
          >
            {BUTTON_RENT}
          </button>
        </div>
      </div>
      { !user && <div className="flex flex-col items-center h-full">
        <div className="flex flex-col space-y-8 sm:space-y-0 sm:flex-row sm:space-x-4">
          <button aria-label="Open Buy Modal" className="px-6 py-1 text-white text-4xl font-semibold cursor-pointer bg-gradient-to-r from-blue-500 via-blue-300 to-blue-500 hover:shadow focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 border border-blue-700 rounded-md shadow-sm transition duration-300 ease-in-out"
    style={{ fontFamily: 'Calibri, Arial, sans-serif', fontSize: '24px' }}
            onClick={() => {
              openModal(BUTTON_LIST);
            }}
          >
            {BUTTON_LIST}
          </button>
        </div>
      </div> 
      }
      <div className="fixed bottom-2 right-4 mb-4 ml-4 mr-4">
        <div className="flex flex-row py-0 justify-end items-end sm:justify-center sm:space-y-0 sm:flex-row sm:space-x-4">
          <span
            key={PRIVACY_POLICY}
            className="inline-block rounded-full p-0 text-base font-semibold text-white cursor-pointer hover:underline sm:ml-4"
            onClick={() => {
              handlePrivacyPolicy();
            }}
          >
            {PRIVACY_POLICY} 
          </span>
          <span className="inline-block bg-white h-4" style={{ marginTop: '0.75rem', marginLeft: '0.5rem', marginRight: '0.5rem' }} aria-hidden="true"></span>
          <span
            key={TERMS_OF_SERVICE}
            className="inline-block rounded-full p-0 text-base font-semibold text-white cursor-pointer hover:underline sm:ml-4"
            onClick={() => {
              handleTermsOfService();
            }}
          >
            {TERMS_OF_SERVICE}
          </span>
        </div>
      </div>
    </>
  );
};

export default MainSection;
