import * as constants from './Constants';

// MENUS
export const defaultUserMenu = [
  {
    id: 1,
    title: constants.CREATE_OWNER_ACCOUNT,
  },
  {
    id: 2,
    title: constants.CREATE_AGENT_ACCOUNT,
  },
  {
    id: 3,
    title: constants.LOGIN,
  },
  {
    id: 4,
    title: constants.PASSWORD_RESET,
  },
  {
    id: 5,
    title: constants.FEEDBACK_CONTACT,
  },
];

// Guest user menu
export const loggedInGuestUserMenu = [
  {
    id: 1,
    title: constants.VIEW_ENQUIRES,
  },
  // {
  //   id: 2,
  //   title: constants.VIEW_FAVORITES,
  // },
  {
   id: 3,
   title: constants.LOGOUT,
  },
  {
    id: 4,
    title: constants.CHANGE_PWD,
  },
  {
    id: 5,
    title: constants.FEEDBACK_CONTACT,
  },
];

export const loggedInUserMenu = [
  {
    id: 1,
    title: constants.ADD_POST,
  },
  {
    id: 2,
    title: constants.VIEW_MY_POSTS,
  },
  {
    id: 3,
    title: constants.VIEW_ENQUIRES,
  },
  // {
  //   id: 4,
  //   title: constants.VIEW_FAVORITES,
  // },
  {
   id: 5,
   title: constants.LOGOUT,
  },
  {
    id: 6,
    title: constants.CHANGE_PWD,
  },
  {
    id: 7,
    title: constants.FEEDBACK_CONTACT,
  },
];

export const propertyTypes = {
  PROPERTY_TYPE_HDB: 'HDB',
  PROPERTY_TYPE_CONDO: 'Condo',
  PROPERTY_TYPE_LANDED: 'Landed',
  PROPERTY_TYPE_RETAIL: 'Retail',
  PROPERTY_TYPE_INDUSTRIAL: 'Industrial',
};

export const commonPropertyFields = {
  fields: [
    { name: 'type', type: 'String' },
    { name: 'subType', type: 'String' },
    { name: 'floorSize', type: 'Number' },
    { name: 'price', type: 'Number' },
    { name: 'tenanted', type: 'Boolean', actionType: 'Sell' },
    { name: 'broker', type: 'Boolean', userType: 'Owner' },
    { name: 'address', type: 'String' },
    { name: 'postalCode', type: 'Number' },
    { name: 'desc', type: 'String' },
    { name: 'pictures', type: 'Array' },
    { name: 'mrtInfo', type: 'String', optional: true },
    { name: 'id', type: 'String', editable: false },
    { name: 'createdAt', type: 'String', editable: false },
    { name: 'userName', type: 'String', editable: false },
    { name: 'userNo', type: 'String', editable: false },
    { name: 'userEmail', type: 'String', optional: false },
  ],
  sellTenure: [
    { id: 'ST01', name: '99-year Leasehold' },
    { id: 'ST02', name: '999-year Leasehold' },
  ],
  rentTenure: [
    { id: 'RT01', name: '1 year Lease' },
    { id: 'RT02', name: '2 years Lease' },
    { id: 'RT03', name: '3 years Lease' },
    { id: 'RT04', name: 'Flexible Lease' },
  ],
};

export const propertyFields = [
  {
    type: propertyTypes.PROPERTY_TYPE_HDB,
    fields: [
      // HDB
      { name: 'hdbEstate', type: 'String' },
      { name: 'floorLevel', type: 'String' },
      { name: 'furnishing', type: 'String' },
      {
        name: 'renovationYear',
        type: 'Number',
        optional: true,
        actionType: 'Sell',
      },
      { name: 'bombShelter', type: 'Boolean' },
      ...commonPropertyFields.fields,
      { name: 'schoolInfo', type: 'String', optional: true },
    ],
    subTypes: [
      '1 Room',
      '2 Rooms',
      '3 Rooms',
      '4 Rooms',
      '5 Rooms',
      'Jumbo/Executive/Others',
    ],
    floorLevel: [
      { id: 'FL01', name: 'Ground Floor' },
      { id: 'FL02', name: 'Low Floor' },
      { id: 'FL03', name: 'Mid Floor' },
      { id: 'FL04', name: 'High Floor' },
    ],
  },
  {
    type: propertyTypes.PROPERTY_TYPE_CONDO,
    fields: [
      // Condo
      { name: 'floorLevel', type: 'String' },
      { name: 'furnishing', type: 'String' },
      { name: 'name', type: 'String' },
      { name: 'developer', type: 'String', optional: true, actionType: 'Sell' },
      { name: 'yearBuilt', type: 'Number', optional: true, actionType: 'Sell' },
      {
        name: 'renovationYear',
        type: 'Number',
        optional: true,
        actionType: 'Sell',
      },
      { name: 'maintenanceAmount', type: 'Number', actionType: 'Sell' }, // Applicable only to Condo Sell.
      { name: 'facilities', type: 'Array' },
      { name: 'tenure', type: 'String', actionType: 'Sell' },
      ...commonPropertyFields.fields,
      { name: 'district', type: 'String' },
      { name: 'schoolInfo', type: 'String', optional: true },
    ],
    subTypes: [
      'Studio/1 bedroom',
      '2 Bedrooms',
      '3 Bedrooms',
      '4+ Bedrooms',
      'Penthouse',
    ],
    floorLevel: [
      { id: 'FL01', name: 'Ground Floor' },
      { id: 'FL02', name: 'Low Floor' },
      { id: 'FL03', name: 'Mid Floor' },
      { id: 'FL04', name: 'High Floor' },
      { id: 'FL05', name: 'Penthouse' },
    ],
    facilities: [
      { id: 'FA01', name: 'Barbeque Area', isSelected: false },
      { id: 'FA02', name: 'Covered Carpark', isSelected: false },
      { id: 'FA03', name: 'Club House', isSelected: false },
      { id: 'FA04', name: 'Game Room', isSelected: false },
      { id: 'FA05', name: 'Gym', isSelected: false },
      { id: 'FA06', name: 'Playground', isSelected: false },
      { id: 'FA07', name: 'Sauna', isSelected: false },
      { id: 'FA08', name: '24 hours security', isSelected: false },
      { id: 'FA09', name: 'Tennis Court', isSelected: false },
      { id: 'FA10', name: 'Pool', isSelected: false },
      { id: 'FA11', name: 'Air-Conditioning', isSelected: false },
    ],
  },
  {
    type: propertyTypes.PROPERTY_TYPE_LANDED,
    fields: [
      // Landed
      { name: 'noOfStoreys', type: 'Number' },
      { name: 'furnishing', type: 'String' },
      { name: 'landSize', type: 'Number' },
      { name: 'yearBuilt', type: 'Number', optional: true, actionType: 'Sell' },
      {
        name: 'renovationYear',
        type: 'Number',
        optional: true,
        actionType: 'Sell',
      },
      { name: 'facilities', type: 'Array' },
      { name: 'tenure', type: 'String', actionType: 'Sell' },
      ...commonPropertyFields.fields,
      { name: 'district', type: 'String' },
      { name: 'schoolInfo', type: 'String', optional: true },
    ],
    subTypes: [
      'Terrace',
      'Corner Terrace',
      'Semi-detached',
      'Bungalow',
      'GCB',
      'Cluster/Townhouse',
    ],
    facilities: [
      { id: 'FA01', name: 'Carpark', isSelected: false },
      { id: 'FA02', name: 'Pool', isSelected: false },
      { id: 'FA03', name: 'Air-Conditioning', isSelected: false },
      { id: 'FA04', name: 'Basement', isSelected: false },
      { id: 'FA05', name: 'Wet Kitchen', isSelected: false },
      { id: 'FA06', name: 'Lift', isSelected: false },
      { id: 'FA07', name: 'Maid Room', isSelected: false },
    ],
  },
  {
    type: propertyTypes.PROPERTY_TYPE_RETAIL,
    fields: [
      // Retail
      { name: 'floorLevel', type: 'String' },
      { name: 'name', type: 'String', optional: true },
      { name: 'facilities', type: 'Array' },
      { name: 'tenure', type: 'String', actionType: 'Sell' },
      ...commonPropertyFields.fields,
      { name: 'district', type: 'String' },
    ],
    subTypes: [
      'Shophouses',
      'HDB Shophouses',
      'Strata Shop Lots',
      'Food and Beverages',
      'Office',
      'E-Business',
      'Medical Suites',
      'Childcare/Tuition Centers',
      'Others',
    ],
    floorLevel: [
      { id: 'FL01', name: 'Ground Floor' },
      { id: 'FL02', name: 'Low Floor' },
      { id: 'FL03', name: 'Mid Floor' },
      { id: 'FL04', name: 'High Floor' },
    ],
    facilities: [
      { id: 'FA01', name: 'Carpark', isSelected: false },
      { id: 'FA02', name: 'Lift', isSelected: false },
      { id: 'FA03', name: 'Water', isSelected: false },
      { id: 'FA04', name: 'Exhaust', isSelected: false },
      { id: 'FA05', name: 'Grease Tap', isSelected: false },
      { id: 'FA06', name: 'Toilet', isSelected: false },
    ],
  },
  {
    type: propertyTypes.PROPERTY_TYPE_INDUSTRIAL,
    fields: [
      // Industrial
      { name: 'floorLevel', type: 'String' },
      { name: 'name', type: 'String', optional: true },
      { name: 'facilities', type: 'Array' },
      { name: 'tenure', type: 'String', actionType: 'Sell' },
      ...commonPropertyFields.fields,
      { name: 'district', type: 'String' },
    ],
    subTypes: [
      'General Industrial',
      'Warehouse',
      'Showroom',
      'Dormitory',
      'Factory',
      'Land',
    ],
    floorLevel: [
      { id: 'FL01', name: 'Ground Floor' },
      { id: 'FL02', name: 'Low Floor' },
      { id: 'FL03', name: 'Mid Floor' },
      { id: 'FL04', name: 'High Floor' },
    ],
    facilities: [
      { id: 'FA01', name: 'Carpark', isSelected: false },
      { id: 'FA02', name: 'Lift', isSelected: false },
      { id: 'FA03', name: 'Water', isSelected: false },
      { id: 'FA04', name: 'Toilet', isSelected: false },
    ],
  },
];

// Start of Property Fields Label Text //

const propFieldsLabel = {
  type: 'Property Type',
  subType: 'Type',
  hdbEstate: 'HDB Estate',
  floorLevel: 'Floor Level',
  noOfStoreys: 'Number of Storeys',
  furnishing: 'Furnishing',
  name: 'Property Name',
  landSize: 'Land Size',
  floorSize: 'Floor Size',
  developer: 'Developer',
  yearBuilt: 'Year Built',
  renovationYear: 'Latest Renovation Year',
  bombShelter: 'Bomb Shelter',
  maintenanceAmount: 'Monthly Maintenance',
  facilities: 'Facilities',
  price: 'Price',
  tenure: 'Tenure',
  tenanted: 'Currently Tenanted',
  broker: 'Willing to Engage Broker',
  address: 'Address',
  district: 'District',
  postalCode: 'Postal Code',
  desc: 'Description',
  pictures: 'Pictures',
  mrtInfo: 'Nearby MRT Stations',
  schoolInfo: 'Schools within 1 km',
  id: 'Post ID',
  createdAt: 'Posted On',
  userName: 'Posted By',
  userNo: 'Contact No',
  userEmail: 'Contact Email',
};

const sellPropFieldsLabel = {
  ...propFieldsLabel,
  price: 'Asking Price',
  desc: 'Why this is a good buy',
};

const rentPropFieldsLabel = {
  ...propFieldsLabel,
  price: 'Monthly Rental',
  desc: 'Rental Preferences',
};

export const getPropFieldsLabel = (actionType) => {
  switch (actionType) {
    case 'Sell':
      return sellPropFieldsLabel;
    case 'Rent':
      return rentPropFieldsLabel;
    default:
      return propFieldsLabel;
  }
};

// End of Property Fields Label Text //

export const actions = ['Sell', 'Rent'];
export const accountTypes = ['Owner', 'Agent'];
export const MAX_COUNT = 10;

export const hdbEstates = [
  { id: 'HE01', name: 'Ang Mo Kio' },
  { id: 'HE02', name: 'Bedok' },
  { id: 'HE03', name: 'Bishan' },
  { id: 'HE04', name: 'Bukit Batok' },
  { id: 'HE05', name: 'Bukit Merah' },
  { id: 'HE06', name: 'Bukit Panjang' },
  { id: 'HE07', name: 'Bukit Timah' },
  { id: 'HE08', name: 'Central Area' },
  { id: 'HE09', name: 'Choa Chu Kang' },
  { id: 'HE10', name: 'Clementi' },
  { id: 'HE11', name: 'Geylang' },
  { id: 'HE12', name: 'Hougang' },
  { id: 'HE13', name: 'Jurong East' },
  { id: 'HE14', name: 'Jurong West' },
  { id: 'HE15', name: 'Kallang/Whampoa' },
  { id: 'HE16', name: 'Lim Chu Kang' },
  { id: 'HE17', name: 'Marine Parade' },
  { id: 'HE18', name: 'Pasir Ris' },
  { id: 'HE19', name: 'Punggol' },
  { id: 'HE20', name: 'Queenstown' },
  { id: 'HE21', name: 'Sembawang' },
  { id: 'HE22', name: 'Sengkang' },
  { id: 'HE23', name: 'Serangoon' },
  { id: 'HE24', name: 'Tampines' },
  { id: 'HE25', name: 'Toa Payoh' },
  { id: 'HE26', name: 'Woodlands' },
  { id: 'HE27', name: 'Yishun' },
];

export const furnishing = [
  { id: 'F01', name: 'Unfurnished' },
  { id: 'F02', name: 'Partially Furnished' },
  { id: 'F03', name: 'Fully Furnished' },
];

export const districts = [
  {
    id: 'D01',
    name: 'D01 - Boat Quay / Raffles Place / Marina',
    isSelected: false,
  },
  { id: 'D02', name: 'D02 - Chinatown / Tanjong Pagar' },
  { id: 'D03', name: 'D03 - Alexandra / Commonwealth' },
  { id: 'D04', name: 'D04 - Harbourfront / Telok Blangah' },
  {
    id: 'D05',
    name: 'D05 - Buona Vista / West Coast / Clementi',
    isSelected: false,
  },
  { id: 'D06', name: 'D06 - City Hall / Clarke Quay' },
  { id: 'D07', name: 'D07 - Beach Road / Bugis / Rochor' },
  { id: 'D08', name: 'D08 - Farrer Park / Serangoon Rd' },
  { id: 'D09', name: 'D09 - Orchard / River Valley' },
  { id: 'D10', name: 'D10 - Tanglin / Holland' },
  { id: 'D11', name: 'D11 - Newton / Novena' },
  { id: 'D12', name: 'D12 - Balestier / Toa Payoh' },
  { id: 'D13', name: 'D13 - Macpherson / Potong Pasir' },
  { id: 'D14', name: 'D14 - Eunos / Geylang / Paya Lebar' },
  { id: 'D15', name: 'D15 - East Coast / Marine Parade' },
  { id: 'D16', name: 'D16 - Bedok / Upper East Coast' },
  {
    id: 'D17',
    name: 'D17 - Changi Airport / Changi Village',
    isSelected: false,
  },
  { id: 'D18', name: 'D18 - Pasir Ris / Tampines' },
  { id: 'D19', name: 'D19 - Hougang / Punggol / Sengkang' },
  { id: 'D20', name: 'D20 - Ang Mo Kio / Bishan / Thomson' },
  {
    id: 'D21',
    name: 'D21 - Clementi Park / Upper Bukit Timah',
    isSelected: false,
  },
  { id: 'D22', name: 'D22 - Boon Lay / Jurong / Tuas' },
  {
    id: 'D23',
    name: 'D23 - Bukit Batok / Bukit Panjang / Choa Chu Kang',
    isSelected: false,
  },
  { id: 'D24', name: 'D24 - Lim Chu Kang / Tengah' },
  { id: 'D25', name: 'D25 - Admiralty / Woodlands' },
  { id: 'D26', name: 'D26 - Mandai / Upper Thomson' },
  { id: 'D27', name: 'D27 - Sembawang / Yishun' },
  { id: 'D28', name: 'D28 - Seletar / Yio Chu Kang' },
];

export const areas = [
  { id: 'A01', name: 'Ang Mo Kio' },
  { id: 'A02', name: 'Bedok' },
  { id: 'A03', name: 'Bishan' },
  { id: 'A04', name: 'Boon Lay' },
  { id: 'A05', name: 'Bukit Batok' },
  { id: 'A06', name: 'Bukit Merah' },
  { id: 'A07', name: 'Bukit Panjang' },
  { id: 'A08', name: 'Bukit Timah' },
  { id: 'A09', name: 'Central Water Catchment' },
  { id: 'A10', name: 'Changi' },
  { id: 'A11', name: 'Changi Bay' },
  { id: 'A12', name: 'Choa Chu Kang' },
  { id: 'A13', name: 'Clementi' },
  { id: 'A14', name: 'Downtown Core' },
  { id: 'A15', name: 'Geylang' },
  { id: 'A16', name: 'Hougang' },
  { id: 'A17', name: 'Jurong East' },
  { id: 'A18', name: 'Jurong West' },
  { id: 'A19', name: 'Kallang' },
  { id: 'A20', name: 'Lim Chu Kang' },
  { id: 'A21', name: 'Mandai' },
  { id: 'A22', name: 'Marina East' },
  { id: 'A23', name: 'Marina South' },
  { id: 'A24', name: 'Marine Parade' },
  { id: 'A25', name: 'Museum' },
  { id: 'A26', name: 'Newton' },
  { id: 'A27', name: 'North-Eastern Islands' },
  { id: 'A28', name: 'Novena' },
  { id: 'A29', name: 'Orchard' },
  { id: 'A30', name: 'Outram' },
  { id: 'A31', name: 'Pasir Ris' },
  { id: 'A32', name: 'Paya Lebar' },
  { id: 'A33', name: 'Pioneer' },
  { id: 'A34', name: 'Punggol' },
  { id: 'A35', name: 'Queenstown' },
  { id: 'A36', name: 'River Valley' },
  { id: 'A37', name: 'Rochor' },
  { id: 'A38', name: 'Seletar' },
  { id: 'A39', name: 'Sembawang' },
  { id: 'A40', name: 'Sengkang' },
  { id: 'A41', name: 'Serangoon' },
  { id: 'A42', name: 'Simpang' },
  { id: 'A43', name: 'Singapore River' },
  { id: 'A44', name: 'Southern Islands' },
  { id: 'A45', name: 'Straits View' },
  { id: 'A46', name: 'Sungei Kadut' },
  { id: 'A47', name: 'Tampines' },
  { id: 'A48', name: 'Tanglin' },
  { id: 'A49', name: 'Tengah' },
  { id: 'A50', name: 'Toa Payoh' },
  { id: 'A51', name: 'Tuas' },
  { id: 'A52', name: 'Western Islands' },
  { id: 'A53', name: 'Western Water Catchment' },
  { id: 'A54', name: 'Woodlands' },
  { id: 'A55', name: 'Yishun' },
];

export const getSellPriceList = (propertyType) => {
  switch (propertyType) {
    case 'HDB':
      return hdbSellPriceList;
    default:
      return sellPriceList;
  }
};

export const hdbSellPriceList = [
  { id: 'PL01', name: '< $250,000', value: { min: 0, max: 250000 } },
  {
    id: 'PL02',
    name: '$250,001 - $500,000',
    value: { min: 250001, max: 500000 },
  },
  {
    id: 'PL03',
    name: '$500,001 - $750,000',
    value: { min: 500001, max: 750000 },
  },
  {
    id: 'PL04',
    name: '$750,001 - $1,000,000',
    value: { min: 750001, max: 1000000 },
  },
  { id: 'PL05', name: '> $1,000,001', value: { min: 1000001, max: 0 } },
];

export const sellPriceList = [
  { id: 'PL01', name: '< $500,000', value: { min: 0, max: 500000 } },
  {
    id: 'PL02',
    name: '$500,001 - $1,000,000',
    value: { min: 500001, max: 1000000 },
  },
  {
    id: 'PL03',
    name: '$1,000,001 - $3,000,000',
    value: { min: 1000001, max: 3000000 },
  },
  {
    id: 'PL04',
    name: '$3,000,001 - $5,000,000',
    value: { min: 3000001, max: 5000000 },
  },
  { id: 'PL05', name: '> $5,000,001', value: { min: 5000001, max: 0 } },
];

export const rentPriceList = [
  { id: 'PL01', name: '< $250', value: { min: 0, max: 250 } },
  {
    id: 'PL02',
    name: '$251 - $500',
    value: { min: 251, max: 500 },
  },
  {
    id: 'PL03',
    name: '$501 - $750',
    value: { min: 501, max: 750 },
  },
  {
    id: 'PL04',
    name: '$751 - $1,000',
    value: { min: 751, max: 1000 },
  },
  {
    id: 'PL05',
    name: '$1,001 - $10,000',
    value: { min: 1001, max: 10000 },
  },
  { id: 'PL06', name: '> $10,001', value: { min: 10001, max: 0 } },
];

export const propertyFieldOrder = [
  'type',
  'subType',
  'floorLevel',
  'noOfStoreys',
  'furnishing',
  'tenure',
  'bombShelter',
  'tenanted',
  'broker',
  'landSize',
  'floorSize',
  'price',
  'maintenanceAmount',
  'name',
  'developer',
  'yearBuilt',
  'renovationYear',
  'facilities',
  'address',
  'district',
  'hdbEstate',
  'postalCode',
  'mrtInfo',
  'schoolInfo',
  'id',
  'createdAt',
  'userName',
  'userNo',
  'userEmail',
];

export const SeoKeys = [
  'ProproproP Singapore',
  'Singapore property for sale',
  'Singapore property for rent',
  'Singapore real estate',
  'Singapore property listing',
  'Singapore free property listing',
];
