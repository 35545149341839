import axios from 'axios';

export const verifyToken = async (token) => {
  let apiRes = null;
  try {
    let response = await axios.post(`api/auth/verify-token`, {
      token,
    });
    apiRes = response.data;
  } catch (error) {
    console.log('error ', error);
    apiRes = error.response;
  } finally {
    return apiRes;
  }
};

export async function login(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('api/auth/signin', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function passwordReset(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('api/auth/initiate-password-reset', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function changePassword(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('api/auth/update-user-password', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function feedbackRequest(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('api/feedback/add', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function createUserAndSendVerificationEMail(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/auth/signup', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function resendVerificationEMail(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/auth/resendVerificationEMail', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function addFreePost(post) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/v2/post/add-free-post', post);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function addPost(post) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/v2/post/add', post);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function updatePost(post) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/v2/post/update', post);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function deletePost(post) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post(`/api/v2/post/delete`, post);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function getPost(id, show) {
  if (show) showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/v2/post/fetch-post', { id: id });
  } catch (err) {
    apiRes = err.response;
  } finally {
    if (show) await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function getPosts(query, show) {
  if (show) showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/v2/post/fetch-posts', query);
  } catch (err) {
    apiRes = err.response;
  } finally {
    if (show) await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function addEnquiry(enquiry) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/enquiry/add', enquiry);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function getEnquiries(data, show) {
  if (show) showPleaseWait();
  let filters = {
    userId: data.userId,
  };
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/enquiry/get-enquiries', filters);
  } catch (err) {
    apiRes = err.response;
  } finally {
    if (show) await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function addFavorite(favorite) {
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/favorite/add', favorite);
  } catch (err) {
    apiRes = err.response;
  } finally {
    return apiRes;
  }
}

export async function getFavorites(data) {
  let filters = {
    userId: data.userId,
  };
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/favorite/get-favorites', filters);
  } catch (err) {
    apiRes = err.response;
  } finally {
    return apiRes;
  }
}

export async function removeFavorite(favorite) {
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/favorite/remove', favorite);
  } catch (err) {
    apiRes = err.response;
  } finally {
    return apiRes;
  }
}

export async function updateUser(data) {
  showPleaseWait();
  let apiRes = null;
  try {
    apiRes = await axios.post('/api/auth/updateUser', data);
  } catch (err) {
    apiRes = err.response;
  } finally {
    await sleep(hidePleaseWait);
    return apiRes;
  }
}

export async function getUser(id) {
  let apiRes = null;
  try {
    apiRes = await axios.get('/api/test/users/' + id);
  } catch (err) {
    apiRes = err.response;
  } finally {
    return apiRes;
  }
}

function showPleaseWait() {
  var pleaseWaitDialog = document.querySelector('#pleaseWaitDialog');

  if (pleaseWaitDialog === null) {
    pleaseWaitDialog = document.createElement('div');
    pleaseWaitDialog.id = 'pleaseWaitDialog';
    pleaseWaitDialog.className = 'preloader';
    pleaseWaitDialog.setAttribute('data-backdrop', 'static');
    pleaseWaitDialog.setAttribute('data-keyboard', 'false');
    pleaseWaitDialog.setAttribute('role', 'dialog');

    // Create and append an overlay element
    var overlay = document.createElement('div');
    overlay.className = 'opacity-20 fixed inset-0 z-30 bg-black';
    pleaseWaitDialog.appendChild(overlay);

    document.body.appendChild(pleaseWaitDialog);
  }

  pleaseWaitDialog.style.display = 'inline';
}

function hidePleaseWait() {
  document.querySelector('#pleaseWaitDialog').style.display = 'none';
}

async function sleep(fn, ...args) {
  await timeout(1000);
  return fn(...args);
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
