import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalContent from '../ModalContent';
import { passwordResetValidation } from '../../common/Validations';
import { passwordReset } from '../../common/ApiService';
import * as constants from '../../common/Constants';
import { VARIANTS } from '../common/toast';
import { AppContext } from '../Home';

function ResetPassword({ id, size, onClose }) {
  const { addToast } = useContext(AppContext);
  const formOptions = { resolver: yupResolver(passwordResetValidation()) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [apiResponseMsg, setApiResponseMsg] = useState({
    type: '',
    data: { code: null, result: null },
    message: null,
  });

  const onSubmit = async (data) => {
    console.log('submit - form values', data);
    data['email'] = String(data['email']).toLowerCase();
    setLoading(true);

    // Send the form data to Password reset API and get a response.
    const response = await passwordReset(data);
    console.log('Password reset form response', response);
    if (response?.status === 200) {
      setLoading(false);
      setApiResponseMsg({
        type: constants.TEXT_SUCCESS,
        message: response.data?.result,
      });
      addToast(VARIANTS.Success.name, response.data?.result);
      onClose(id);
    } else {
      setLoading(false);
      setApiResponseMsg({
        type: constants.TEXT_FAILURE,
        message: response?.data?.message,
        data: { code: response?.status },
      });
      addToast(VARIANTS.Error.name, response?.data.message);
    }
    return false;
  };

  const renderServerError = () => {
    if (apiResponseMsg.data?.code) {
      // For Server error response
      return (
        <div className="flex justify-center mt-4 text-xs text-red-700 ">
          {apiResponseMsg.message}
        </div>
      );
    } else {
      // For server error
      return (
        <div className="flex justify-center text-xs text-red-700">
          <div className="border-t border-gray-200 text-center">
            <h6 className="text-base font-medium py-2">
              {constants.ERROR_OOPS}
            </h6>
          </div>
        </div>
      );
    }
  };

  const renderApiResponse = () => {
    if (!loading && apiResponseMsg.type === constants.TEXT_FAILURE) {
      return renderServerError();
    }
    return null;
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <ModalContent size={size}>
        <section className="container">
          <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
            <div
              className={`${
                apiResponseMsg.type === constants.TEXT_SUCCESS
                  ? 'hidden'
                  : 'block'
              }`}
            >
              <div className="py-2">
                <div className="w-full">
                  <div className="flex flex-wrap -m-3">
                    <div className="w-full md:w-1/3 p-2">
                      <p className="text-sm text-gray-800 dark:text-white font-medium">
                        Email
                      </p>
                    </div>
                    <div className="w-full md:flex-1 p-3">
                      <input
                        className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none bg-transparent border rounded-lg shadow-input ${
                          !errors.email?.message
                            ? 'focus:border-blue-500 border-gray-300'
                            : 'border-red-700 focus:ring-red-700 focus:border-red-700'
                        }`}
                        type="text"
                        placeholder="Enter Email"
                        id="email"
                        {...register('email')}
                      />
                      <span className="text-xs text-red-700">
                        {errors.email?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex justify-center text-gray-800 dark:text-white font-medium ${
                apiResponseMsg.type === constants.TEXT_SUCCESS
                  ? 'block'
                  : 'hidden'
              }`}
            >
              Please check you email...
            </div>
            {/* Handle API Response  */}
            {renderApiResponse()}
          </div>
        </section>
      </ModalContent>
      <div className="flex items-center justify-center p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        {
          <button
            className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
            type="submit"
            aria-label="Login"
            disabled={loading}
          >
            {constants.BUTTON_SUBMIT}
          </button>
        }
      </div>
    </form>
  );
}

export default ResetPassword;
