import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import {
  EDIT_POST,
  PRIVACY_POLICY,
  PRIVACY_POLICY_TITLE,
  TERMS_OF_SERVICE,
  TERMS_OF_SERVICE_TITLE,
  VIEW_POST,
} from '../../../common/Constants';
import { AppContext } from '../../Home';
import useIsMobile from '../../../hooks/useIsMobile';
import useMobileOrientation from '../../../hooks/useMobileOrientation';

export const modalSizes = [
  {
    size: 'xs',
    styles: {
      width:
        'w-[30vw] sm:w-[30vw] md:w-[30vw] lg:w-[30vw] xl:w-[30vw] 2xl:w-[30vw]',
      height:
        'h-[40vh] sm:h-[40vh] md:h-[40vh] lg:h-[40vh] xl:h-[40vh] 2xl:h-[40vh]',
    },
  },
  {
    size: 'xl',
    styles: {
      width:
        'w-[45vw] sm:w-[45vw] md:w-[45vw] lg:w-[45vw] xl:w-[45vw] 2xl:w-[45vw]',
      height:
        'h-[70vh] sm:h-[70vh] md:h-[70vh] lg:h-[70vh] xl:h-[70vh] 2xl:h-[70vh]',
    },
  },
];

const Modal = ({ id, size, title, onClose, children }) => {
  const styles = modalSizes.find((item) => item.size === size).styles;
  const { selectedPost } = useContext(AppContext);
  const { isMobile, isLandscape } = useMobileOrientation();

  const getTitle = (title, selectedPost) => {
    if (title === VIEW_POST || title === EDIT_POST) {
      return selectedPost?.name || selectedPost?.address;
    } else if (title === PRIVACY_POLICY) {
      return PRIVACY_POLICY_TITLE;
    } else if (title === TERMS_OF_SERVICE) {
      return TERMS_OF_SERVICE_TITLE;
    } else {
      return title;
    }
  };

  return (
    <div key={id}>
      <div className="fixed top-0 left-0 right-0 z-40 flex items-center justify-center w-full p-4 overflow-auto md:inset-0 max-h-full">
        <div className={`relative ${isMobile ? 'w-[90vw]' : styles.width}`}>
          {/* Modal content  */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between px-5 py-2 border-b rounded-t dark:border-gray-600">
              <h3
                className={`${
                  isMobile ? 'font-bold' : 'heading'
                } text-gray-900 dark:text-white text-center flex-grow truncate`}
              >
                {getTitle(title, selectedPost)}
              </h3>
              <button
                type="button"
                className="text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:text-white dark:hover:text-white"
                aria-label="Close"
                onClick={() => onClose(null, null, id)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="opacity-7 h-6 w-6 text-sm block py-0"
                />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
