import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as constants from '../../common/Constants';
import {
  agentAccountValidation,
  ownerAccountValidation,
} from '../../common/Validations';
import { PostStepperContext } from '../add-edit-post/AddEditPost';
import FormField, { WhatsAppInput } from '../common/FormField';

const CommonCreateForm = () => {
  const { postData, setPostData } = useContext(PostStepperContext);
  const [accountType, setAccountType] = useState('Owner'); // Assuming 'owner' is the default type
  const isAgent = accountType !== 'Owner';
  const [ccode, setCountryCode] = useState('+65');
  const [whatsapp, setWhatsapp] = useState();
  const [pwdType, setPwdType] = useState('password');
  const [cpwdType, setCPwdType] = useState('password');
  // Form options and validation using react-hook-form and yup
  // Dynamic formOptions based on accountType
  const formOptions = {
    resolver: yupResolver(
      isAgent ? agentAccountValidation() : ownerAccountValidation()
    ),
  };
  const { setFocus, register, handleSubmit, formState, getValues } =
    useForm(formOptions);
  const data = getValues();
  const { errors } = formState;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const handleChange = (event) => {
    updatePostData('accountType', event.target.value);
    setAccountType(event.target.value);
    updateAccountValidStatus(false);
  };

  function handleCCodeChange(event) {
    if (
      event.target.value.length === 1 &&
      event.target.value.charAt(0) === '+'
    ) {
      return setCountryCode('+');
    }

    var _ccode = event.target.value.replace(/\D/g, '');
    if (_ccode.length > 0 && _ccode.charAt(0) !== '+') {
      _ccode = '+' + _ccode;
      event.target.value = _ccode;
    }
    setCountryCode(_ccode);
    updateAccountValidStatus(false);
  }

  function handleWhatsAppNoChange(event) {
    var _whatsapp = event.target.value.replace(/\D/g, '');
    setWhatsapp(_whatsapp);
    updateAccountValidStatus(false);
  }

  // Handle form submission
  const onSubmit = async (data) => {
    // Remove confirm password from data
    delete data['cpassword'];
    data['ccode'] = Number('65');
    data['whatsapp'] = Number(data['whatsapp']);
    if(accountType == 'Agent'){
      // data['company'] = String(data['company']).toUpperCase(); // Case is not required
      // data['jobTitle'] = String(data['jobTitle']).toUpperCase(); // Case is not required
      data['registrationNo'] = String(data['registrationNo']).toUpperCase();
      data['licenseNo'] = String(data['licenseNo']).toUpperCase();
    }
    data['userType'] = accountType;
    data['email'] = String(data['email']).toLowerCase();
    data.valid = true;
    updatePostData('account',data);
  };

  const updateAccountValidStatus = (valid) => {
    const data = {...postData.account};
    data.valid = valid;
    updatePostData('account',data);
  };

  const updatePostData = (field, value) => {
    setPostData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    console.log('object 123', data);

    return () => {};
  }, [formState?.dirtyFields]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <section className="container">
        <div className="px-4 h-full overflow-hidden rounded-md shadow-dashboard">
          <div className="py-2">
            <div className="w-full">
              <div className="flex flex-wrap -m-3">
                <div className="w-full md:w-1/3 p-3">
                  <p className="text-sm text-gray-800 dark:text-white font-medium">
                    Account Type
                  </p>
                </div>
                <div className="w-full md:flex-1 px-3 flex">
                  {['Owner', 'Agent'].map((option, i) => {
                    return (
                      <div key={i} className="flex items-center">
                        <input
                          checked={accountType == option}
                          id={option}
                          type="radio"
                          value={option}
                          name={'accountType'}
                          className="w-4 h-4 mb-1 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-0 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                          onChange={handleChange}
                        />
                        <label
                          htmlFor={option}
                          className="px-2 mb-3 w-full text-sm font-medium cursor-pointer text-gray-500 dark:text-gray-300"
                        >
                          {option}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <FormField
            label="Name as in NRIC/Passport "
            name="userName"
            register={register}
            errors={errors}
          />
          <FormField
            label="Email"
            name="email"
            register={register}
            errors={errors}
          />
          <WhatsAppInput
            register={register}
            errors={errors}
            ccode={ccode}
            whatsapp={whatsapp}
            handleCCodeChange={handleCCodeChange}
            handleWhatsAppNoChange={handleWhatsAppNoChange}
          />
          {isAgent && (
            <>
              <FormField
                label="Company"
                name="company"
                register={register}
                errors={errors}
              />
              <FormField
                label="Job title"
                name="jobTitle"
                register={register}
                errors={errors}
              />
              <FormField
                label="CEA Registration No"
                name="registrationNo"
                register={register}
                errors={errors}
              />
              <FormField
                label="Agency License No"
                name="licenseNo"
                register={register}
                errors={errors}
              />
            </>
          )}
          <div className="py-2">
            <div className="w-full">
              <div className="flex flex-wrap -m-3">
                <div className="w-full md:w-1/3 p-3">
                  <p className="text-sm text-gray-800 dark:text-white font-medium">
                    {constants.LABEL_PASSWORD}
                  </p>
                </div>
                <div className="w-full md:flex-1 p-3">
                  <div
                    className={`flex items-center ${
                      !errors.password?.message
                        ? 'focus-within:border-blue-500 border-gray-300'
                        : 'focus-within:border-red-700  border-red-700'
                    } bg-transparent overflow-hidden border rounded-lg shadow-input`}
                  >
                    <input
                      className={`w-full px-4 py-1 text-sm font-normal outline-none border-r ${
                        !errors.password?.message
                          ? 'focus-within:border-blue-500  border-gray-300'
                          : 'focus-within:border-red-700  border-red-700'
                      } bg-transparent`}
                      maxLength={12}
                      type={pwdType}
                      placeholder=""
                      id="password"
                      {...register('password')}
                    />
                    <p
                      className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                      onClick={() =>
                        setPwdType(pwdType === 'text' ? 'password' : 'text')
                      }
                    >
                      <FontAwesomeIcon
                        icon={pwdType === 'text' ? faEye : faEyeSlash}
                      />
                    </p>
                  </div>
                  <span className="text-xs text-red-700">
                    {errors.password?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="w-full">
              <div className="flex flex-wrap -m-3">
                <div className="w-full md:w-1/3 pl-3 py-3">
                  <p className="text-sm text-gray-800 dark:text-white font-medium">
                    {constants.LABEL_RE_ENTER_PASSWORD}
                  </p>
                </div>
                <div className="w-full md:flex-1 p-3">
                  <div
                    className={`flex items-center ${
                      !errors.cpassword?.message
                        ? 'focus-within:border-blue-500 border-gray-300'
                        : 'focus-within:border-red-700  border-red-700'
                    } bg-transparent overflow-hidden border rounded-lg shadow-input`}
                  >
                    <input
                      className={`w-full px-4 py-1 text-sm dark:text-white font-normal outline-none border-r ${
                        !errors.cpassword?.message
                          ? 'focus-within:border-blue-500  border-gray-300'
                          : 'focus-within:border-red-700  border-red-700'
                      } bg-transparent`}
                      maxLength={12}
                      type={cpwdType}
                      placeholder=""
                      id="cpassword"
                      {...register('cpassword')}
                    />
                    <p
                      className="px-4 text-sm dark:text-white font-normal cursor-pointer"
                      onClick={() =>
                        setCPwdType(cpwdType === 'text' ? 'password' : 'text')
                      }
                    >
                      <FontAwesomeIcon
                        icon={cpwdType === 'text' ? faEye : faEyeSlash}
                      />
                    </p>
                  </div>
                  <span className="text-xs text-red-700">
                    {errors.cpassword?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="w-full">
              <div className="flex flex-wrap -m-3">
                <div className="w-full md:w-1/3 pl-3 py-3">
                </div>
                <div className="w-full md:flex-1 p-3">
                  <div
                    className="flex items-center text-xs text-red-700"
                  >
                  <p>Password must contain at least - one number, one special character, one lowercase letter, one uppercase letter - and should be 6 to 12 characters</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex items-center justify-center p-5 space-x-2  rounded-b dark:border-gray-600">
        {
            <button
              className="text-white bg-blue-500 active:bg-blue-700 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1"
              type="submit"
              aria-label="Submit"
              disabled={postData.account.valid}
            >
              {/* Display different text based on conditions */}
              {'Validate To Continue'}
            </button>
        }
      </div>
    </form>
  );
};

export default CommonCreateForm;
