import postCache from './PostCache';
import { propertyTypes } from './AppConstants';
import * as Yup from 'yup';
import { LOGGED_IN_USER } from './Constants';

export const getLoggedInUser = () => {
  const loggedInUser = localStorage.getItem(LOGGED_IN_USER);
  if (loggedInUser) {
    const foundUser = JSON.parse(loggedInUser);
    return foundUser;
  } else {
    return null;
  }
};

export const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDate = (_date) => {
  if (!_date) {
    return;
  }
  var date = new Date(_date);
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-SG', options);
};

export const getPropertyType = (obj) => {
  if (obj.type === propertyTypes.PROPERTY_TYPE_HDB)
    return obj.type + '/' + obj.hdbEstate;
  return obj.type;
};

// Utility function to determine the grid columns based on screen width
// For now used in Buy/Rent filter for Buttons & Checkboxes
export const getGridColumns = () => {
  if (typeof window !== 'undefined') {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1280) {
      return 'grid-cols-3'; // 3 columns for screens wider than or equal to 1280px
    } else if (screenWidth >= 1024) {
      return 'grid-cols-2'; // 2 columns for screens between 1024px and 1279px
    } else if (screenWidth >= 768) {
      return 'grid-cols-1'; // 1 column for screens between 768px and 1023px
    }
  }
  return 'grid-cols-1'; // Default to 1 column if window is not available (e.g., server-side rendering)
};

export const isEnquiryMadeForPost = (postId) => {
  if (postCache.enquiries.length > 0) {
    var post = postCache.enquiries.find((post) => {
      return post.id === postId;
    });
    return post ? true : false;
  }
  return false;
};

export const getContactAgentOrOwner = (userId) => {
  return userId.toString().includes('AG') ? 'Contact Agent' : 'Contact Owner';
};

export const getContactedAgentOrOwner = (userId) => {
  return userId.toString().includes('AG')
    ? 'Contacted Agent'
    : 'Contacted Owner';
};

export const isAgentUser = (userId) => {
  return userId.toString().includes('AG');
};

export function isApplicableFieldForActionType(fields, fieldName, postData) {
  let field = fields?.find((_field) => _field.name === fieldName);
  if (!field) {
    return false;
  }
  if (!field.actionType && !field.userType) {
    return true;
  }

  return field.actionType
    ? field.actionType === postData.action
    : field.userType
    ? field.userType === getLoggedInUser()?.type
    : false;
}

export function isMandatoryField(fields, fieldName) {
  let field = fields?.find((_field) => _field.name === fieldName);
  return field && !field.optional;
}

export function isEmail(email) {
  return Yup.string().email().isValidSync(email);
}

export function isWhatsapp(whatsapp) {
  return Yup.number().integer().positive().isValidSync(whatsapp);
}

export const getModalObject = (
  title,
  size,
  message,
  isSearch,
  isEnquiry,
  isFavorite,
  isGuest
) => {
  return {
    id: Math.floor(Math.random() * 1001 + 1), // TO generate random unique id for each modal
    title: title,
    size: size,
    message: message,
    isSearch: isSearch,
    isEnquiry: isEnquiry,
    isFavorite: isFavorite,
    isGuest: isGuest,
  };
};

export const getToastObject = (type, message) => {
  return {
    id: Math.floor(Math.random() * 101 + 1), // TO generate random unique id for each toast
    type: type,
    description: message,
  };
};

export const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 2);
  } else return;

  return initials.toUpperCase();
};

export const NumberFormatterForSingDollars = (value, decimal) => {
  return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
    'en-SG',
    {
      useGrouping: true,
    }
  );
};

export const getPropertyOptions = (field, options, data) => {
  if (field === 'subType') {
    const property = options.find(
      (property) => property?.type === data['type']
    );
    return property?.subTypes;
  }
  return options;
};

export const getOptionsWithSelectedFalse = (options) => {
  return options.map((option) => ({
    ...option,
    isSelected: false,
  }));
};

export const getPostsQuery = (data) => {
  let _price =
    data?.price?.length > 0
      ? {
          min: data?.price[0]?.min,
          max: data?.price[data?.price.length - 1].max,
        }
      : [];
  let query = {};
  if (data.userId) {
    query.userId = data.userId;
  }
  if (data.action) {
    query.action = data.action;
  }
  if (data.type) {
    query.type = data.type;
  }
  if (data.hdbEstates) {
    query.hdbEstates = data.hdbEstates;
  }
  if (data.subType) {
    query.subType = data.subType;
  }
  if (data.price) {
    query.price = _price;
  }
  if (data.sortBy) {
    query.sortBy = data.sortBy;
  }
  return query;
};
