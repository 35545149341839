import React, { useCallback, useContext, useEffect, useState } from 'react';
import ModalContent from '../ModalContent';
import { getPosts } from '../../common/ApiService';
import { BUTTON_CLOSE, BUTTON_FILTER } from '../../common/Constants';
import { AppContext } from '../Home';
import postCache from '../../common/PostCache';
import StepperContent from './stepper/StepperContent';
import StepperControl from './stepper/StepperControl';
import StepperNavigation from './stepper/StepperNavigation';
import useIsMobile from '../../hooks/useIsMobile';
import { getPostsQuery } from '../../common/Utils';

export const SearchContext = React.createContext();

const defaultSteps = [
  'Property Type',
  'HDB Estate',
  'Sub Type',
  'Price',
  'List',
];

export const defaultSearchValues = {
  action: '',
  type: '',
  hdbEstates: [],
  subType: '',
  price: [],
};

const BuyRent = ({ id, size, action, addToast, onClose }) => {
  const steps = defaultSteps;
  const [posts, setPosts] = useState([]); // Represents the list of posts based on search value
  const [searchValues, setSearchValues] = useState(defaultSearchValues);
  const [currentStep, setCurrentStep] = useState(1);
  const [additionalStep, setAdditionalStep] = useState(
    searchValues?.type === 'HDB' ? true : false
  );
  const [applyFilter, setApplyFiler] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sortValue } = useContext(AppContext);
  const isMobile = useIsMobile();

  const isNextDisabled = (step) => {
    switch (step) {
      case 1:
        return !searchValues.type;
      default:
        return false;
    }
  };

  const handleStepperClick = (newStep) => {
    setApplyFiler(false);
    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sortValue]);

  const fetchData = useCallback(
    async (show) => {
      const query = getPostsQuery({
        ...searchValues,
        sortBy: sortValue,
        action: action,
      });
      const list = postCache.getFilteredPosts(query);
      if (list) {
        show = false;
        setPosts(list);
        setLoading(false);
      } else {
        setLoading(true);
      }
      try {
        const response = await getPosts(query, show);
        if (response?.status === 200 && response?.data) {
          setPosts(response?.data);
          postCache.setFilteredPosts(query, response?.data);
        } else {
          setPosts([]);
          postCache.setFilteredPosts(query, null);
        }
      } catch (error) {
        setPosts([]);
        postCache.setFilteredPosts(query, null);
      }
      setLoading(false);
    },
    [searchValues, sortValue]
  ); // Add searchValues as a dependency if needed

  const onComplete = useCallback(() => {
    setApplyFiler(true);
    if (currentStep === 1 && searchValues?.property_type === '') {
      fetchData(true);
      return;
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
      fetchData(true);
    }
  }, [currentStep, searchValues, fetchData]);

  const handleClick = (direction) => {
    setApplyFiler(false);
    let newStep = currentStep;
    direction === 'next' ? (newStep += 1) : (newStep -= 1);

    // Check if steps are within bounds
    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  };

  return (
    <SearchContext.Provider
      value={{
        loading,
        posts,
        setPosts,
        currentStep,
        setCurrentStep,
        searchValues,
        setSearchValues,
        additionalStep,
        setAdditionalStep,
      }}
    >
      <ModalContent size={size}>
        <div className="relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
          <div className="mb-3">
            <StepperNavigation
              applyFilter={applyFilter}
              steps={steps}
              currentStep={currentStep}
              searchValues={searchValues}
              isNextDisabled={isNextDisabled(currentStep)}
              handleStepperClick={handleStepperClick}
            />
          </div>
          <div className="relative flex flex-col min-w-0 break-words w-full">
            <StepperContent
              action={action}
              currentStep={currentStep}
              additionalStep={additionalStep}
              stepsLength={steps.length}
              applyFilter={applyFilter}
              addToast={addToast}
              onSelect={() => {}}
            />
          </div>
        </div>
      </ModalContent>
      {/* navigation button */}
      <div
        className={`flex flex-row items-center ${
          (additionalStep && currentStep <= steps.length) ||
          (!additionalStep && currentStep < steps.length)
            ? 'justify-between'
            : 'justify-end'
        } p-5 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600`}
      >
        {((additionalStep && currentStep <= steps.length) ||
          (!additionalStep && currentStep < steps.length)) && (
          <StepperControl
            additionalStep={additionalStep}
            currentStep={currentStep}
            isNextDisabled={isNextDisabled(currentStep)}
            applyFilter={applyFilter}
            handleClick={handleClick}
            stepsLength={steps.length}
          />
        )}

        <div>
          {((additionalStep && currentStep < steps.length && !applyFilter) ||
            (!additionalStep &&
              currentStep < steps.length - 1 &&
              !applyFilter)) && (
            <button
              className={`mr-2 btn btn-primary ${isMobile ? 'px-3' : ''}`}
              aria-label={BUTTON_FILTER}
              onClick={onComplete}
            >
              {BUTTON_FILTER}
            </button>
          )}
{/*
          <button
            className={`btn btn-secondary ${isMobile ? 'px-3' : ''}`}
            aria-label="close"
            onClick={() => onClose(id)}
          >
            {BUTTON_CLOSE}
          </button>
*/}
        </div>
      </div>
    </SearchContext.Provider>
  );
};

export default BuyRent;
