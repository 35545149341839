import { BUTTON_NEXT, BUTTON_PREVIOUS } from '../../../common/Constants';
import useIsMobile from '../../../hooks/useIsMobile';

export default function StepperControl({
  isNextDisabled,
  handleClick,
  currentStep,
  stepsLength,
}) {
  stepsLength = stepsLength - 1;
  const isMobile = useIsMobile();

  return (
    <div className="flex min-[1024px]:mb-0">
      <button
        className={`btn btn-primary ${isMobile ? 'px-4' : ''}`}
        aria-label={currentStep === 1 ? 'Previous (Disabled)' : 'Previous'}
        onClick={() => handleClick()}
        disabled={currentStep === 1 ? true : false}
      >
        {isMobile ? '<' : BUTTON_PREVIOUS}
      </button>
      {
        <button
          className={`ml-2 btn btn-primary ${isMobile ? 'px-4' : ''}`}
          disabled={isNextDisabled || currentStep == stepsLength}
          aria-label={BUTTON_NEXT}
          onClick={() => handleClick('next')}
        >
          {isMobile ? '>' : BUTTON_NEXT}
        </button>
      }
    </div>
  );
}
