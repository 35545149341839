import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchContext } from '../BuyRent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const StepperNavigation = ({
  steps,
  currentStep,
  searchValues,
  applyFilter,
  handleStepperClick,
}) => {
  const [newSteps, setNewStep] = useState([]);
  const stepsRef = useRef();
  const { additionalStep } = useContext(SearchContext);

  const updateStep = (stepNumber, steps) => {
    // Filter the array to remove objects without a "description" property
    const newSteps = [...steps].filter((item) => item?.description);
    // console.log(newSteps);
    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      }

      //step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      //step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) => {
      if (!additionalStep && index === 1) {
        return null;
      } else {
        return Object.assign(
          {},
          {
            description:
              step === 'Sub Type' ? `${searchValues?.type} Type` : step,
            completed: false,
            highlighted: index === 0 ? true : false,
            selected: index === 0 ? true : false,
          }
        );
      }
    });

    stepsRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
  }, [steps, currentStep, additionalStep]);

  return (
    <>
      <ol className="list-none p-0 flex flex-wrap">
        {newSteps.map((step, index) => {
          if (currentStep < index + 1) {
            return null;
          }

          const isCurrentStep = currentStep === index + 1;
          const isLastStep = index === steps.length - 1;
          const isClickable = isCurrentStep && applyFilter;

          return (
            <li className="flex items-center sm:w-auto mb-1" key={index}>
              <span
                className={`cursor-pointer inline-flex items-center text-base font-semibold ${
                  !isClickable
                    ? 'text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white'
                    : 'border-gray-200 text-blue-500 hover:text-blue-500 dark:text-blue-500 dark:hover:text-blue-500'
                }`}
                onClick={() => {
                  if (!isClickable) {
                    handleStepperClick(index + 1);
                  }
                }}
              >
                {isClickable
                  ? newSteps[newSteps.length - 1].description
                  : step.description}
              </span>
              {!isLastStep && (
                <FontAwesomeIcon
                  className="fill-current w-3 h-3 mx-1 dark:text-white"
                  icon={faAngleRight}
                />
              )}
            </li>
          );
        })}
      </ol>
    </>
  );
};
export default StepperNavigation;
