import { useContext } from 'react';
import { PostStepperContext } from '../AddEditPost';
import {
  getPropFieldsLabel,
  propertyFieldOrder,
  propertyFields,
} from '../../../common/AppConstants';
import { TEXT_NO_FILE_SELECTED } from '../../../common/Constants';
import FieldValueDisplay from '../../common/FieldValueDisplay';
import Carousel, { CarouselItem } from '../../common/Carousel';

// PicturesPreview component
function PicturesPreview({ pictures }) {
  return (
    <div className="w-[280px] sm:mb-0 mb-6 mx-auto h-[168px]">
      <div className="rounded-lg h-40 overflow-hidden">
        {pictures?.length === 0 && (
          <div id="gallery" className="flex flex-1 flex-wrap -m-1">
            <div
              id="empty"
              className="h-full w-full text-center flex flex-col items-center justify-center"
            >
              <img
                className="mx-auto w-32"
                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                alt="no data"
              />
              <span className="text-small text-gray-500">
                {TEXT_NO_FILE_SELECTED}
              </span>
            </div>
          </div>
        )}
        <Carousel>
          {pictures?.map((picture, i) => {
            return (
              picture && (
                <CarouselItem key={i}>
                  <img
                    alt="content"
                    className="object-fit object-center h-full w-full"
                    src={picture.url || picture.data}
                  />
                </CarouselItem>
              )
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

// PropertyDetails component
function PropertyDetailsPreview({ postData }) {
  const selectedPropertyFields = propertyFields.find(
    (property) => property?.type === postData['type']
  )?.fields;

  let count = 0;
  return (
    <div className="my-3">
      <div className="flex flex-wrap">
        {propertyFieldOrder.map((fieldName) => {
          let display = true;
          const field = selectedPropertyFields?.find(
            (f) => f.name === fieldName
          );
          if (field) {
            if (field.actionType) {
              if (field.actionType !== postData.action) {
                display = false; // Skip this field if actionType doesn't match
              }
            }
            if (!postData.hasOwnProperty(field.name)) {
              display = false; // postData doesn't have the field
            } else if (
              postData[field.name] === null ||
              postData[field.name] === undefined
            ) {
              display = false; // postData field value is null/undefined
            } else if (!String(postData[field.name]).trim().length) {
              display = false; // postData have the field - but the value is empty
            }
          } else {
            display = false;
          }
          if (field && display) {
            count = count + 1;
            return (
              <div
                key={count}
                className={`border-b w-full ${
                  count % 2 === 0 ? 'lg:w-2/5' : 'lg:w-3/5'
                } py-2 border-gray-200`}
              >
                <FieldValueDisplay
                  key={field.name}
                  field={field}
                  value={postData[field.name]}
                  postData={postData}
                />
              </div>
            );
          } else return null;
        })}
      </div>
    </div>
  );
}

export default function PreviewConfirmation() {
  const { postData } = useContext(PostStepperContext);
  console.log('postData ', postData);
  return (
    // Property Section
    <section className="text-gray-600 body-font">
      <div className="px-4 py-1 mx-auto">
        <div className="flex flex-wrap -mx-4">
          {/* Image Gallery */}
          <PicturesPreview pictures={postData?.pictures} />
        </div>
        <div className="flex flex-col w-full pt-4">
          {/** Action, Name & Price */}
          <div className="flex justify-between">
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap pt-1">
                <span className="bg-blue-50 border border-blue-500 text-blue-500 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-md dark:bg-blue-200 dark:text-blue-800">
                  {postData?.action}
                </span>
              </div>
            </div>
          </div>
          {/** Property Details */}
          <PropertyDetailsPreview postData={postData} />
        </div>
        <div className="relative h-auto">
          <div className="w-full overflow-y-hidden h-full resize-none">
            <div className="font-bold h-6 mt-3 text-gray-800 dark:text-white text-xs leading-8">
              {getPropFieldsLabel(postData.action).desc}
            </div>
            <div className="h-32 my-2 p-1 bg-transparent border border-gray-200 rounded textarea-container">
              <textarea
                readOnly
                value={postData['desc'] || ''}
                className="bg-transparent dark:text-white/80  text-sm font-medium p-1 px-1 none w-full text-gray-500 resize-none outline-none"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
